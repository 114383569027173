import { UPLOAD_PROFILE_PHOTO, EDIT_USER } from './../const/index'

export const userLoginReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${UPLOAD_PROFILE_PHOTO}_SUCCESS`: {
            return {
                ...state,
                value: {
                    ...state.value,
                    user: {
                        ...state.value.user,
                        profileImage: action.payload.result,
                    },
                },
            }
        }
        case `${EDIT_USER}_SUCCESS`: {
            return {
                ...state,
                value: {
                    ...state.value,
                    user: {
                        ...state.value.user,
                        ...action.payload.result
                    },
                },
            }
        }
        default: {
            return state
        }
    }
}
