import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    CircularProgress,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { fetchLocationDetail } from '../../redux/actions/locations'
import { LocationPreview } from './LocationPreview'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
    Charger,
    ChargerConnector,
    getLabelForChargerConnectorType,
    getLabelForChargerConnectorSocketType,
} from '../../shared/types/charger'
import ImagesManager from '../../shared/components/imagesManager'
import Linkify from 'react-linkify'
import { LocationWithChargers, ChargingSessionState } from '../../shared/types/location'
import { ReadOnlyEditor } from '../../shared/components/ReadOnlyEditor'
import { useConnectorStatusPeriodicUpdate } from '../../utils/currentChargerConnectorStatus'

const useStyles = makeStyles((theme) => ({
    image: {
        height: '80px',
        width: '100%',
    },
    connectors: {
        marginTop: '10px',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    },
    forumLink: {
        '& a': {
            color: theme.palette.primary.main,
        },
    },
    chargerField: {
        textAlign: 'left',
        marginTop: theme.spacing(2),
    },
    detailLabel: {
        textAlign: 'left',
        display: 'flex',
    },
    expansionPanel: {
        color: 'white',
        background: 'transparent',
        boxShadow: 'none',
        width: '100%',
    },
    expansionPanelSummary: {
        padding: 0,
        minHeight: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
        },
        '& .MuiExpansionPanelSummary-expandIcon': {
            padding: 0,
        },
    },
    expansionPanelDetails: {
        padding: 0,
    },
    expansionPanelContainer: {
        margin: '0px 23px 12px 23px',
        backgroundColor: 'black',
        borderRadius: '10px',
    },
    expansionPanelItem: {
        marginBottom: '12px',
        padding: '0px 5px 0px 5px',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to right, #3a4145, rgba(27, 31, 33, 1))',
        borderTopLeftRadius: '10px !important',
        borderTopRightRadius: '10px !important',
        borderBottomLeftRadius: '10px !important',
        borderBottomRightRadius: '10px !important',
    },
    header: {
        fontFamily: "'Play', sans-serif",
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        color: 'white',
    },
    secondaryTitle: {
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#37c0ca',
        marginTop: '12px',
    },
    expansionPanelSummaryContainer: { padding: '0px 16px 0px 16px' },
    connectorType: {
        borderRadius: '8px',
        backgroundColor: '#000507',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
        padding: '5px 10px 5px 10px',
        margin: '3px 5px 0px 0px',
    },
    bottomDecoration: {
        background: 'black',
        height: '175px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    openChargingContainer: {
        zoom: '0.75',
        marginTop: '27px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: '46px',
    },
    openChargingButton: {
        borderRadius: '14px',
        maxWidth: '280px',
        width: '80%',
        height: '60px',
        backgroundImage: 'linear-gradient(254deg, #268890 47%, #42c7d0 140%, #42c7d0 84%)',
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        textAlign: 'center',
        color: 'white',
    },
}))

interface LocationDetailProps {
    locationId: string
    onBackOpen: string
}

export const LocationDetail: React.FC<LocationDetailProps> = ({ locationId, onBackOpen }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const location = useSelector((state: any) => state.locationDetail.value) as LocationWithChargers
    const locationExecuted = useSelector((state: any) => state.locationDetail.executed)

    const chargingSession = useSelector((state: any) => state.chargingSession.value)

    const [windowDimensions, setWindowDimensions] = useState<any>(getWindowDimensions())

    const getSecondaryTitle = (charger: Charger) => {
        let secondary
        if (charger.manufacturer && charger.brand) {
            secondary = `${charger.manufacturer} (${charger.brand})`
        } else {
            secondary = charger.manufacturer ?? charger.brand
        }

        return secondary
    }

    const isDisabled = chargingSession != null && chargingSession.endedAt == null && chargingSession.location?.id !== locationId

    const connectorToString = (connector: ChargerConnector) => {
        const type = getLabelForChargerConnectorType(connector.type, t)
        const socketType = getLabelForChargerConnectorSocketType(connector.socketType, t)

        return `${socketType} ${type}`
    }

    const getChargerAdditionalFields = (charger: Charger) => {
        return [
            [t('detail.howToGetThere'), charger.textInstructionForNavigation],
            [t('detail.howToStartCharging'), charger.textInstructionHowToStartCharging],
        ]
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window
        return {
            width,
            height,
        }
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (!location || location.id !== locationId) {
            dispatch(fetchLocationDetail(locationId))
        }
    }, [dispatch, location, locationId])

    useConnectorStatusPeriodicUpdate(locationId)

    return (
        <>
            <div style={{ height: windowDimensions.height * 1.333337, background: 'black' }}>
                {location && locationExecuted ? (
                    <>
                        <LocationPreview locationId={locationId} onBackOpen={onBackOpen} />
                        <div style={{ backgroundColor: 'black', paddingBottom: '40px', zoom: '0.75' }}>
                            <div className={classes.expansionPanelContainer}>
                                {location.chargers
                                    .sort((x, y) => x.name.localeCompare(y.name))
                                    .map((charger) => (
                                        <ExpansionPanel key={charger.id} className={classes.expansionPanelItem}>
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className={classes.expansionPanelSummaryContainer}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                        <Typography className={classes.header}>
                                                            {charger.name}
                                                        </Typography>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            md={8}
                                                            className={classes.connectors}
                                                        >
                                                            {!Boolean(charger.connectors) ||
                                                            charger.connectors.length === 0 ? (
                                                                <Typography variant="caption">
                                                                    {t('detail.noConnectors')}
                                                                </Typography>
                                                            ) : (
                                                                charger.connectors
                                                                    .sort((x, y) => x.externalId - y.externalId)
                                                                    .map((item: any, index: number) => (
                                                                        <Grid
                                                                            key={index}
                                                                            item
                                                                            className={classes.connectorType}
                                                                            style={{
                                                                                opacity:
                                                                                    item.availability === 0 &&
                                                                                    (item.state === 0 ||
                                                                                        item.state === 10) &&
                                                                                    location.connectedChargers.includes(
                                                                                        item.chargerId
                                                                                    )
                                                                                        ? '1'
                                                                                        : '0.5',
                                                                            }}
                                                                        >
                                                                            {connectorToString(item)}
                                                                            {item.availability === 1 ? " ("+ t('detail.unavailable')+ ")" : ""}
                                                                        </Grid>
                                                                    ))
                                                            )}
                                                        </Grid>
                                                        <Typography className={classes.secondaryTitle}>
                                                            {getSecondaryTitle(charger)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <ImagesManager
                                                            images={charger.images}
                                                            imageClassName={classes.image}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Linkify>
                                                            <Typography
                                                                component="div"
                                                                variant="caption"
                                                                className={classes.forumLink}
                                                            >
                                                                {charger.linkToForum}
                                                            </Typography>
                                                        </Linkify>
                                                        {getChargerAdditionalFields(charger).map(
                                                            (field, index) =>
                                                                field[1] && (
                                                                    <Grid
                                                                        key={index}
                                                                        item
                                                                        container
                                                                        xs={12}
                                                                        className={classes.chargerField}
                                                                    >
                                                                        <ExpansionPanel
                                                                            className={classes.expansionPanel}
                                                                        >
                                                                            <ExpansionPanelSummary
                                                                                expandIcon={
                                                                                    <ExpandMoreIcon
                                                                                        style={{ color: 'white' }}
                                                                                    />
                                                                                }
                                                                                className={
                                                                                    classes.expansionPanelSummary
                                                                                }
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    className={classes.detailLabel}
                                                                                >
                                                                                    <Typography
                                                                                        variant="subtitle2"
                                                                                        style={{ color: 'white' }}
                                                                                    >
                                                                                        {field[0]}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </ExpansionPanelSummary>
                                                                            <ExpansionPanelDetails
                                                                                className={
                                                                                    classes.expansionPanelDetails
                                                                                }
                                                                            >
                                                                                <Typography
                                                                                    align="justify"
                                                                                    variant="caption"
                                                                                    noWrap={false}
                                                                                >
                                                                                    <ReadOnlyEditor value={field[1]} />
                                                                                </Typography>
                                                                            </ExpansionPanelDetails>
                                                                        </ExpansionPanel>
                                                                    </Grid>
                                                                )
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ))}
                            </div>
                        </div>
                        <Grid item container xs={12} className={classes.openChargingContainer}>
                            <Button
                                className={classes.openChargingButton}
                                component={RouterLink}
                                to={`/charging/${locationId}`}
                                disabled={isDisabled}
                                size="medium"
                                variant="contained"
                                color="primary"
                            >
                                {t(
                                    chargingSession?.state === ChargingSessionState.InProgress
                                        ? 'charging.stopCharging'
                                        : 'charging.openCharging'
                                )}
                            </Button>
                        </Grid>
                        <div className={classes.bottomDecoration}></div>
                    </>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </>
    )
}
