import { ERROR_DISMISS, PROMISE_ERROR, LOGIN, SET_PAYMENT_SETTINGS } from "../const";

export const asyncPagedActionCreator = (
  actionName: string,
  workerFunction: (pageIndex: number, pageSize: number) => any,
  key: any,
  currentState: any,
  pageNumber?: number,
  itemsPerPage?: number
) => {
  const pageIndex = key === currentState.key ? (pageNumber ?? currentState.pageIndex + 1) : 1
  const pageSize = itemsPerPage ?? 50

  return asyncActionCreator(actionName, () => workerFunction(pageIndex, pageSize), {
    key,
    pageIndex,
  });
};

export const asyncActionCreator = (
  actionName: string,
  workerFunction: Function,
  additionalSuccessPayload: any = {},
  codeToMessageMap: Array<Array<string>> = []
) => {
  return async (dispatch: Function) => {
    const result: Promise<any> = new Promise((resolve) => {
      dispatch({
        type: `${actionName}_START`,
      });

      workerFunction().then(
        (result: any) => {
          dispatch({
            type: `${actionName}_SUCCESS`,
            payload: {
              ...additionalSuccessPayload,
              result: result,
            },
          });
          resolve(result);
        },
        (error: any) => {
          dispatch({
            type: `${actionName}_ERROR`,
            payload: {
              error: error,
              codeToMessageMap: codeToMessageMap,
            },
          });
          resolve(false);
        }
      );
    });

    return result;
  };
};

export const valueHolderActionCreator = (actionName: string, value: any) => ({
  type: actionName,
  payload: {
    value: value,
  },
});

export const clearAsync = (actionName: string) => ({
  type: `${actionName}_CLEAR`,
});

export const promiseError = (error: any) => ({
  type: PROMISE_ERROR,
  payload: {
    error: error,
  },
});

export const errorDismiss = () => ({
  type: ERROR_DISMISS,
});

export const login = (isAnonymous = false) => {
  return valueHolderActionCreator(LOGIN, {
    isLoggedIn: true,
    isAnonymous,
    user: null,
  });
};

export const setUser = (user: any, isAnonymous: boolean = false) => {
  return valueHolderActionCreator(LOGIN, {
    isLoggedIn: true,
    isAnonymous,
    user,
  });
};

export const logout = () => {
  return valueHolderActionCreator(LOGIN, {
    isLoggedIn: false,
    isAnonymous: false,
    user: null,
  });
};

export const setPaymentSettings = (currency: string) => {
  return valueHolderActionCreator(SET_PAYMENT_SETTINGS, currency)
}