import React, { SFC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, makeStyles, DialogContent, DialogActions, Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { DialogProgress } from '../../shared/components/dialogProgress'
import { DialogConfirmButton } from '../../shared/components/dialogConfirmButtonStyled'
import { editUser, clearEditState } from '../../redux/actions/users'
import { ValidationErrors } from 'final-form'
import { setRequiredErrors } from '../../utils/ui'
import { CountrySelect } from '../../shared/components/countrySelectStyled'
import { CustomTextField } from '../../shared/components/customInputRff'
import PlaceIcon from '@material-ui/icons/Place'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import PublicIcon from '@material-ui/icons/Public'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import DashboardIcon from '@material-ui/icons/Dashboard'

const useStyles = makeStyles((theme) => ({
    form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    formItem: {
        paddingBottom: '16px !important',
        zoom: '0.75',
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
        flexDirection: 'row',
        justifyContent: 'center',
    },
    fullWidth: {
        width: '100%',
    },
    inputIconLeft: {
        color: '#37c0ca',
        marginBottom: '14px',
        marginTop: '20px',
        marginLeft: '15px',
        height: '17px',
    },
    dialog: {},
    inputIconLeftCountry: {
        color: '#37c0ca',
        marginBottom: '15px',
        marginTop: '16px',
        marginLeft: '8px',
        height: '13px',
    },
    formItemCountry: {
        paddingLeft: '19.5px',
        paddingRight: '19.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '19.5px',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
        paddingBottom: '16px',
    },
    textDesc: {
        margin: '0px 0px 0px 0px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    '@global': {
        '.MuiDialog-paperScrollPaper': {
            backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 1) 100%)',
            borderRadius: '16px',
        },
    },
}))

interface AddInvoiceDetailsDialog {
    open: boolean
    onClose(): void
}
const AddInvoiceDetailsDialog: SFC<AddInvoiceDetailsDialog> = ({ open, onClose }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const stateSelector = (state: any) => state.user.edit
    const user = useSelector((state: any) => state.user.login.value.user)

    useEffect(() => {
        return () => {
            dispatch(clearEditState())
        }
    }, [dispatch])

    return (
        <Dialog open={open} className={classes.dialog}>
            <DialogProgress stateSelector={stateSelector} />
            <DialogTitle style={{ color: 'white' }}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>{t('card.invoiceDetails')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.textDesc}>{t('card.insertDetails')}</Typography>
                </Grid>
            </DialogTitle>

            <Form
                onSubmit={async (model) => {
                    const userWithDetails = {
                        ...user,
                        ...model,
                        
                        /**
                         * starbug mp, bug - https://app.asana.com/0/1199592801905492/1204032838409729
                         * zakomentoval som tieto tri riadky, aby sa karta nevymazala z db este skor ako sa potvrdi nova
                         */
                        // paymentRequestId: null,
                        // cardExpiration: null,
                        // cardNumber: null,
                    }
                    const result: any = await dispatch(editUser(userWithDetails))
                    if (result) {
                        onClose()
                    }
                }}
                validate={(model: any): ValidationErrors => {
                    const result: any = {}
                    setRequiredErrors(result, model, ['street', 'city', 'country', 'zip'], t('register.required'))

                    return result
                }}
                initialValues={user}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <DialogContent>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        itemName={'street'}
                                        required={true}
                                        label={t('settings.street')}
                                        password={false}
                                        children={<PlaceIcon className={classes.inputIconLeft} />}
                                    ></CustomTextField>
                                </Grid>
                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        itemName={'city'}
                                        required={true}
                                        label={t('settings.city')}
                                        password={false}
                                        children={<LocationCityIcon className={classes.inputIconLeft} />}
                                    ></CustomTextField>
                                </Grid>
                                <Grid item xs={12} className={classes.formItemCountry}>
                                    <CountrySelect
                                        required={true}
                                        child={<PublicIcon className={classes.inputIconLeftCountry} />}
                                        name="country"
                                        label={t('settings.state')}
                                        variant="standard"
                                    ></CountrySelect>
                                </Grid>
                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        itemName={'zip'}
                                        required={true}
                                        label={t('settings.zip')}
                                        password={false}
                                        children={<LocationCityIcon className={classes.inputIconLeft} />}
                                    ></CustomTextField>
                                </Grid>
                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        required={false}
                                        itemName={'companyName'}
                                        label={t('settings.companyName')}
                                        password={false}
                                        children={<DashboardIcon className={classes.inputIconLeft} />}
                                    ></CustomTextField>
                                </Grid>
                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        required={false}
                                        itemName={'companyId'}
                                        label={t('settings.companyId')}
                                        password={false}
                                        children={<DashboardIcon className={classes.inputIconLeft} />}
                                    ></CustomTextField>
                                </Grid>
                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        required={false}
                                        itemName={'taxId'}
                                        label={t('settings.taxId')}
                                        password={false}
                                        children={<CollectionsBookmarkIcon className={classes.inputIconLeft} />}
                                    ></CustomTextField>
                                </Grid>
                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        required={false}
                                        itemName={'vatId'}
                                        label={t('settings.vatId')}
                                        password={false}
                                        children={<CollectionsBookmarkIcon className={classes.inputIconLeft} />}
                                    ></CustomTextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <DialogConfirmButton label={t('common.continue')} stateSelector={stateSelector} />
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default AddInvoiceDetailsDialog
