import ValidationError from './validationError'

export function validateEmail(email: string): boolean {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export function getErrorMessage(error: Error, codeToMessageMap: Array<Array<string>>, defaultMessage: string): string {
    let result: string = defaultMessage
    if (error instanceof ValidationError) {
        const mappedValidationErrors = codeToMessageMap
            // eslint-disable-next-line eqeqeq
            .filter((x) => x.length === 2 && error.errors.find((e) => e.code == x[0]))
            .map((x) => x[1])
        if (mappedValidationErrors.length > 0 && mappedValidationErrors.length === error.errors.length) {
            result = mappedValidationErrors.join('\n')
        }
    }

    return result
}
