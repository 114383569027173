import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { DeleteConfirmationDialog } from './deleteConfirmationDialog'
import { makeStyles, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
    deleteOverlayWrapper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        '&:hover $deleteButton': {
            visibility: 'visible',
        },
    },
    deleteButton: {
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        zIndex: 10,
        backgroundColor: 'white !important',
        visibility: 'hidden',
        padding: theme.spacing(0.5),
    },
}))

type DeleteOverlayProps = {
    progressStateSelector(state: any): any
    deleteAction(): Promise<any>
    children: any
}
export function DeleteOverlay({ progressStateSelector, deleteAction, children }: DeleteOverlayProps) {
    const classes = useStyles()

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
    const state = useSelector(progressStateSelector)

    return (
        <>
            {isDeleteDialogOpen && (
                <DeleteConfirmationDialog
                    open={true}
                    onCancel={() => setIsDeleteDialogOpen(false)}
                    onConfirm={async () => {
                        await deleteAction()
                        setIsDeleteDialogOpen(false)
                    }}
                    inProgress={!state.executed}
                />
            )}
            <div className={classes.deleteOverlayWrapper}>
                <IconButton onClick={() => setIsDeleteDialogOpen(true)} className={classes.deleteButton}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
                {children}
            </div>
        </>
    )
}
