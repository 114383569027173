import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateLocationInfo } from './../redux/actions/locations'
import { config } from '../config'

var socket:WebSocket;

export function closeWebSocket() {
    socket.close();
}

export function useChargersStatusPeriodicUpdate() {
    const dispatch = useDispatch()

    useEffect(() => {
        if (socket == null) {
            socket = new WebSocket(`${config.websocket}/ocpp/client_chargers_status`, [
                'ocpp1.6',
            ]);;
            socket.onmessage = (event) => {
                const chargerInfoChanged = JSON.parse(event.data)
                console.log(chargerInfoChanged)
                dispatch(updateLocationInfo(chargerInfoChanged))
            }
            return () => {
            }
        }
    }, [dispatch])
}
    