import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    noProgress: {
        height: theme.spacing(0.5),
    },
}))

export function NoProgress() {
    const classes = useStyles()

    return <div className={classes.noProgress} />
}
