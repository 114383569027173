import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getChargerConnectorSocketTypes } from '../../shared/types/charger'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setAvailability, setDistance, setConnector, setSortByDistance } from '../../redux/actions/filter'
import { SinglePicker, MultiPicker } from '../../utils/OptionPicker'

const useStyles = makeStyles((theme) => ({
    chipsWrap: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent',
        },
    },
    chipsWrapAbsolute: {
        position: 'fixed',
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        maxWidth: 'min-content',
        width: '100%',
    },
    chipsWrapInner: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        width: 'auto',
    },
    chipWrapper: {
        display: 'inline-flex',
        '&:last-child': {
            paddingRight: theme.spacing(2),
        },
    },
}))

interface ChipsBarProps {
    elevate?: boolean
    fullWidth: boolean
    useWhiteStyle?: boolean
}
const ChipsBar: React.FC<ChipsBarProps> = ({ elevate = true, fullWidth, useWhiteStyle }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const filter = useSelector((state: any) => state.filter)

    const distanceValues = [
        { id: 1, distanceInKm: 1 },
        { id: 2, distanceInKm: 3 },
        { id: 3, distanceInKm: 15 },
        { id: 4, distanceInKm: 150 },
    ]
    const connectorValues = getChargerConnectorSocketTypes(t).map((x, index) => ({
        id: index + 1,
        label: x.label,
        connector: x.id,
    }))

    const onlyAvailableValue: boolean = filter.availability.value === true
    const sortByDistance: boolean = filter.sortByDistance.value === true
    const chips = [
        {
            show: !useWhiteStyle,
            label: sortByDistance ? t('filter.sortByDistance') : t('filter.sortByName'),
            active: false,
            onClick: (event: any) => {
                if (!filter.distance.value?.km) {
                    dispatch(setSortByDistance(!sortByDistance))
                    const kmOnlyTimeoutId = setTimeout(() => {
                        dispatch(
                            setDistance({
                                location: undefined,
                            })
                        )
                    }, 150)
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((x) => {
                            clearTimeout(kmOnlyTimeoutId)
                            dispatch(
                                setDistance({
                                    location: {
                                        lat: x.coords.latitude,
                                        lng: x.coords.longitude,
                                        accuracy: x.coords.accuracy,
                                    },
                                })
                            )
                        })
                    }
                }
            },
        },
        {
            show: true,
            label: t('filter.onlyAvailable'),
            active: onlyAvailableValue,
            onClick: () => dispatch(setAvailability(!onlyAvailableValue)),
        },
        {
            show: true,
            label: t('filter.distance'),
            active:
                Boolean(filter.distance.value?.km) &&
                Boolean(filter.distance.value?.location?.lat) &&
                Boolean(filter.distance.value?.location?.lng),
            menu: {
                onChange: (event: any) => {
                    const kmToSet = distanceValues.find((x) => x.id === event.target.value)?.distanceInKm ?? undefined

                    const kmOnlyTimeoutId = setTimeout(() => {
                        dispatch(
                            setDistance({
                                km: kmToSet,
                                location: undefined,
                            })
                        )
                    }, 150)

                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((x) => {
                            clearTimeout(kmOnlyTimeoutId)
                            dispatch(
                                setDistance({
                                    km: kmToSet,
                                    location: {
                                        lat: x.coords.latitude,
                                        lng: x.coords.longitude,
                                        accuracy: x.coords.accuracy,
                                    },
                                })
                            )
                        })
                    }
                    dispatch(setSortByDistance(true))
                },
                value: distanceValues.find((x) => x.distanceInKm === filter.distance.value?.km)?.id ?? 0,
                items: [
                    { id: 0, label: t('filter.noFilter') },
                    ...distanceValues.map((x) => ({ id: x.id, label: t('filter.distanceKm', { km: x.distanceInKm }) })),
                ],
            },
        },
        {
            show: true,
            label: t('filter.connector'),
            active: filter.connector.value != null,
            menu: {
                onChange: (event: any) => {
                    dispatch(
                        setConnector(connectorValues.find((x) => x.id === event.target.value)?.connector ?? undefined)
                    )
                },
                value: connectorValues.find((x) => x.connector === filter.connector.value)?.id ?? 0,
                items: [
                    { id: 0, label: t('filter.noFilter') },
                    ...connectorValues.map((x) => ({
                        id: x.id,
                        label: x.label,
                    })),
                ],
            },
        },
    ]

    return (
        <div
            className={
                fullWidth
                    ? `${classes.chipsWrap} chipsWrapper`
                    : `${classes.chipsWrap} ${classes.chipsWrapAbsolute} chipsWrapper`
            }
        >
            <div className={classes.chipsWrapInner}>
                {chips.map(
                    (item, index) =>
                        item.show && (
                            <div key={index} className={classes.chipWrapper}>
                                {item.menu ? (
                                    <MultiPicker
                                        key={index}
                                        active={item.active}
                                        elevate={elevate}
                                        label={item.label}
                                        value={item.menu.value}
                                        items={item.menu.items}
                                        onChange={item.menu.onChange}
                                        useWhiteStyle={useWhiteStyle}
                                    />
                                ) : (
                                    <SinglePicker
                                        active={item.active}
                                        elevate={elevate}
                                        label={item.label}
                                        onClick={item.onClick}
                                        useWhiteStyle={useWhiteStyle}
                                    />
                                )}
                            </div>
                        )
                )}
            </div>
        </div>
    )
}

export default ChipsBar
