import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import SearchBar from '../../core/appBar/SearchBar'
import { Grid, Drawer } from '@material-ui/core'
import Map from '../../core/map/Map'
import { useCalcHeightToBottom } from '../../utils/ui'
import { LocationDetail } from './LocationDetail'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Flip } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useChargersStatusPeriodicUpdate } from '../../utils/currentChargersStatus'
import { BottomControlPanel } from '../../core/footer/bottomControlPanel'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        backgroundColor: '#ffffff',
        height: '100%',
        flexDirection: 'column',
    },
    content: {
        zoom: '0.75',
        flex: '1 1',
        zIndex: 1,
    },
    mapWrapper: {
        width: '100%',
    },
    drawerContent: { background: 'transparent' },
}))

export default function MainMap() {
    const classes = useStyles()
    const history = useHistory()
    const baseUrl = '/main-map'

    const { t } = useTranslation()

    const mapHeightFill = useCalcHeightToBottom('locationsMapWrapper')
    const [mapTopPadding, setMapTopPadding] = useState<number>(0)
    const [triggerBoundsUpdate, setTriggerBoundsUpdate] = useState<boolean>(true)

    const [showWariningToast, setShowWariningToast] = useState<boolean>(true)
    const [isWariningToastVisible, setWariningToastVisible] = useState<boolean>(false)

    const previewRouteMatch = useRouteMatch(`${baseUrl}/preview/:id/:source`)
    const detailRouteMatch = useRouteMatch(`${baseUrl}/detail/:id/:source`)

    const user = useSelector((state: any) => state.user.login.value.user)

    const routeParams: any = previewRouteMatch ? previewRouteMatch?.params : detailRouteMatch?.params
    const locationId: string = routeParams?.id
    const source: string = routeParams?.source

    const toPreview = (locationId: string) => {
        history.push(`${baseUrl}/preview/${locationId}/map`)
    }

    const drawerClose = () => history.push(baseUrl)

    const modalProps = {
        BackdropProps: {
            invisible: false,
        },
    }

    const isDrawerOpen = Boolean(previewRouteMatch) || Boolean(detailRouteMatch)

    const onToastClose = () => {
        setTimeout(function () {
            //Start the timer
            setWariningToastVisible(false)
        }, 200)
    }

    useEffect(() => {
        if (previewRouteMatch?.isExact) {
            setTriggerBoundsUpdate(false)
        }
    }, [previewRouteMatch])

    useEffect(() => {
        const onToastClick = () => {
            history.push('/register-card/map')
        }
        if (user.cardExpiration) {
            var currentDate = new Date()
            var expirationDate = new Date(
                String(currentDate.getFullYear()).substring(0, 2) +
                    user.cardExpiration.substring(2, 4) +
                    '-' +
                    user.cardExpiration.substring(0, 2)
            )
            if (
                showWariningToast &&
                currentDate.getMonth() === expirationDate.getMonth() &&
                currentDate.getFullYear() === expirationDate.getFullYear() &&
                locationId === undefined
            ) {
                toast.warning(t('common.paymentCardExpirationWarning'), {
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false,
                    onClose: onToastClose,
                    onClick: onToastClick,
                })
                setWariningToastVisible(true)
                setShowWariningToast(false)
            }
            if (
                showWariningToast &&
                currentDate.getMonth() > expirationDate.getMonth() &&
                currentDate.getFullYear() >= expirationDate.getFullYear() &&
                locationId === undefined
            ) {
                toast.error(t('common.paymentCardExpirationError'), {
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false,
                    onClose: onToastClose,
                    onClick: onToastClick,
                })
                setWariningToastVisible(true)
                setShowWariningToast(false)
            }
        }
    }, [user, locationId, showWariningToast, t, history])

    useChargersStatusPeriodicUpdate()

    return (
        <>
            <div>
                <ToastContainer
                    style={{
                        width: '100%',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        paddingTop: '25px',
                        zoom: '0.75',
                    }}
                    position="top-center"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    transition={Flip}
                />
                <div className={classes.main}>
                    {Boolean(isDrawerOpen) ? (
                        ''
                    ) : (
                        <div
                            ref={(element: any) => {
                                if (element) {
                                    const chipsWrapperElements = element.getElementsByClassName('chipsWrapper')
                                    if (chipsWrapperElements && chipsWrapperElements.length > 0) {
                                        const chipsWrapper = chipsWrapperElements[0].getBoundingClientRect()
                                        setMapTopPadding(chipsWrapper.top + chipsWrapper.height)
                                    }
                                }
                            }}
                        >
                            <SearchBar
                                float={true}
                                fullWidthChipsBar={false}
                                moveDown={isWariningToastVisible}
                                blackStyle={false}
                            />
                        </div>
                    )}
                    <div className={classes.content}>
                        <Grid item className={classes.mapWrapper}>
                            <div id="locationsMapWrapper" ref={mapHeightFill}>
                                {
                                    <Map
                                        locationId={locationId}
                                        onLocationSelected={(location) => toPreview(location.id)}
                                        mapTopPadding={mapTopPadding}
                                        shouldUpdateBounds={triggerBoundsUpdate}
                                    />
                                }
                            </div>
                            <Drawer ModalProps={modalProps} anchor="top" open={isDrawerOpen} onClose={drawerClose}>
                                <div className={classes.drawerContent}>
                                    {Boolean(previewRouteMatch) && (
                                        <LocationDetail locationId={locationId} onBackOpen={source} />
                                    )}
                                </div>
                            </Drawer>
                        </Grid>
                    </div>
                    <BottomControlPanel actualPage={'MainMap'} positionAbsolute={false} />
                </div>
            </div>
        </>
    )
}
