import React from 'react'
import {
    Typography
} from '@material-ui/core'

export type NoDataProps = {
    text: string
}

export function NoData({ text }: NoDataProps) {
    return (
        <Typography component="div" variant="h6">
            {text}
        </Typography>
    )
}