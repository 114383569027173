import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Image } from './../../shared/components/Image'
import { LocationListItem } from '../../shared/types/location'
import Place from '@material-ui/icons/Place'

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        height: '121px',
        background: 'black',
        margin: '0px 23px 12px 23px',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to left, rgba(27, 31, 33, 0), #3a4245 77%)',
        zoom: '0.75',
    },
    itemWrapper: {},
    itemWrapperInner: {},
    imageContainer: {
        width: '83px',
        background: 'white',
        height: '121px',
        borderRadius: '10px',
    },
    dataContainer: {
        width: 'calc(100% - 83px)',

        height: '121px',
    },
    dataRowContainer: { margin: '21px 22px 0px 21px', width: 'calc(100% - 43px)' },
    image: {},
    dataRowItem: { marginBottom: '12px' },
    dataRowItemHeadline: {
        fontFamily: "'Play', sans-serif",
        fontSize: '18px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 0.89,
        letterSpacing: 'normal',
        color: 'white',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dataRowItemConnectorCount: {
        height: '24px',
        fontFamily: "'Muli', sans-serif",
        padding: '4px 9px',
        borderRadius: '8px',
        backgroundImage: 'linear-gradient(65deg, #36c076, #61e59e 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        color: 'white',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dataRowItemConnectorCountDesc: {
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#787c7e',
        display: 'flex',
        marginLeft: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dataRowItemPlaceIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '3px',
    },
    dataRowItemPlaceIcon: {
        height: '14px',
        width: '14px',
        color: '#37c0ca',
    },
    dataRowItemPlaceName: {
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#37c0ca',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    chargerImage: {
        height: '100%',
        width: '100%',
    },
    owerflowText: {
        margin: '0',
        overflow: 'hidden',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}))

interface ListItemProps {
    item: LocationListItem
}

export const ListItem: React.FC<ListItemProps> = ({ item }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className={classes.itemContainer}>
            <RouterLink to={`main-map/preview/${item.id}/list`}>
                <Grid item xs={12} container justify="center" className={classes.itemWrapper}>
                    <Grid item xs={12} container justify="center" className={classes.itemWrapperInner}>
                        <Grid item className={classes.imageContainer}>
                            <Image
                                className={classes.chargerImage}
                                imageClassName={classes.chargerImage}
                                url={item.images.length > 0 ? item.images[0].url : undefined}
                            />
                        </Grid>
                        <Grid item className={classes.dataContainer}>
                            <Grid item xs={12} container justify="center" className={classes.dataRowContainer}>
                                <Grid item xs={12} container className={classes.dataRowItem}>
                                    <Grid item className={classes.dataRowItemHeadline}>
                                        {item.name}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className={classes.dataRowItem}>
                                    <Grid
                                        item
                                        className={classes.dataRowItemConnectorCount}
                                        style={
                                            item.availableConnectors > 0
                                                ? { backgroundImage: 'linear-gradient(65deg, #36c076, #61e59e 100%)' }
                                                : { backgroundImage: 'linear-gradient(70deg, #e8a437, #f1bc68 97%)' }
                                        }
                                    >
                                        {t('detail.chargersCount', {
                                            countNotInUse: item.availableConnectors,
                                            countTotal: item.connectorCount,
                                        })}
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.dataRowItemConnectorCountDesc}
                                        style={{ width: 'calc(100% - 75px)' }}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <p className={classes.owerflowText}>{t('detail.connectorsCountAvailable')}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className={classes.dataRowItem}>
                                    <Grid item className={classes.dataRowItemPlaceIconContainer}>
                                        <Place className={classes.dataRowItemPlaceIcon} />
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.dataRowItemPlaceName}
                                        style={{ width: 'calc(100% - 30px)' }}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <p className={classes.owerflowText}>{item.city + ', ' + item.street}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </RouterLink>
        </Grid>
    )
}
