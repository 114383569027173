/* eslint-disable react-hooks/exhaustive-deps */
import React, { SFC, useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LocationInfo, Location } from '../../shared/types/location'
import { LocationMarker } from '../marker/Marker'
import { GoogleMap, LoadScript, MarkerClusterer } from '@react-google-maps/api'
import { makeStyles, Paper } from '@material-ui/core'
import { fetchMapLocations } from '../../redux/actions/locations'
import { CurrentPositionMarker } from '../marker/CurrentPositionMarker'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import { setDistance } from '../../redux/actions/filter'
import MarkerIcon from '../../static/map_icon_group.png'

const useStyles = makeStyles((theme) => ({
    map: {
        height: '100%',
        width: '100%',
    },
    currentLocation: {
        position: 'absolute',
        left: theme.spacing(1),
        top: 'auto',
        right: 'auto',
        background: 'white',
        cursor: 'pointer',
        bottom: theme.spacing(3),
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:active > svg': {
            fill: 'black',
        },
    },
}))

interface MapProps {
    onLocationSelected(location: LocationInfo): void
    locationId?: string
    mapTopPadding: number
    shouldUpdateBounds: boolean
}
const Map: SFC<MapProps> = ({ onLocationSelected, locationId, mapTopPadding, shouldUpdateBounds = true }) => {
    const classes = useStyles()
    const mapRef = useRef<any>(null)

    const dispatch = useDispatch()

    const mapToken = useSelector((state: any) => state.mapToken.value)

    const locations = useSelector((state: any) => state.locationsMap)
    const filter = useSelector((state: any) => state.filter)
    const user = useSelector((state: any) => state.user.login.value.user)
    const tenant = useSelector((state: any) => state.tenant.value)

    var resetMap = true

    const skipMapBoundsUpdate = useRef(false)
    const [initialized, setInitialized] = useState<boolean>(false)
    const [initialPositionResolved, setInitialPositionResolved] = useState<boolean>(false)

    const setMapBounds = useCallback(
        (locationsInput: LocationInfo[]) => {
            const map = mapRef.current
            if (map) {
                if (locationsInput.length === 1) {
                    map.setCenter(
                        new (window as any).google.maps.LatLng(locationsInput[0].latitude, locationsInput[0].longitude)
                    )
                    map.setZoom(16)
                } else if (locationsInput.length > 1 && resetMap) {
                    resetMap = false
                    const bounds = new (window as any).google.maps.LatLngBounds()
                    locationsInput.forEach((x: LocationInfo) =>
                        bounds.extend(new (window as any).google.maps.LatLng(x.latitude, x.longitude))
                    )
                    map.fitBounds(bounds, { top: mapTopPadding })
                }
            }
        },
        [mapTopPadding]
    )

    const updateCurrentPosition = useCallback(
        (distanceKm: number, callback?: () => void) => {
            const map = mapRef.current
            if (map && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((x) => {
                    map.setCenter(new (window as any).google.maps.LatLng(x.coords.latitude, x.coords.longitude))
                    map.setZoom(16)
                    dispatch(
                        setDistance({
                            km: distanceKm,
                            location: {
                                lat: x.coords.latitude,
                                lng: x.coords.longitude,
                                accuracy: x.coords.accuracy,
                            },
                        })
                    )
                    if (callback) {
                        callback()
                    }
                    skipMapBoundsUpdate.current = true
                })
            }
        },
        [dispatch]
    )

    const initializeMapIfNecessary = useCallback(() => {
        const defaultSettings = {
            center: {
                lat: 48.144892,
                lng: 17.107137,
            },
            zoom: 14,
        }
        if (initialized === false && locations.executed && mapRef.current) {
            const defaultInitializationTimeoutId = setTimeout(() => {
                if (locations.value.length === 0) {
                    mapRef.current.setCenter(
                        new (window as any).google.maps.LatLng(defaultSettings.center.lat, defaultSettings.center.lng)
                    )
                    mapRef.current.setZoom(defaultSettings.zoom)
                } else {
                    setMapBounds(locations.value)
                }
                setInitialPositionResolved(true)
            }, 150)

            updateCurrentPosition(filter.distance.value.km, () => {
                clearTimeout(defaultInitializationTimeoutId)
                setInitialPositionResolved(true)
            })

            setInitialized(true)
        }
    }, [updateCurrentPosition, filter, initialized, locations.executed, locations.value, mapRef, setMapBounds])

    const onLoad = (map: any) => {
        mapRef.current = map
        initializeMapIfNecessary()
    }

    const handleLocationSelected = (location: LocationInfo) => {
        mapRef.current.setCenter(new (window as any).google.maps.LatLng(location.latitude, location.longitude))
        onLocationSelected(location)
    }

    useEffect(() => {
        initializeMapIfNecessary()
    }, [initializeMapIfNecessary, locations, mapRef])

    useEffect(() => {
        if (skipMapBoundsUpdate.current) {
            skipMapBoundsUpdate.current = false
        } else {
            if (shouldUpdateBounds) {
                setMapBounds(locations.value)
            }
        }
    }, [setMapBounds, locations.value, shouldUpdateBounds])

    useEffect(() => {
        if (initialPositionResolved) {
            dispatch(
                fetchMapLocations({
                    search: filter.search.value,
                    availability: filter.availability.value,
                    distance: filter.distance.value,
                    connector: filter.connector.value,
                    showHiddenLocation: user.showHiddenLocation,
                    tenantId: tenant?.id,
                })
            )
            if (!skipMapBoundsUpdate.current) {
                setMapBounds(locations.value)
            }
        }
    }, [dispatch, filter, initialPositionResolved])

    useEffect(() => {
        if (initialized && locationId != null) {
            const location = locations.value.find((x: Location) => x.id === locationId)
            if (location != null) {
                mapRef.current.setCenter(new (window as any).google.maps.LatLng(location.latitude, location.longitude))
                mapRef.current.setZoom(14)
            }
        }
    }, [locationId, locations, initialized])

    return (
        <div className={classes.map}>
            <LoadScript googleMapsApiKey={mapToken}>
                <GoogleMap
                    onLoad={onLoad}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    options={{
                        gestureHandling: 'greedy',
                        fullscreenControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControlOptions: { position: 8 },
                    }}
                >
                    <MarkerClusterer
                        styles={[
                            {
                                textColor: 'white',
                                url: MarkerIcon,
                                anchorIcon: [35, 35],
                                textSize: 15,
                                height: 70,
                                width: 70,
                            },
                            {
                                textColor: 'white',
                                url: MarkerIcon,
                                anchorIcon: [35, 35],
                                textSize: 15,
                                height: 70,
                                width: 70,
                            },
                            {
                                textColor: 'white',
                                url: MarkerIcon,
                                anchorIcon: [35, 35],
                                textSize: 15,
                                height: 70,
                                width: 70,
                            },
                        ]}
                        calculator={(markers: any) => ({
                            text: markers.length,
                            index: 6,
                            title: '',
                        })}
                    >
                        {(clusterer) =>
                            locations.value.map((location: LocationInfo) => (
                                <LocationMarker
                                    key={location.id}
                                    location={location}
                                    onClick={() => handleLocationSelected(location)}
                                    clusterer={clusterer}
                                />
                            ))
                        }
                    </MarkerClusterer>
                    <Paper
                        className={classes.currentLocation}
                        elevation={1}
                        square
                        onClick={() => updateCurrentPosition(filter.distance.value.km)}
                    >
                        <MyLocationIcon color="action" />
                    </Paper>
                    {filter.distance.value?.location && (
                        <CurrentPositionMarker coordinates={filter.distance.value.location} />
                    )}
                </GoogleMap>
            </LoadScript>
        </div>
    )
}

export default Map
