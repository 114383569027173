// based on https://github.com/vigzmv/react-promise-suspense/
import deepEqual from "fast-deep-equal";

interface PromiseCache {
  promise?: Promise<void>;
  inputs?: Array<any>;
  error?: any;
  response?: any;
}

const promiseCaches: { [key: string]: PromiseCache } = {};

const usePromiseResult = (
  cacheKey: string,
  promise: (...inputs: any) => any,
  inputs: Array<any>
) => {
  if (!promiseCaches[cacheKey]) {
    promiseCaches[cacheKey] = {};
  }

  const promiseCache = promiseCaches[cacheKey];
  if (deepEqual(inputs, promiseCache.inputs)) {
    // If an error occurred,
    if (Object.prototype.hasOwnProperty.call(promiseCache, "error")) {
      throw promiseCache.error;
    }

    // If a response was successful,
    if (Object.prototype.hasOwnProperty.call(promiseCache, "response")) {
      return promiseCache.response;
    }
    throw promiseCache.promise;
  }

  // The request is new or has changed.

  promiseCaches[cacheKey] = {
    promise:
      // Make the promise request.
      promise(...inputs)
        .then((response: any) => {
          promiseCaches[cacheKey].response = response;
        })
        .catch((e: any) => {
          promiseCaches[cacheKey].error = e;
        }),
    inputs,
  };
  throw promiseCaches[cacheKey].promise;
};

export default usePromiseResult;
