import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Fab } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles((theme) => ({
    appWrapFloat: {
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 1700,
    },
    icon: {
        borderRadius: '14px',
        backgroundImage: 'linear-gradient(141deg, #3a4245, #1b1f21 100%)',
        marginTop: '23px',
        marginLeft: '23px',
        cursor: 'pointer !important',
        zIndex: 1701,
        width: '48px',
        height: '60px',
        color: 'white',
    },
}))

interface DetailHeaderProps {
    locationId: string
    backClick: any
}

export const DetailHeader: React.FC<DetailHeaderProps> = ({ locationId, backClick }) => {
    const classes = useStyles()
    return (
        <div className={classes.appWrapFloat} id="app-header">
            <Grid container>
                <Fab onClick={() => backClick(locationId)} className={classes.icon}>
                    <ArrowBackIcon />
                </Fab>
            </Grid>
        </div>
    )
}
