import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { AsyncButton } from '../../shared/components/asyncButton'
import { sendJson } from '../../shared/utils/api'
import { usePromise } from '../../shared/utils/usePromise'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from '../../shared/utils/validation'
import { useDispatch } from 'react-redux'
import { BackButton } from '../../shared/components/backButton'
import { CustomTextField } from '../../shared/components/customInput'
import { PulsingHeader } from '../../shared/components/pulsingHeader'
import EnvelopeIcon from '../../static/icons/icon-envelope-blue.svg'
import EnvelopeRedIcon from '../../static/icons/icon-envelope-red.svg'
import { getDefaultLanguage } from '../../config'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
    },
    wrapperBottom: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    wrapperInner: {
        maxWidth: '400px',
        paddingBottom: '0px',
        padding: '23px',
        margin: 'auto',
        color: 'white',
    },
    wrapperInnerBottom: {
        maxWidth: '400px',
        paddingTop: '0px',
        paddingBottom: '176px',
        margin: 'auto',
        color: 'white',
    },
    titleContainer: {
        marginBottom: '37px',
        textAlign: 'center',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    logo: {
        paddingBottom: theme.spacing(6),
    },
    buttonWrapper: {
        paddingTop: theme.spacing(8),
    },
    backButton: {
        width: '100%',
        backgroundColor: '#ffffff',
        color: theme.palette.secondary.dark,
        '&:hover': {
            color: '#000000',
            backgroundColor: theme.palette.primary.light,
        },
    },
    forgotPasswordButtonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    forgotPasswordButtonContainer: {
        marginBottom: '226px',
        marginLeft: '37px',
        marginRight: '37px',
        marginTop: '30px',
    },
    forgotPasswordButton: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        borderRadius: '14px',
    },
    textDescContainer: {
        textAlign: 'center',
        marginBottom: '50px',
        marginRight: '36px',
        marginLeft: '36px',
    },
    textDesc: {
        margin: '0px 0px 0px 0px',
        fontFamily: 'Muli',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    inputIconLeft: {
        color: '#37c0ca',
        marginBottom: '14px',
        marginTop: '20px',
        marginLeft: '8px',
        height: '17px',
    },
    message: {
        paddingTop: theme.spacing(3),
    },
    input: {
        paddingLeft: theme.spacing(1),
    },
}))

const forgotPasswordAction = (email: string) =>
    sendJson('ForgotPassword', 'POST', { email, language: getDefaultLanguage() })

const ForgotPassword = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()

    const dispatch = useDispatch()

    const [email, setEmail] = useState<string>('')
    const [inputError, setInputError] = useState<string>()

    const [forgotPasswordStart, inProgress, result, error] = usePromise(forgotPasswordAction)

    const cleanError = () => {
        setInputError(undefined)
    }

    useEffect(() => {
        if (error) {
            setInputError(
                getErrorMessage(
                    error,
                    [
                        ['2', t('register.invalidEmail')],
                        ['4', t('login.forgotPasswordErrorEmailDoesNotExist')],
                        ['8', t('login.forgotPasswordErrorEmailIsRegisteredViaSocialNetwork')],
                        ['16', t('login.forgotPasswordNotConfirmedAccount')],
                    ],
                    t('login.forgotPasswordError', { error })
                )
            )
        } else {
            setInputError(undefined)
        }
    }, [dispatch, t, error])

    useEffect(() => {
        if (result) {
            history.push('/?forgotPasswordRequested')
        }
    }, [result, history])

    return (
        <div className={classes.wrapper} style={{ zoom: '0.75' }}>
            <div className={classes.wrapper}>
                <form noValidate>
                    <Grid container alignItems="flex-start" className={classes.wrapperInner} spacing={0}>
                        <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            style={{ marginBottom: '37px' }}
                        >
                            <Grid item style={{ justifySelf: 'start' }}>
                                <BackButton
                                    buttonProps={{
                                        onClick: (event: any) => {
                                            history.push('/')
                                        },
                                    }}
                                ></BackButton>
                            </Grid>
                            <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                <PulsingHeader mainWindowScroll={window.pageYOffset} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography variant="h3" className={classes.title}>
                                {t('forgotPassword.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.textDescContainer}>
                            <p className={classes.textDesc}>{t('forgotPassword.description')}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                errorState={inputError ? true : false}
                                helperText={inputError}
                                textFieldProps={{
                                    value: email,
                                    onChange: (event: any) => setEmail(event.target.value),
                                    label: t('login.email'),
                                    onFocus: cleanError,
                                }}
                                password={false}
                                standardChildren={
                                    <img alt="Envelope" src={EnvelopeIcon} className={classes.inputIconLeft} />
                                }
                                errorChildren={
                                    <img alt="EnvelopeError" src={EnvelopeRedIcon} className={classes.inputIconLeft} />
                                }
                            ></CustomTextField>
                        </Grid>
                        <Grid item xs={12} className={classes.forgotPasswordButtonContainer}>
                            <Grid container direction="row" justify="space-between">
                                <Grid item xs={12}>
                                    <AsyncButton
                                        inProgress={inProgress}
                                        buttonProps={{
                                            className: classes.forgotPasswordButton,
                                            fullWidth: true,
                                            variant: 'contained',
                                            color: 'primary',
                                            type: 'submit',
                                            onClick: (event: any) => {
                                                event.preventDefault()
                                                if (email) {
                                                    forgotPasswordStart(email)
                                                } else {
                                                    setInputError(t('register.required'))
                                                }
                                            },
                                        }}
                                    >
                                        <p className={classes.forgotPasswordButtonText}>{t('login.sendEmail')}</p>
                                    </AsyncButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div className={classes.wrapperBottom}>
                <form noValidate>
                    <Grid container alignItems="flex-start" className={classes.wrapperInnerBottom} spacing={0}></Grid>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword
