import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import EyeIcon from '../static/icon-eye-white.svg'
import EyeOffIcon from '../static/icon-eye-off-white.svg'
import { TextField } from 'mui-rff'
import { Field } from 'react-final-form'

const commonStyles = makeStyles((theme) => ({
    input: {
        paddingLeft: '5px',
        marginInlineStart: '-22px',
        color: 'white',
        background: 'black',
        paddingRight: '5px',
        paddingTop: '2px',
        fontSize: '14px',
        border: '0px !important',
        fontFamily: "'Mulish', sans-serif",
        WebkitAppearance: 'none',
    },
    inputIconRight: {
        color: 'white',
        opacity: '0.3',
        marginBottom: '14px',
        marginTop: '20px',
        height: '17px',
    },
}))

const errorStyles = makeStyles((theme) => ({
    inputTextField: {
        '&& .MuiInput-root:hover::before': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiInput-root:hover::after': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiFormLabel-root.Mui-focused': {
            color: 'white',
            border: 'none',
        },
        '& label.Mui-focused': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            paddingTop: '0px',
            color: '#de384a !important',
            background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 55%, rgba(0,0,0,0) 55%)',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            marginLeft: '-60px',
            marginRight: '0px',
            marginTop: '57px',
            paddingTop: '3px',
            fontSize: '10px',
            position: 'absolute',
            color: '#de384a',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            '&.MuiInputLabel-outlined': {
                background: 'black',
                paddingRight: '10px',
            },
            '&.MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                padding: '6px',
            },
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        '& input:-internal-autofill-focused': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        WebkitBoxShadow: '0 0 0 50px black inset',
        WebkitAppearance: 'none',
    },
    wrapper: {
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #de384a',
        background: 'black',
    },
    '@global': {
        '.MuiFormLabel-root.Mui-error': {
            color: '#de384a',
        },
    },
}))

const normalStyles = makeStyles((theme) => ({
    inputTextField: {
        '&& .MuiInput-root:hover::before': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiInput-root:hover::after': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiFormLabel-root.Mui-focused': {
            color: 'white',
            border: 'none',
        },
        '& label.Mui-focused': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            paddingTop: '0px',
            color: '#00e3e3 !important',
            background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 55%, rgba(0,0,0,0) 55%)',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            marginLeft: '-60px',
            marginRight: '0px',
            marginTop: '57px',
            paddingTop: '6px',
            position: 'absolute',
            fontSize: '10px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.17,
            letterSpacing: 'normal',
            fontFamily: "'Mulish', sans-serif",
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            '&.MuiInputLabel-outlined': {
                background: 'black',
                paddingRight: '10px',
            },
            '&.MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                padding: '6px',
            },
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        '& input:-internal-autofill-focused': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        WebkitBoxShadow: '0 0 0 50px black inset',
        WebkitAppearance: 'none',
    },
    wrapper: {
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #565b5c',
        background: 'black',
    },
}))

type CustomTextFieldProps = {
    label: string
    children?: any
    errorChildren?: any
    password: boolean
    itemName?: string
    required?: boolean
}
export function CustomTextField({
    label,
    children,
    errorChildren,
    password,
    itemName,
    required,
}: CustomTextFieldProps) {
    const commonClasses = commonStyles()
    const errorClasses = errorStyles()
    const normalClasses = normalStyles()

    const [showPassword, setShowPassword] = useState<boolean>()

    const changePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    return (
        <Field name={itemName ? itemName : ''}>
            {({ input, meta }) => (
                <Grid
                    item
                    xs={12}
                    className={meta.error && meta.touched ? errorClasses.wrapper : normalClasses.wrapper}
                >
                    <Grid container spacing={1}>
                        <Grid item style={{ width: '60px', zIndex: 5 }}>
                            {errorChildren != null && meta.error && meta.touched ? errorChildren : children}
                        </Grid>
                        <Grid item style={{ width: password ? 'calc(100% - 123px)' : 'calc(100% - 68px)' }}>
                            <TextField
                                name={input.name}
                                className={
                                    meta.error && meta.touched
                                        ? errorClasses.inputTextField
                                        : normalClasses.inputTextField
                                }
                                label={label}
                                InputProps={{
                                    className: commonClasses.input,
                                }}
                                InputLabelProps={{
                                    className: commonClasses.input,
                                }}
                                type={password && !showPassword ? 'password' : 'text'}
                                variant="outlined"
                                style={{ width: '100%' }}
                                fullWidth
                                required={required == null ? true : required}
                                autoComplete="off"
                            />
                        </Grid>
                        {password && (
                            <Grid item style={{ width: '60px' }}>
                                <div onClick={changePasswordVisibility}>
                                    {showPassword ? (
                                        <img alt="Eye" src={EyeIcon} className={commonClasses.inputIconRight} />
                                    ) : (
                                        <img alt="Eye" src={EyeOffIcon} className={commonClasses.inputIconRight} />
                                    )}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Field>
    )
}
