import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    circle: {
        overflow: 'hidden',
        position: 'fixed',
        right: '-75px',
        top: '-75px',
        borderRadius: '50%',
        width: '150px',
        height: '150px',
        opacity: 0,
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: '#37c0ca',
        animation: '$scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)',
    },
    '@keyframes scaleIn': {
        from: { transform: 'scale(.5, .5)', opacity: 0.6 },
        to: { transform: 'scale(2.5, 2.5)', opacity: 0 },
    },
}))

type PulsingHeaderProps = {
    mainWindowScroll: number
}
export function PulsingHeader({ mainWindowScroll }: PulsingHeaderProps) {
    const classes = useStyles()

    return (
        <div>
            <div
                className={classes.circle}
                style={{ borderColor: 'rgba(55,192,202,' + (250 - mainWindowScroll) / 250 + ')' }}
            ></div>
            <div
                className={classes.circle}
                style={{ animationDelay: '0s', borderColor: 'rgba(55,192,202,' + (250 - mainWindowScroll) / 250 + ')' }}
            ></div>
            <div
                className={classes.circle}
                style={{ animationDelay: '1s', borderColor: 'rgba(55,192,202,' + (250 - mainWindowScroll) / 250 + ')' }}
            ></div>
            <div
                className={classes.circle}
                style={{ animationDelay: '2s', borderColor: 'rgba(55,192,202,' + (250 - mainWindowScroll) / 250 + ')' }}
            ></div>
            <div
                className={classes.circle}
                style={{ animationDelay: '3s', borderColor: 'rgba(55,192,202,' + (250 - mainWindowScroll) / 250 + ')' }}
            ></div>
        </div>
    )
}
