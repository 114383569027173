import { useEffect, useCallback } from 'react'

export function setRequiredErrors(result: any, model: any, properties: Array<string>, requiredMessage: string) {
    properties.forEach(property => {
        if (!model[property]) {
            result[property] = requiredMessage
        }
    })
}

export function useWindowResizeElementUpdate(elementId: string, elementAction: Function) {
    const action = useCallback(
        (element: any) => {
            if (element) {
                elementAction(element)
            }
        },
        [elementAction]
    )

    useEffect(() => {
        const resizeHandler = () => {
            action(document.getElementById(elementId))
        }

        window.addEventListener('resize', resizeHandler)
        return () => window.removeEventListener('resize', resizeHandler)
    }, [elementId, action])

    return action
}

export const getFooterHeight = () => {
    const footer = document.getElementById('app-footer')
    const height = footer?.offsetHeight
    return height == null ? 58 : height
}

function calcHeightToBottom(element: any) {
    element.style.height = window.innerHeight * 1.333337 - 90 + 'px'
}
export function useCalcHeightToBottom(elementId: string) {
    return useWindowResizeElementUpdate(elementId, calcHeightToBottom)
}
