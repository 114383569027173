import React from "react";
import { useTranslation } from "react-i18next";
import { AsyncActionConfirmationDialog } from "./asyncActionConfirmationDialog";

type DeleteConfirmationDialogProps = {
    open: boolean;
    onConfirm: Function;
    onCancel: Function;
    inProgress: boolean;
    content?: string;
};

export function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
    const { t } = useTranslation();
    return (
        <AsyncActionConfirmationDialog
            open={props.open}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            inProgress={props.inProgress}
            content={props.content === null || props.content === undefined ? t("common.deleteContent") : props.content}
            title={t("common.deleteTitle")}
            confirmLabel={t("common.delete")}
            cancelLabel={t("common.cancel")}
        />
    );
}
