import { UPDATE_CHARGING_SESSION_CONSUMPTION, FETCH_CHARGING_SESSION } from './../const/index'

export const chargingSessionReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${UPDATE_CHARGING_SESSION_CONSUMPTION}`: {
            return {
                ...state,
                value: {
                    ...state.value,
                    consumedWh: action.payload.ConsumedWh,
                    importedWh: action.payload.ImportedWh,
                    offeredWh: action.payload.OfferedWh,
                },
            }
        }
        case `${FETCH_CHARGING_SESSION}`: {
            return {
                ...state,
                value: {
                    ...state.value,
                    ...action.payload
                },
            }
        }
        default: {
            return state
        }
    }
}
