import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Form } from 'react-final-form'
import { Link as RouterLink } from 'react-router-dom'
import { ValidationErrors } from 'final-form'
import { setRequiredErrors } from '../../utils/ui'
import { validateEmail, getErrorMessage } from '../../shared/utils/validation'
import { AsyncButton } from '../../shared/components/asyncButton'
import { promiseError, logout } from '../../shared/redux/actions/common'
import { usePromise } from '../../shared/utils/usePromise'
import { sendJson } from '../../shared/utils/api'
import { removeToken, getAppKey, config, getMainTenantId } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton } from '../../shared/components/backButton'
import { CustomTextField } from '../../shared/components/customInputRff'
import { PulsingHeader } from '../../shared/components/pulsingHeader'
import IdCard from '../../static/icons/icon-id-card-blue.svg'
import IdCardRed from '../../static/icons/icon-id-card-red.svg'
import EnvelopeIcon from '../../static/icons/icon-envelope-blue.svg'
import EnvelopeRedIcon from '../../static/icons/icon-envelope-red.svg'
import PasswordIcon from '../../static/icons/icon-password-blue.svg'
import PasswordRedIcon from '../../static/icons/icon-password-red.svg'
import { getDefaultLanguage } from '../../config'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
    },
    wrapperBottom: {
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    wrapperInner: {
        maxWidth: '400px',
        paddingBottom: '0px',
        padding: '23px',
        margin: 'auto',
        color: 'white',
    },

    logo: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    form: {
        width: '100%',
        zoom: '0.75',
    },
    formItem: {
        paddingBottom: '26px',
    },
    buttonWrapper: {
        paddingTop: theme.spacing(6),
    },
    titleContainer: {
        marginBottom: '57px',
        textAlign: 'center',
    },
    backButton: {
        backgroundColor: '#ffffff',
        color: theme.palette.secondary.dark,
        '&:hover': {
            color: '#000000',
            backgroundColor: theme.palette.primary.light,
        },
    },
    message: {
        paddingTop: theme.spacing(3),
    },
    input: {
        paddingLeft: theme.spacing(1),
    },
    registerInButtonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    registerButtonContainer: {
        marginBottom: '19px',
        marginLeft: '37px',
        marginRight: '37px',
        marginTop: '30px',
    },
    registerButton: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        borderRadius: '14px',
    },
    alreadyAcc: {
        marginTop: '19px',
        marginBottom: '30px',
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
    inputIconLeft: {
        color: '#37c0ca',
        marginBottom: '14px',
        marginTop: '20px',
        marginLeft: '8px',
        height: '17px',
    },
    terms: {
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
}))

const registerAction = (model: any) =>
    sendJson('Register', 'POST', model, {
        ApplicationKey: getAppKey(),
    })

const Register = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()

    const loginState = useSelector((state: any) => state.user.login.value)
    const tenant = useSelector((state: any) => state.tenant.value)

    const [registerStart, inProgress, result, error] = usePromise(registerAction)

    const handleLogout = () => {
        removeToken()
        dispatch(logout())
    }

    useEffect(() => {
        if (error) {
            dispatch(
                promiseError(
                    getErrorMessage(
                        error,
                        [['2', t('register.mailAreadyExistsError')]],
                        t('register.submitError', { error })
                    )
                )
            )
        }
    }, [dispatch, t, error])

    useEffect(() => {
        if (result) {
            if (loginState.isLoggedIn) {
                dispatch(logout())
            }
            history.push('/?registrationSuccess')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result, history])

    return (
        <>
            <Form
                validate={(model: any): ValidationErrors => {
                    const result: any = {}
                    let requiredFields = ['firstName', 'lastName', 'email', 'password', 'passwordAgain']
                    if (model.password && model.passwordAgain && model.password !== model.passwordAgain) {
                        result.passwordAgain = t('register.passwordsMustMatch')
                    }

                    setRequiredErrors(result, model, requiredFields, t('register.required'))
                    if (model.email && !validateEmail(model.email)) {
                        result.email = t('register.invalidEmail')
                    }
                    return result
                }}
                onSubmit={(model: any) => {
                    model.language = getDefaultLanguage()
                    model.tenants = getMainTenantId()
                    registerStart(model)
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form} autoComplete="off">
                        <div className={classes.wrapper}>
                            <Grid container alignItems="flex-start" className={classes.wrapperInner} spacing={0}>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    style={{ marginBottom: '37px' }}
                                >
                                    <Grid item style={{ justifySelf: 'start' }}>
                                        <BackButton
                                            buttonProps={{
                                                onClick: (event: any) => {
                                                    history.goBack()
                                                },
                                            }}
                                        ></BackButton>
                                    </Grid>
                                    <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                        <PulsingHeader mainWindowScroll={window.pageYOffset} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.titleContainer}>
                                    <Typography variant="h3" className={classes.title}>
                                        {t('register.registerTitle')}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        label={t('register.firstName')}
                                        itemName={'firstName'}
                                        password={false}
                                        children={<img alt="Card" src={IdCard} className={classes.inputIconLeft} />}
                                        errorChildren={
                                            <img alt="Card" src={IdCardRed} className={classes.inputIconLeft} />
                                        }
                                    ></CustomTextField>
                                </Grid>

                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        label={t('register.lastName')}
                                        itemName={'lastName'}
                                        password={false}
                                        children={<img alt="Card" src={IdCard} className={classes.inputIconLeft} />}
                                        errorChildren={
                                            <img alt="Card" src={IdCardRed} className={classes.inputIconLeft} />
                                        }
                                    ></CustomTextField>
                                </Grid>

                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        label={t('register.email')}
                                        itemName={'email'}
                                        password={false}
                                        children={
                                            <img alt="Envelope" src={EnvelopeIcon} className={classes.inputIconLeft} />
                                        }
                                        errorChildren={
                                            <img
                                                alt="Envelope"
                                                src={EnvelopeRedIcon}
                                                className={classes.inputIconLeft}
                                            />
                                        }
                                    ></CustomTextField>
                                </Grid>

                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        label={t('register.password')}
                                        itemName={'password'}
                                        password={true}
                                        children={
                                            <img alt="Lock" src={PasswordIcon} className={classes.inputIconLeft} />
                                        }
                                        errorChildren={
                                            <img alt="Lock" src={PasswordRedIcon} className={classes.inputIconLeft} />
                                        }
                                    ></CustomTextField>
                                </Grid>

                                <Grid item xs={12} className={classes.formItem}>
                                    <CustomTextField
                                        label={t('register.passwordAgain')}
                                        password={true}
                                        itemName={'passwordAgain'}
                                        children={
                                            <img alt="Lock" src={PasswordIcon} className={classes.inputIconLeft} />
                                        }
                                        errorChildren={
                                            <img alt="Lock" src={PasswordRedIcon} className={classes.inputIconLeft} />
                                        }
                                    ></CustomTextField>
                                </Grid>

                                <Grid item xs={12} className={classes.terms}>
                                    {t('register.agreeOnRegister')}
                                    <a
                                        href={`${config.proxy ?? ''}${config.api}Settings/TermsAndConditions`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t('common.terms')}
                                    </a>
                                    {t('common.and')}
                                    <a
                                        href={`${config.proxy ?? ''}${config.api}Settings/Gdpr`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t('common.gdpr')}
                                    </a>
                                    .
                                    {tenant?.aboutPage ? (
                                        <p>
                                            <a href={tenant.aboutPage} target="_blank" rel="noopener noreferrer">
                                                About us
                                            </a>
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.wrapperBottom}>
                            <Grid container alignItems="flex-start" className={classes.wrapperInner} spacing={0}>
                                <Grid item xs={12} className={classes.registerButtonContainer}>
                                    <Grid container direction="row" justify="space-between">
                                        <Grid item xs={12}>
                                            <AsyncButton
                                                buttonProps={{
                                                    className: classes.registerButton,
                                                    variant: 'contained',
                                                    color: 'primary',
                                                    type: 'submit',
                                                    fullWidth: true,
                                                }}
                                                inProgress={inProgress}
                                            >
                                                <p className={classes.registerInButtonText}>{t('login.register')}</p>
                                            </AsyncButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.alreadyAcc}>
                                    {t('login.alreadyAcc')}
                                    <RouterLink to="/login" onClick={handleLogout}>
                                        {' '}
                                        {t('login.signIn')}
                                    </RouterLink>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                )}
            />
        </>
    )
}

export default Register
