import { ChargingSession, ChargingSessionState } from './../types/location'

export function consumedKwh(session: ChargingSession): string {
    // in progress session use kwh in meterValues, for all other states we need to format values
    if (session.state && session.state === ChargingSessionState.InProgress) {
        return session.consumedWh ? (session.consumedWh).toFixed(2) : '0.00'
    }  else {
        return session.consumedWh ? (session.consumedWh / 1000).toFixed(2) : '0.00'
    }
}
export function importedKwh(session: ChargingSession): string {
    return session.importedWh ? (session.importedWh).toFixed(1) : '0.0'
}
export function offeredKwh(session: ChargingSession): string {
    return session.offeredWh ? (session.offeredWh).toFixed(1) : '0.0'
}
