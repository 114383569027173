import React from 'react'
import {
    makeStyles,
    ButtonBase,
    CardMedia,
} from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { config } from '../../config'

const useStyles = makeStyles((theme) => ({
    noImageWrapper: {
        backgroundColor: theme.palette.secondary.light,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

type ImageProps = {
    url?: string
    className?: string
    imageClassName?: string
    onClick?: Function
    radius?: string
}
export function Image({ url, className, imageClassName, onClick, radius = '5%' }: ImageProps) {
    const classes = useStyles()
    
    return (
        <ButtonBase
            disableRipple
            disabled={!Boolean(onClick)}
            onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
            className={className}
        >
            {url ? (
                <CardMedia image={`${config.proxy ? config.proxy + config.api : config.api}${url}`} className={imageClassName} style={{ borderRadius: radius }} />
            ) : (
                <div className={`${classes.noImageWrapper} ${imageClassName}`} style={{ borderRadius: radius }}>
                    <PhotoCameraIcon />
                </div>
            )}
        </ButtonBase>
    )
}