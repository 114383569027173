import { UPDATE_LOCATION_INFO } from './../const/index'

export const locationLisReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${UPDATE_LOCATION_INFO}`: {
            if (state.value.length === 0) {
                return state
            }
            return {
               ...state,
               value: {
                   ...state.value,

                   items: state.value.items.map((value: any) => 
                   value.id === action.payload.locationInfo.Id ? { ...value,
                       chargerCount: action.payload.locationInfo.ChargerCount,
                       chargersInUseCount: action.payload.locationInfo.ChargersInUseCount,
                       availableConnectors: action.payload.locationInfo.AvailableConnectors,
                       workingConnectors: action.payload.locationInfo.WorkingConnectors,
                       connectedChargers: action.payload.locationInfo.ConnectedChargers,
                       connectorCount: action.payload.locationInfo.ConnectorCount,
                    } : value
                   )
               },
               data: state.value.items.map((value: any) => 
               value.id === action.payload.locationInfo.Id ? { ...value,
                   chargerCount: action.payload.locationInfo.ChargerCount,
                   chargersInUseCount: action.payload.locationInfo.ChargersInUseCount,
                   availableConnectors: action.payload.locationInfo.AvailableConnectors,
                   workingConnectors: action.payload.locationInfo.WorkingConnectors,
                   connectedChargers: action.payload.locationInfo.ConnectedChargers,
                   connectorCount: action.payload.locationInfo.ConnectorCount,
                } : value
               ) 
               
            };
        }
        default: {
            return state
        }
    }
}