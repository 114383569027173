import React, { useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { ChargingSessionState } from '../../shared/types/location'
import { useSelector, useDispatch } from 'react-redux'
import { consumedKwh } from '../../shared/utils/display'
import { AsyncButton } from '../../shared/components/asyncButton'
import { useTranslation } from 'react-i18next'
import { AsyncActionConfirmationDialog } from '../../shared/components/asyncActionConfirmationDialogStyled'
import { stopCharging, setChargingSession } from '../../redux/actions/charging'
import PowerIcon from '../../static/icons/icon-outline-power-white.svg'
import { useSessionPeriodicUpdate } from '../../utils/currentSession'

const useStyles = makeStyles((theme) => ({
    bottomPannelContainer: {
        background: 'transparent',
        bottom: '-1px',
        width: '100%',
        maxHeight: '90px',
        left: '0',
        right: '0',
        margin: 'auto',
        zIndex: 10,
        zoom: '0.75',
    },
    wrapper: {
        color: 'white',
        background: 'transparent',
    },
    wrapperInner: {
        backgroundColor: 'black',
        height: '90px',
        position: 'relative',
        borderBottom: '0px',

        borderRadius: '20px 20px 0px 0px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource: 'linear-gradient(to right, #000507, #37c0ca 36%, #42c7d0 68%, #000507)',
        borderImageSlice: '1',
        backgroundImage:
            'linear-gradient(to bottom, #000507, #000507), linear-gradient(to right, #000507, #37c0ca 36%, #42c7d0 68%, #000507)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
    },
    middleItem: {
        backgroundColor: '#00000000 !important',
        marginBottom: '15px',
    },
    sideItems: {
        width: '40%',
    },
    menuItemSvg: {
        marginTop: '35px',
        maxWidth: '23px',
        maxHeight: '23px',
    },
    menuItemSvgMid: {
        marginTop: '19px',
        maxWidth: '23px',
        maxHeight: '23px',
    },
    menuItem: { height: '100%' },
    menuItemMid: {
        marginTop: '21px',
    },
    menuItemActive: {
        marginTop: '35px',
        color: '#37c0ca',
    },
    middleCircle: {
        background: 'black',
        width: '70px',
        height: '70px',
        left: 'calc(50% - 35px)',

        position: 'absolute',
        top: '-20px',
        border: 'double 1px transparent',
        backgroundImage: 'linear-gradient(black, black), linear-gradient(to bottom, #b7faff, #37c0ca 16%, #000507)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
        backgroundColor: '#000507',
        borderRadius: '50%',
    },
    chargingInfoContainer: {
        marginTop: '10px',
    },
    chargingInfoData: {
        fontFamily: "'Play', sans-serif",
        fontSize: '22px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#73d9e0',
    },
    chargingInfoDesc: {
        fontFamily: "'Play', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#37c0ca',
    },
}))

const useRedStyles = makeStyles((theme) => ({
    navigateButton: {
        width: '68px',
        height: '68px',
        borderRadius: '60px',
        background: 'linear-gradient(0deg, #000000, #ff0000)',
        backgroundPosition: '30% 25%',
        backgroundSize: '200px 200px',
        WebkitAnimation: '$AnimationName 3s ease infinite',
        MozAnimation: '$AnimationName 3s ease infinite',
        OAnimation: '$AnimationName 3s ease infinite',
        animation: '$AnimationName 3s ease infinite',
    },

    stopChargingContainer: {
        marginTop: '7px',
    },
    '@-webkit-keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 68px' },
        '50%': { backgroundPosition: '0% 130px' },
        '100%': { backgroundPosition: '0px 68px' },
    },
    '@-moz-keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 68px' },
        '50%': { backgroundPosition: '0% 130px' },
        '100%': { backgroundPosition: '0px 68px' },
    },
    '@-o-keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 68px' },
        '50%': { backgroundPosition: '0% 130px' },
        '100%': { backgroundPosition: '0px 68px' },
    },
    '@keyframes AnimationName': {
        '0%': { backgroundPosition: '0px 68px' },
        '50%': { backgroundPosition: '0% 130px' },
        '100%': { backgroundPosition: '0px 68px' },
    },
    navigateIcon: {},
    middleCircle: {
        width: '70px',
        height: '70px',
        left: 'calc(50% - 35px)',
        backgroundOrigin: 'border-box',
        position: 'absolute',
        top: '-20px',
        border: 'double 1px transparent',
        backgroundImage: 'linear-gradient(black, black), linear-gradient(to bottom, #df394b, #ff0000 16%, #000507)',

        backgroundClip: 'content-box, border-box',
        backgroundColor: '#cc606d',
        borderRadius: '50%',
        background: 'linear-gradient(0deg, #000000,  #cc606d)',
    },
    wrapperInner: {
        backgroundColor: 'black',
        height: '90px',
        position: 'relative',
        borderBottom: '0px',

        borderRadius: '20px 20px 0px 0px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource: 'linear-gradient(to right, #000507, #de384a 36%, #f77a88 68%, #000507)',
        borderImageSlice: '1',
        backgroundImage:
            'linear-gradient(to bottom, #000507, #000507), linear-gradient(to right, #000507, #de384a 36%, #f67a88 68%, #000507)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
    },
}))

type BottomControlPanelProps = {
    actualPage: string
    positionAbsolute: boolean
}
export const BottomControlPanel: React.FC<BottomControlPanelProps> = ({ actualPage, positionAbsolute }) => {

    const classes = useStyles()
    const classesRed = useRedStyles()

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const [stopChargingConfirmIsOpen, setStopChargingConfirmIsOpen] = useState<boolean>(false)
    const stopChargingState = useSelector((state: any) => state.stopCharging)

    const inProgress = !stopChargingState.executed

    const chargingSession = useSelector((state: any) => state.chargingSession.value)
    const locationId = chargingSession?.location?.id ? chargingSession.location.id : chargingSession?.locationId

    const clearChargingSession = () => {
        if (actualPage === 'chargingDone') {
            dispatch(setChargingSession(null))
        }
    }

    useSessionPeriodicUpdate(chargingSession)
    
    const mapPath = () => {
        return (
            <>
                <path
                    width="100%"
                    id="5trpy4okoa"
                    d="M12 2c5.523 0 10 4.477 10 10 0 1.549-.358 3.047-1.016 4.392-.015.035-.031.069-.05.101-.863 1.715-2.215 3.175-3.934 4.167-.515.297-1.05.545-1.597.743-.044.02-.09.036-.136.049-1.402.484-2.886.648-4.344.49l.077.003c-.052 0-.103-.004-.153-.012C9.513 21.78 8.202 21.354 7 20.66 3.906 18.874 2 15.573 2 12c0-3.92 2.255-7.312 5.538-8.952l.036-.018C8.909 2.37 10.41 2 12 2zM7.009 5.747l-.064.052C5.644 6.861 4.683 8.324 4.252 10L5 10c1.598 0 2.904 1.249 2.995 2.824L8 13v1c0 .552.448 1 1 1 1.598 0 2.904 1.249 2.995 2.824L12 18v2c.673 0 1.345-.085 2-.254V18c0-1.598 1.249-2.904 2.824-2.995L17 15h2.417c.38-.939.583-1.955.583-3 0-1.061-.207-2.074-.582-3.001L19 9c-.513 0-.936.386-.993.883L18 10c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.513-.386-.936-.883-.993L11 9h-.5C8.65 9 7.136 7.565 7.009 5.747zM4 12c0 2.858 1.525 5.5 4 6.928.635.367 1.308.64 2 .818V18c0-.513-.386-.936-.883-.993L9 17c-1.598 0-2.904-1.249-2.995-2.824L6 14v-1c0-.513-.386-.936-.883-.993L5 12zm14.245 5H17c-.513 0-.936.386-.993.883L16 18v.928c.876-.505 1.633-1.163 2.245-1.928zM12 4c-1.061 0-2.074.207-3.001.582L9 5.5c0 .78.595 1.42 1.356 1.493L10.5 7h.5c1.598 0 2.904 1.249 2.995 2.824L14 10c0 .552.448 1 1 1s1-.448 1-1c0-1.419.985-2.607 2.308-2.92C16.845 5.205 14.563 4 12 4z"
                />
            </>
        )
    }

    const listPath = () => {
        return (
            <>
                <path
                    id="c8owsqo9qa"
                    d="M8 13c1.657 0 3 1.343 3 3v2c0 1.657-1.343 3-3 3H6c-1.657 0-3-1.343-3-3v-2c0-1.657 1.343-3 3-3zm10 0c1.657 0 3 1.343 3 3v2c0 1.657-1.343 3-3 3h-2c-1.657 0-3-1.343-3-3v-2c0-1.657 1.343-3 3-3zM8 15H6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1h2c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1zm10 0h-2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1h2c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1zM8 3c1.657 0 3 1.343 3 3v2c0 1.657-1.343 3-3 3H6c-1.657 0-3-1.343-3-3V6c0-1.657 1.343-3 3-3zm10 0c1.657 0 3 1.343 3 3v2c0 1.657-1.343 3-3 3h-2c-1.657 0-3-1.343-3-3V6c0-1.657 1.343-3 3-3zM8 5H6c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1h2c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1zm10 0h-2c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1h2c.552 0 1-.448 1-1V6c0-.552-.448-1-1-1z"
                />
            </>
        )
    }

    const briefPath = () => {
        return (
            <>
                <path
                    id="r96yd6ahqa"
                    d="M13 2c1.306 0 2.418.835 2.83 2H17c1.598 0 2.904 1.249 2.995 2.824L20 7v12c0 1.598-1.249 2.904-2.824 2.995L17 22H7c-1.598 0-2.904-1.249-2.995-2.824L4 19V7c0-1.598 1.249-2.904 2.824-2.995L7 4h1.17c.394-1.112 1.425-1.924 2.654-1.995L11 2h2zM8.171 6H7c-.513 0-.936.386-.993.883L6 7v12c0 .513.386.936.883.993L7 20h10c.513 0 .936-.386.993-.883L18 19V7c0-.513-.386-.936-.883-.993L17 6h-1.171c-.393 1.113-1.424 1.924-2.653 1.995L13 8h-2c-1.306 0-2.417-.834-2.829-2zM15 15c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L15 17H9c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L9 15h6zm0-4c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L15 13H9c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L9 11h6zm-2-7h-2c-.513 0-.936.386-.993.883L10 4.998V5c0 .513.386.936.883.993L11 6h2c.513 0 .936-.386.993-.883L14 5v-.002l-.007-.115c-.053-.459-.417-.823-.876-.876L13 4z"
                />
            </>
        )
    }

    const profilePath = () => {
        return (
            <>
                <path
                    id="dhmykcum9a"
                    d="M12 2c5.523 0 10 4.477 10 10 0 3.573-1.906 6.874-5 8.66-3.094 1.787-6.906 1.787-10 0-3.094-1.786-5-5.087-5-8.66C2 6.477 6.477 2 12 2zm-.002 15c-1.792-.003-3.561.367-5.195 1.08.368.316.768.6 1.197.848 2.475 1.43 5.525 1.43 8 0 .429-.247.829-.531 1.197-.846-1.62-.71-3.381-1.082-5.199-1.082zM12 4c-4.418 0-8 3.582-8 8 0 1.644.504 3.215 1.403 4.526C7.454 15.52 9.711 14.996 12 15c2.32 0 4.564.528 6.596 1.525C19.496 15.215 20 13.643 20 12c0-4.418-3.582-8-8-8zm0 2c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
                />
            </>
        )
    }

    const plugPath = () => {
        return (
            <>
                <path
                    id="vhzz2m9mla"
                    d="M16.8 4.28c0-.707-.573-1.28-1.28-1.28-.707 0-1.28.573-1.28 1.28v3.84h2.56V4.28zm1.92 3.12H4.64c-.353 0-.64.287-.64.64v1.28c0 .353.287.64.64.64h.64v1.28c0 3.041 2.14 5.663 5.12 6.272v3.968h2.56v-3.968c2.98-.61 5.12-3.23 5.12-6.272V9.96h.64c.353 0 .64-.287.64-.64V8.04c0-.353-.287-.64-.64-.64zm-9.6-3.12C9.12 3.573 8.547 3 7.84 3c-.707 0-1.28.573-1.28 1.28v3.84h2.56V4.28z"
                />
            </>
        )
    }

    return (
        <>
            <Grid
                item
                xs={12}
                className={classes.bottomPannelContainer}
                style={{ position: positionAbsolute ? 'absolute' : 'relative' }}
            >
                <Grid item xs={12} container justify="center" className={classes.wrapper}>
                    <Grid
                        item
                        xs={12}
                        container
                        justify="center"
                        className={actualPage === 'charging' ? classesRed.wrapperInner : classes.wrapperInner}
                    >
                        <Grid item container justify="center" className={classes.sideItems}>
                            <Grid item xs={6}>
                                <RouterLink to="/main-map" onClick={clearChargingSession}>
                                    <div className={classes.menuItem}>
                                        <svg
                                            fill={actualPage === 'MainMap' ? '#37c0ca' : 'white'}
                                            className={classes.menuItemSvg}
                                        >
                                            {mapPath()}
                                        </svg>
                                    </div>
                                </RouterLink>
                            </Grid>
                            <Grid item xs={6}>
                                <RouterLink to="/main-list" onClick={clearChargingSession}>
                                    <div className={classes.menuItem}>
                                        <svg
                                            fill={actualPage === 'MainList' ? '#37c0ca' : 'white'}
                                            className={classes.menuItemSvg}
                                        >
                                            {listPath()}
                                        </svg>
                                    </div>
                                </RouterLink>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.middleItem} style={{ backgroundColor: 'black', width: '20%' }}>
                            <div className={actualPage === 'charging' ? classesRed.middleCircle : classes.middleCircle}>
                                {actualPage === 'charging' ? (
                                    <AsyncButton
                                        buttonProps={{
                                            className: classesRed.navigateButton,
                                            onClick: (event: any) => {
                                                event.preventDefault()
                                                setStopChargingConfirmIsOpen(true)
                                            },
                                        }}
                                        inProgress={inProgress}
                                    >
                                        <div className={classesRed.stopChargingContainer}>
                                            <img alt="stop" src={PowerIcon} className={classesRed.navigateIcon} />
                                        </div>
                                    </AsyncButton>
                                ) : chargingSession != null &&
                                  (chargingSession.state === ChargingSessionState.InProgress ||
                                      chargingSession.state === ChargingSessionState.Parking) ? (
                                    <RouterLink to={`/charging/${locationId}`}>
                                        <div className={classes.chargingInfoContainer}>
                                            <div
                                                className={classes.chargingInfoData}
                                                style={{ fontSize: 20 - consumedKwh(chargingSession).length + 'px' }}
                                            >
                                                {consumedKwh(chargingSession)}
                                            </div>
                                            <div className={classes.chargingInfoDesc}>kWh</div>
                                        </div>
                                    </RouterLink>
                                ) : (
                                    <svg
                                        fill={actualPage === 'charging' ? '#37c0ca' : 'white'}
                                        className={classes.menuItemSvgMid}
                                    >
                                        {plugPath()}
                                    </svg>
                                )}
                            </div>
                        </Grid>
                        <Grid item container justify="center" className={classes.sideItems}>
                            <Grid item xs={6}>
                                <RouterLink to="/charging-history" onClick={clearChargingSession}>
                                    <div className={classes.menuItem}>
                                        <svg
                                            fill={actualPage === 'History' ? '#37c0ca' : 'white'}
                                            className={classes.menuItemSvg}
                                        >
                                            {briefPath()}
                                        </svg>
                                    </div>
                                </RouterLink>
                            </Grid>
                            <Grid item xs={6}>
                                <RouterLink to="/settings" onClick={clearChargingSession}>
                                    <div className={classes.menuItem}>
                                        <svg
                                            fill={actualPage === 'Settings' ? '#37c0ca' : 'white'}
                                            className={classes.menuItemSvg}
                                        >
                                            {profilePath()}
                                        </svg>
                                    </div>
                                </RouterLink>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between">
                <AsyncActionConfirmationDialog
                    open={stopChargingConfirmIsOpen}
                    inProgress={false}
                    cancelLabel={t('common.close')}
                    confirmLabel={t('common.confirm')}
                    title={t('common.confirmation')}
                    content={t('charging.stopChargingConfirmationMessage')}
                    onConfirm={async () => {
                        setStopChargingConfirmIsOpen(false)
                        const result = await dispatch(stopCharging(chargingSession.id))
                        if (result) {
                            dispatch(setChargingSession(result))
                        }
                    }}
                    onCancel={() => setStopChargingConfirmIsOpen(false)}
                ></AsyncActionConfirmationDialog>
            </Grid>
        </>
    )
}
