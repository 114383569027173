import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment, { Moment } from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, CircularProgress, AppBar, Toolbar, useScrollTrigger } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ChargingSession } from '../../shared/types/location'
import { fetchChargingHistory, setChargingHistoryFilter } from '../../redux/actions/charging'
import { NoData } from '../../shared/components/NoData'
import { SessionCard } from './SessionCard'
import { BottomControlPanel } from '../../core/footer/bottomControlPanel'
import { PulsingHeader } from '../../shared/components/pulsingHeader'
import { MultiPicker } from '../../utils/OptionPicker'
import { RegisteredUsersOnly } from '../../core/anonymusUser/registeredUsersOnly'

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: 'black',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        height: '100%',
    },
    header: {
        fontSize: '1rem',
        alignItems: 'center',
    },
    dateRow: {
        fontSize: '0.8rem',
        textAlign: 'left',
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    selects: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '15px',
    },
    sessionList: {
        padding: theme.spacing(1, 2),
        overflow: 'auto',
        flexWrap: 'nowrap',
        zIndex: 10,
        marginBottom: '10px',
        zoom: '0.75',
    },
    sessionDayTitle: {
        marginLeft: theme.spacing(1),
        fontFamily: "'Muli', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    sessionDayTitleDivider: {
        marginTop: theme.spacing(3),
        fontFamily: "'Muli', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    dataInfo: {
        marginTop: theme.spacing(2),
    },
    resgisteredUsersContent: {
        marginTop: theme.spacing(2),
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline',
            },
        },
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    titleContainer: {
        maxHeight: '32px',
        marginBottom: '34px',
        textAlign: 'center',
    },
    loadMoreButtonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    loadMoreButtonContainer: {
        marginBottom: '19px',
        marginTop: '30px',
        width: '100%',
    },
    loadMoreButton: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        width: '100%',
        maxWidth: '280px',
        borderRadius: '14px',
    },
    bottomDecoration: {
        bottom: '0px',
        left: '0px',
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '261px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
}))

export default function ChargingHistory() {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const isAnonymousUser = useSelector((state: any) => state.user.login.value.isAnonymous)
    const chargingHistory = useSelector((state: any) => state.chargingHistory)
    const chargingHistoryFilterId = useSelector((state: any) => state.chargingHistoryFilter?.value)

    const [scrollRef, setScrollRef] = useState()

    const handleChange = (event: any) => {
        dispatch(setChargingHistoryFilter(event.target.value))
    }

    const sessionsWithDate: any[] = []
    let lastStartedAtDay: Moment
    chargingHistory.data.forEach((x: ChargingSession) => {
        const currentStartedAtDay = moment(x.startedAt).startOf('day')
        if (!currentStartedAtDay.isSame(lastStartedAtDay)) {
            sessionsWithDate.push({ date: currentStartedAtDay.format('LL'), value: null })
            lastStartedAtDay = currentStartedAtDay
        }

        sessionsWithDate.push({ date: null, value: x })
    })

    const filterIdToDates = useCallback((filterId) => {
        switch (filterId) {
            case 0: {
                return { from: moment().startOf('day').subtract(7, 'days').toDate(), to: undefined }
            }
            case 1: {
                return { from: moment().startOf('month').toDate(), to: undefined }
            }
            case 2: {
                return {
                    from: moment().startOf('month').subtract(1, 'month').toDate(),
                    to: moment().startOf('month').subtract(1, 'month').endOf('month').toDate(),
                }
            }
            case 3: {
                return { from: moment().subtract(3, 'months').startOf('month').toDate(), to: undefined }
            }
            default: {
                throw new Error('Not supported filter type')
            }
        }
    }, [])

    useEffect(() => {
        if (chargingHistory.executed && chargingHistory.key !== chargingHistoryFilterId) {
            dispatch(
                fetchChargingHistory(chargingHistoryFilterId, chargingHistory, filterIdToDates(chargingHistoryFilterId))
            )
        }
    }, [dispatch, chargingHistory, chargingHistoryFilterId, filterIdToDates])

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: scrollRef,
    })

    return (
        <>
            <div style={{ background: 'black', height: '100%' }}>
                {!isAnonymousUser ? (
                    <Grid className={classes.main} container direction="column">
                        <Grid container className={classes.header} direction="row">
                            <AppBar color="transparent" position="relative" elevation={trigger ? 4 : 0}>
                                <div style={{ zoom: '0.75' }}>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        style={{ marginBottom: '37px', marginTop: '23px', marginLeft: '23px' }}
                                    >
                                        <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                            <PulsingHeader mainWindowScroll={window.pageYOffset} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.titleContainer}>
                                        <Typography className={classes.title} color="inherit">
                                            {t('menu.chargingHistory')}
                                        </Typography>
                                    </Grid>
                                </div>

                                <Toolbar className={classes.selects}>
                                    <Grid item>
                                        <MultiPicker
                                            active={false}
                                            elevate={!trigger}
                                            label={t('charging.last7Days')}
                                            value={chargingHistoryFilterId}
                                            items={[
                                                { id: 0, label: t('charging.last7Days') },
                                                { id: 1, label: t('charging.currentMonth') },
                                                { id: 2, label: t('charging.lastMonth') },
                                                { id: 3, label: t('charging.last3Months') },
                                            ]}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                        </Grid>

                        <Grid container direction="column" className={classes.sessionList} ref={setScrollRef}>
                            <Grid item xs={12}>
                                {sessionsWithDate.map((sessionWithDate: any, index) =>
                                    sessionWithDate.date ? (
                                        <Typography
                                            variant="subtitle2"
                                            key={sessionWithDate.date}
                                            className={`${classes.sessionDayTitle} ${
                                                index > 0 ? classes.sessionDayTitleDivider : ''
                                            }`}
                                        >
                                            {sessionWithDate.date}
                                        </Typography>
                                    ) : (
                                        <SessionCard key={sessionWithDate.value.id} session={sessionWithDate.value} />
                                    )
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.loadMoreButtonContainer}>
                                {chargingHistory.hasMorePages && chargingHistory.executed && (
                                    <Button
                                        className={classes.loadMoreButton}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            dispatch(
                                                fetchChargingHistory(
                                                    chargingHistoryFilterId,
                                                    chargingHistory,
                                                    filterIdToDates(chargingHistoryFilterId)
                                                )
                                            )
                                        }}
                                    >
                                        <p className={classes.loadMoreButtonText}>{t('common.loadMore')}</p>
                                    </Button>
                                )}
                                {!chargingHistory.executed && (
                                    <div className={classes.dataInfo}>{<CircularProgress size={28} />}</div>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {chargingHistory.totalCount === 0 && (
                                    <div className={classes.dataInfo}>
                                        <NoData text={t('charging.noChargings')} />
                                    </div>
                                )}
                                <div style={{ height: '120px' }}></div>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <RegisteredUsersOnly title={t('menu.chargingHistory')} />
                )}
                <div className={classes.bottomDecoration}></div>
                <BottomControlPanel positionAbsolute={true} actualPage="History" />
            </div>
        </>
    )
}
