import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Form } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import { setRequiredErrors } from '../../utils/ui'
import { sendJson } from '../../shared/utils/api'
import { usePromise } from '../../shared/utils/usePromise'
import { AsyncButton } from '../../shared/components/asyncButton'
import { promiseError } from '../../shared/redux/actions/common'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CustomTextField } from '../../shared/components/customInputRff'
import PasswordIcon from '../../static/icons/icon-password-blue.svg'
import { PulsingHeader } from '../../shared/components/pulsingHeader'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        zoom: '0.75',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'black',
    },
    wrapperInner: {
        maxWidth: '400px',
        padding: theme.spacing(4),
    },
    form: {
        width: '100%',
    },
    formItem: {
        paddingBottom: theme.spacing(2),
    },
    logo: {
        paddingBottom: theme.spacing(6),
    },
    buttonWrapper: {
        paddingTop: theme.spacing(6),
    },
    titleContainer: {
        marginBottom: '25px',
        textAlign: 'center',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    message: {
        paddingTop: theme.spacing(3),
    },
    inputIconLeft: {
        color: '#37c0ca',
        marginBottom: '14px',
        marginTop: '20px',
        marginLeft: '8px',
        height: '17px',
    },
    buttonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    buttonContainer: {
        marginBottom: '19px',
        marginLeft: '37px',
        marginRight: '37px',
        marginTop: '30px',
    },
    button: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        borderRadius: '14px',
        width: '100%',
        maxWidth: '280px',
    },
    bottomDecoration: {
        bottom: '0px',
        left: '0px',
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '261px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
}))

const resetPasswordAction = (model: any, token: string) =>
    sendJson('ChangePassword', 'POST', { password: model.password, token: token })

const ResetPassword = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()

    const dispatch = useDispatch()

    const resetRouteMatch = useRouteMatch(`/reset-password/:token`)

    const routeParams: any = resetRouteMatch?.params
    const token: string = routeParams?.token

    const [resetPasswordStart, inProgress, result, error] = usePromise(resetPasswordAction)

    useEffect(() => {
        if (error) {
            dispatch(promiseError(t('login.resetPasswordError', { error })))
        }
    }, [dispatch, t, error])
    useEffect(() => {
        if (result) {
            history.push('/?resetPasswordSuccess')
        }
    }, [result, history])

    return (
        <div className={classes.wrapper}>
            <Grid container alignItems="flex-start" className={classes.wrapperInner} spacing={0}>
                <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                    <PulsingHeader mainWindowScroll={window.pageYOffset} />
                </Grid>
                <Grid item xs={12} className={classes.titleContainer}>
                    <p className={classes.title}>{t('login.resetPassword')}</p>
                </Grid>
                <Form
                    validate={(model: any): ValidationErrors => {
                        const result: any = {}
                        let requiredFields = ['password', 'passwordAgain']
                        if (model.password && model.passwordAgain && model.password !== model.passwordAgain) {
                            result.passwordAgain = t('register.passwordsMustMatch')
                        }

                        setRequiredErrors(result, model, requiredFields, t('register.required'))
                        return result
                    }}
                    onSubmit={(model: any) => {
                        resetPasswordStart(model, token)
                    }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate className={classes.form} style={{ zIndex: 10 }}>
                            <Grid item xs={12} className={classes.formItem}>
                                <CustomTextField
                                    label={t('login.newPassword')}
                                    itemName={'password'}
                                    password={true}
                                    children={<img alt="lock" src={PasswordIcon} className={classes.inputIconLeft} />}
                                ></CustomTextField>
                            </Grid>
                            <Grid item xs={12} className={classes.formItem}>
                                <CustomTextField
                                    label={t('login.newPasswordAgain')}
                                    password={true}
                                    itemName={'passwordAgain'}
                                    children={<img alt="lock" src={PasswordIcon} className={classes.inputIconLeft} />}
                                ></CustomTextField>
                            </Grid>

                            <Grid item xs={12} className={classes.buttonWrapper}>
                                <AsyncButton
                                    buttonProps={{
                                        className: classes.button,
                                        variant: 'contained',
                                        color: 'primary',
                                        type: 'submit',
                                        fullWidth: true,
                                    }}
                                    inProgress={inProgress}
                                >
                                    <p className={classes.buttonText}>{t('common.save')}</p>
                                </AsyncButton>
                            </Grid>
                        </form>
                    )}
                />
                <div className={classes.bottomDecoration} />
            </Grid>
        </div>
    )
}

export default ResetPassword
