import React from 'react'
import { useSelector } from 'react-redux'
import { LinearProgress } from '@material-ui/core'
import { NoProgress } from './noProgress'

type DialogProgressProps = {
    stateSelector(state: any): any
}
export function DialogProgress({ stateSelector }: DialogProgressProps) {
    const state = useSelector(stateSelector)

    return !state.executed ? <LinearProgress /> : <NoProgress />
}
