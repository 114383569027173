import React from 'react'
import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

const useStyles = makeStyles((theme) => ({
    arrowBackIcon: {
        color: 'white',
        width: '16px',
        marginLeft: '5px',
    },
    backButton: {
        width: '48px',
        minWidth: '48px',
        height: '60px',
        borderRadius: '14px',
        backgroundImage: 'linear-gradient(141deg, #3a4245, #1b1f21 100%)',
    },
}))

type BackButtonProps = {
    buttonProps?: ButtonProps
}
export function BackButton({ buttonProps }: BackButtonProps) {
    const classes = useStyles()

    return (
        <Button {...buttonProps} variant="contained" fullWidth={true} type="submit" className={classes.backButton}>
            <ArrowBackIos className={classes.arrowBackIcon} />
        </Button>
    )
}
