import { Coordinates } from './../../shared/types/common'
import { ChargerConnectorSocketType } from './../../shared/types/charger'
import {
    CHANGE_SEARCH_FILTER,
    CHANGE_AVAILABILITY_FILTER,
    CHANGE_DISTANCE_FILTER,
    CHANGE_CONNECTOR_FILTER,
    CHANGE_SORT_FILTER
} from './../const/index'
import { valueHolderActionCreator } from './../../shared/redux/actions/common'

export const setSearch = (search: string) => {
    return valueHolderActionCreator(CHANGE_SEARCH_FILTER, search)
}

export const setAvailability = (onlyAvailable: boolean) => {
    return valueHolderActionCreator(CHANGE_AVAILABILITY_FILTER, onlyAvailable)
}

export const setSortByDistance = (sortByDistance: boolean) => {
    return valueHolderActionCreator(CHANGE_SORT_FILTER, sortByDistance)
}

export const setDistance = (distance: { km?: number; location?: Coordinates }) => {
    return valueHolderActionCreator(CHANGE_DISTANCE_FILTER, distance)
}

export const setConnector = (connector?: ChargerConnectorSocketType) => {
    return valueHolderActionCreator(CHANGE_CONNECTOR_FILTER, connector)
}
