import React, { useState, useEffect } from 'react'
import { makeStyles, MenuItem, Input, Select, FormControl } from '@material-ui/core'
import i18n from '../../src/shared/i18n/i18n'
import { setDefaultLanguage, getDefaultLanguage } from '../config'
import RadioButtonChecked from '../../src/static/icons/radio_button_checked_blue.svg'
import RadioButtonUnchecked from '../../src/static/icons/radio_button_unchecked_blue.svg'

const useStyles = makeStyles((theme) => ({
    select: {
        color: 'white',
        outline: 'none',
        marginLeft: theme.spacing(2),
        border: '0px solid #ffffff',
        borderRadius: '10px',
        fontSize: '12px',
        boxShadow: 'none',
        '&:before': {
            border: '0 !important',
        },
        '&:after': {
            border: '0 !important',
        },
        background: 'white',
        height: '45px',
        width: '36px',
        backgroundImage: 'linear-gradient(144deg, #3a4245, #1b1f21 96%)',
    },
    selectInner: {
        position: 'fixed',
        color: 'white',
        outline: 'none',
        backgroundColor: 'black',
        fontSize: '0.6rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontFamily: 'Roboto,Regular,Open Sans,sans-serif',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.3),
        paddingBottom: theme.spacing(1.3),
        border: 'solid 1px #565b5c',
        borderRadius: '14px',
        boxShadow:
            '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        '&:focus': {
            backgroundColor: 'black',
            color: 'white',
        },
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
            border: 'solid 0.5px #565b5c',
        },
    },
    selectMenu: {
        '& option': {
            padding: theme.spacing(1),
        },
    },
    noElevation: {
        boxShadow: '0px 3px 5px -1px transparent, 0px 6px 10px 0px transparent, 0px 1px 18px 0px transparent',
    },
    noElevationActive: {
        boxShadow: '0px 3px 5px -1px transparent, 0px 6px 10px 0px transparent, 0px 1px 18px 0px transparent',
    },
    icon: {
        color: 'white',
        top: 'calc(50% - 13px);',
    },

    menuItem: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: 'white !important',
        },
        '& .MuiPaper-root': {
            backgroundColor: 'black',
        },

        background: '#363b3d',
        color: 'white',
        backgroundPosition: 'left',
        backgroundImage: `url(${RadioButtonUnchecked})`,
        backgroundSize: '13px 13px',
        backgroundRepeat: 'no-repeat',
        marginLeft: '8px',
        paddingLeft: '23px',
        paddingRight: '19px',
        marginRight: '8px',
        fontSize: '12px',
    },
    menuProps: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: '#363b3d !important',
            color: 'white',
            backgroundPosition: 'left',
            backgroundImage: `url(${RadioButtonChecked})`,
            backgroundSize: '13px 13px',
            backgroundRepeat: 'no-repeat',
            marginLeft: '8px',
            paddingLeft: '23px',
            position: 'relative',
            fontSize: '12px',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#363b3d',
            borderRadius: '8px',
            fontSize: '12px',
        },
        '& .MuiList-padding': {
            backgroundColor: '#363b3d',
            borderRadius: '8px',
            fontSize: '12px',
        },
        '& .MuiPopover-paper': {},
    },
    empty: {},
    '@global': {
        '.MuiSelect-iconOutlined': { top: '23px', left: '0', right: '0', margin: 'auto', color: '#37c0ca' },
        '.MuiSelect-outlined.MuiSelect-outlined': { paddingRight: '0px' },
        '.MuiSelect-select:focus': { background: 'rgba(0, 0, 0, 0.00)', color: '#37c0ca' },
    },
}))

export function LanguageSettings() {
    const classes = useStyles()

    const [language, setLanguage] = useState<number>(0)

    const langs = [
        { id: 0, val: 'EN' },
        { id: 1, val: 'SK' },
        { id: 2, val: 'MK' },
    ]

    const handleLanguageChange = (newLanguage: any) => {
        var langId = newLanguage.target ? newLanguage.target.value : newLanguage
        setLanguage(langId)
        switch (langId) {
            case 0:
                changeLanguage('EN')
                break
            case 1:
                changeLanguage('SK')
                break
            case 2:
                changeLanguage('MK')
                break
            default:
                break
        }
    }

    const changeLanguage = (ln: string) => {
        i18n.changeLanguage(ln)
        setDefaultLanguage(ln)
        console.log('Language changed to' + ln)
    }

    useEffect(() => {
        switch (getDefaultLanguage()) {
            case 'MK':
                setLanguage(2)
                break
            case 'SK':
                setLanguage(1)
                break
            case 'EN':
                setLanguage(0)
                break
            default:
                break
        }
    }, [])

    return (
        <>
            <FormControl variant="outlined">
                <Select
                    className={classes.select}
                    value={language}
                    classes={{
                        root: classes.noElevation,
                    }}
                    input={<Input classes={{ focused: classes.empty }} />}
                    MenuProps={{
                        className: classes.menuProps,
                    }}
                    onChange={handleLanguageChange}
                >
                    {langs.map((lang, i) => (
                        <MenuItem
                            value={lang.id}
                            key={lang.id}
                            className={classes.menuItem}
                            style={i !== 0 ? { borderTop: '1px solid #484e51' } : {}}
                        >
                            {lang.val}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}
