//import { values } from 'lodash';
import { UPDATE_LOCATION_INFO, UPDATE_CONNECTOR_STATUS_LOCATION_DETAIL/*, UPDATE_CHARGING_SESSION_LOCATION_DETAIL*/ } from './../const/index'


export const locationDetailReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${UPDATE_LOCATION_INFO}`: {
            if (!state.value) {
                return state
            }
            if (state.value.length === 0) {
                return state
            }
            return {
                ...state,
                value: state.value.id === action.payload.locationInfo.Id ? {
                    ...state.value,
                    chargerCount: action.payload.locationInfo.ChargerCount,
                    chargersInUseCount: action.payload.locationInfo.ChargersInUseCount,
                    availableConnectors: action.payload.locationInfo.AvailableConnectors,
                    workingConnectors: action.payload.locationInfo.WorkingConnectors,
                    connectedChargers: action.payload.locationInfo.ConnectedChargers,
                    connectorCount: action.payload.locationInfo.ConnectorCount,
                } : { ...state.value }

            };
        }
        case `${UPDATE_CONNECTOR_STATUS_LOCATION_DETAIL}`: {
            if (!state.value) {
                return state
            }
            if (state.value.length === 0) {
                return state
            }

            //state.value.chargers.forEach((charger: any) => {
            /*var count = state.value.chargers.length;
            for (var j = 0; j < count; j++) {
                var connectorsEdited = (state.value.chargers[j].connectors.map((value: any) =>
                    value.id === action.payload.locationInfo.Id ? {
                        ...value,
                        state: action.payload.locationInfo.State,
                        availability: action.payload.locationInfo.Availability,
                        active: action.payload.locationInfo.Active,
                    } : value
                )
                );
                state.value.chargers[j].connectors = connectorsEdited
            }
            state.value.socketUpdate = state.value.socketUpdate == undefined ? true : !state.value.socketUpdate
            return state*/

            return {
                ...state,
                value: {
                    ...state.value,
                    chargers: state.value.chargers.map((charger: any) =>
                        true ? {
                            ...charger,
                            connectors: charger.connectors.map((connector: any) =>
                                connector.id === action.payload.locationInfo.Id ? {
                                    ...connector,
                                    state: action.payload.locationInfo.State,
                                    availability: action.payload.locationInfo.Availability,
                                    active: action.payload.locationInfo.Active,
                                    inPreparingState: action.payload.locationInfo.InPreparingState,
                                } : connector
                            )
                        } : charger
                    ),
                    connectorUpdate: state.value.connectorUpdate === undefined ? true : state.value.connectorUpdate
                }
            };

        }
        /*        case `${UPDATE_CHARGING_SESSION_LOCATION_DETAIL}`: {
                    if (!state.value) {
                        return state
                    }
                    if (state.value.length === 0) {
                        return state
                    }
                    return {
                        ...state,
                        value: state.value.chargers.map((charger: any) =>
                            charger.connectors.map((connector: any) =>
                                connector.id === action.payload.ChargingConnectorId ? {
                                    ...connector.lastSession,
                                    endedAt: action.payload.State,
                                    startedAt: action.payload.Availability,
                                    state: action.payload.Active,
                                } : connector
                            )
                        )
                    };
        
                }*/
        default: {
            return state
        }
    }
}