import React, { SFC, useState } from 'react'
import { DeleteOverlay } from './deleteOverlay'
import { ImageGallery } from './imageGallery'
import { AddImage } from './addImage'
import { GridList, makeStyles, Theme, GridListTile, createStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Image } from '../types/common'
import { Image as ImageComponent } from '../components/Image'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            flexWrap: 'nowrap',
            transform: 'translateZ(0)',
        },
    })
)

interface ImagesManagerProps {
    images: Image[]
    imageClassName?: string
    imageCols?: number
    uploadAction?(files: File[]): Promise<any>
    uploadError?(): void
    uploadProgressStateSelector?(state: any): any
    deleteAction?(image: Image): Promise<any>
    deleteProgressStateSelector?(state: any): any
}

const ImagesManager: SFC<ImagesManagerProps> = ({
    images,
    imageClassName,
    imageCols,
    uploadAction,
    uploadError,
    uploadProgressStateSelector,
    deleteAction,
    deleteProgressStateSelector,
}) => {
    const [selectedImage, setSelectedImage] = useState<number>()
    const classes = useStyles()

    const imageColsToUse = imageCols ?? (useMediaQuery(useTheme().breakpoints.up('md')) ? 1.5 : 3)
    return (
        <div>
            {selectedImage !== undefined && (
                <ImageGallery
                    urls={images.map((x) => x.url)}
                    initialIndex={selectedImage}
                    onClose={() => setSelectedImage(undefined)}
                />
            )}
            <GridList className={classes.gridList} cols={13} cellHeight={'auto'}>
                {images.map((x, index) => (
                    <GridListTile key={x.id} cols={imageColsToUse}>
                        {deleteAction && deleteProgressStateSelector ? (
                            <DeleteOverlay
                                deleteAction={async () => await deleteAction(x)}
                                progressStateSelector={deleteProgressStateSelector}
                            >
                                <ImageComponent
                                    url={x.url}
                                    className={imageClassName}
                                    imageClassName={imageClassName}
                                    onClick={() => setSelectedImage(index)}
                                />
                            </DeleteOverlay>
                        ) : (
                            <ImageComponent
                                url={x.url}
                                className={imageClassName}
                                imageClassName={imageClassName}
                                onClick={() => setSelectedImage(index)}
                            />
                        )}
                    </GridListTile>
                ))}
                {uploadProgressStateSelector && uploadAction && (
                    <GridListTile cols={imageColsToUse}>
                        <AddImage
                            className={imageClassName}
                            progressStateSelector={uploadProgressStateSelector}
                            onUpload={uploadAction}
                            onUploadError={uploadError}
                        />
                    </GridListTile>
                )}
            </GridList>
        </div>
    )
}

export default ImagesManager
