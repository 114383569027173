import { useState, useCallback } from 'react'

export const usePromise = (workerFunction: Function): [Function, boolean, any, any] => {
    const [inProgress, setInProgress] = useState<boolean>(false)
    const [result, setResult] = useState(undefined)
    const [error, setError] = useState(undefined)

    const executor = useCallback(
        (...params: any) => {
            setInProgress(true)
            workerFunction(...params)
                .then((workerResult: any) => {
                    setInProgress(false)
                    setResult(workerResult)
                })
                .catch((workerError: any) => {
                    setInProgress(false)
                    setError(workerError)
                })
        },
        [workerFunction]
    )

    return [executor, inProgress, result, error]
}
