import React, { useState } from 'react'
import { Typography, Grid, AppBar, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PulsingHeader } from '../../shared/components/pulsingHeader'
import { LanguageSettings } from '../../utils/languageSettings'
import { ChargingSession } from '../../shared/types/location'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AsyncActionConfirmationDialog } from '../../shared/components/asyncActionConfirmationDialogStyled'
import { getMainTenantId } from '../../config'

const useStyles = makeStyles((theme) => ({
    header: {
        zIndex: 5,
        fontSize: '1rem',
    },
    registeredUsersContentcontainer: {
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
        margin: '0px 23px 0px 23px',
    },
    registeredUsersContent: {
        marginTop: '19px',
        marginBottom: '30px',
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
    registeredUsersLink: {
        color: '#37c0ca',
        textDecoration: 'none',
        '&:hover': {
            color: '#37c0ca',
            textDecoration: 'underline',
        },
    },
    registeredUsersContact: {
        color: '#37c0ca',
        textDecoration: 'none',
        fontSize: '18px',
        paddingTop: '1em',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    titleContainer: {
        maxHeight: '24px',
        marginBottom: '26px',
        textAlign: 'center',
    },
    headerConstainer: {
        marginBottom: '10px',
        marginTop: '23px',
        marginLeft: '23px',
        marginRight: '23px',
    },
    dialog: {},
    '@global': {
        '.MuiDialog-paperScrollPaper': {
            backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 1) 100%)',
            borderRadius: '16px',
        },
    },
}))

interface RegisteredOnlyProps {
    title: string
    languageSettings?: boolean
}

export const RegisteredUsersOnly: React.FC<RegisteredOnlyProps> = ({ title, languageSettings }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const tenant = useSelector((state: any) => state.tenant.value);
    const mainTenantId = getMainTenantId();

    const chargingSession: ChargingSession = useSelector((state: any) => state.chargingSession.value)

    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const handleRedirect = () => {
        if (chargingSession) {
            setOpenDialog(true)
        } else {
            history.push('/register')
        }
    }

    return (
        <>
            <div>
                <Grid container className={classes.header} direction="row">
                    <AppBar color="transparent" position="relative" elevation={0}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                className={classes.headerConstainer}
                            >
                                <Grid item style={{ justifySelf: 'start' }}></Grid>
                                <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                    <PulsingHeader mainWindowScroll={window.pageYOffset} />
                                    {languageSettings != null && languageSettings === true ? <LanguageSettings /> : ''}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography variant="h3" className={classes.title}>
                                {title}
                            </Typography>
                        </Grid>
                        {tenant && tenant.id === mainTenantId ? (
                            <Grid item xs={12} className={classes.registeredUsersContentcontainer}>
                                <div className={classes.registeredUsersContent}>
                                    {t('login.registeredUsersOnlyFeature')}
                                    {t('login.registeredUsersOnlyFollowLink')}
                                    <span className={classes.registeredUsersLink} onClick={handleRedirect}>
                                        {t('login.register')}
                                    </span>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={12} className={classes.registeredUsersContentcontainer}>
                            <div className={classes.registeredUsersContent}>
                                {t('login.registeredUsersOnlyFeature')}
                                {t('login.registeredUsersOnlyFollowContact')}
                                <Grid item xs={12} className={classes.registeredUsersContact}>
                                    {tenant.contact}
                                </Grid>
                            </div>
                        </Grid>
                        )}
                    </AppBar>
                </Grid>
                <AsyncActionConfirmationDialog
                    open={openDialog}
                    inProgress={false}
                    cancelLabel={t('common.close')}
                    confirmLabel={t('common.confirm')}
                    title={t('common.confirmation')}
                    content={t('login.chargingInProgress')}
                    onConfirm={async () => {
                        history.push('/register')
                    }}
                    onCancel={() => setOpenDialog(false)}
                ></AsyncActionConfirmationDialog>
            </div>
        </>
    )
}
