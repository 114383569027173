import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    bottomPannelContainer: {
        background: 'transparent',
        bottom: '0px',
        width: '100%',
        zoom: '0.75',
        left: '0',
        right: '0',
        margin: 'auto',
        zIndex: 3,
    },
    wrapper: {
        color: 'white',
        background: 'transparent',
    },
    wrapperInner: {
        backgroundColor: 'black',
        height: '90px',
        position: 'relative',
        borderBottom: '0px',

        borderRadius: '20px 20px 0px 0px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource: 'linear-gradient(to right, #000507, #37c0ca 36%, #42c7d0 68%, #000507)',
        borderImageSlice: '1',
        backgroundImage:
            'linear-gradient(to bottom, #000507, #000507), linear-gradient(to right, #000507, #37c0ca 36%, #42c7d0 68%, #000507)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
    },
    middleItem: {
        backgroundColor: '#00000000',
        marginBottom: '15px',
    },
    sideItems: {
        width: '40%',
    },
    menuItem: {
        marginTop: '13px',
    },
    menuItemActive: {
        marginTop: '13px',
        color: '#37c0ca',
    },
    middleCircle: {
        background: 'black',
        width: '118px',
        height: '118px',
        left: 'calc(50% - 59px)',

        position: 'absolute',
        top: '-43px',
        border: 'double 1px transparent',
        backgroundImage: 'linear-gradient(black, black), linear-gradient(to bottom, #b7faff, #37c0ca 16%, #000507)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
        backgroundColor: '#000507',
        borderRadius: '50%',
    },
}))

type BottomControlPanelProps = {
    actualPage: string
    positionAbsolute: boolean
    asyncButton: any
}
export function BottomControlPanel({ actualPage, positionAbsolute, asyncButton }: BottomControlPanelProps) {
    const classes = useStyles()

    return (
        <Grid
            item
            xs={12}
            // justify="center"
            className={classes.bottomPannelContainer}
            style={{ position: positionAbsolute ? 'absolute' : 'relative' }}
        >
            <Grid item xs={12} container justify="center" className={classes.wrapper}>
                <Grid item xs={12} container justify="center" className={classes.wrapperInner}>
                    <Grid item container justify="center" className={classes.sideItems}></Grid>
                    <Grid item className={classes.middleItem} style={{ width: '40%' }}>
                        <div className={classes.middleCircle}>{asyncButton}</div>
                    </Grid>
                    <Grid item container justify="center" className={classes.sideItems}></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
