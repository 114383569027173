import React from 'react'
import { Editor, EditorState, convertFromRaw, ContentState } from 'draft-js';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    readOnlyEditor: {
        '& blockquote': {
            borderLeft: `5px solid ${theme.palette.secondary.main}`,
            fontFamily: '"Hoefler Text", "Georgia", serif',
            fontStyle: 'italic',
            margin: theme.spacing(2, 0),
            padding: theme.spacing(1, 2),
        }
    }

}))

export const ReadOnlyEditor = (props: any) => {
    const classes = useStyles()

    let editorState = EditorState.createEmpty()

    if (props.value) {
        try {
            editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.value)))
        }
        catch (e) {
            editorState = EditorState.createWithContent(ContentState.createFromText(props.value))
        }
    }

    return (
        <div className={classes.readOnlyEditor}>
            <Editor
                editorState={editorState}
                readOnly={true}
                onChange={() => { }}
            />
        </div>
    )
}
