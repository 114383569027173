import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BottomControlPanel } from '../../core/footer/bottomControlPanel'
import SearchBar from '../../core/appBar/SearchBar'
import { ListItem } from './ListItem'
import { useSelector, useDispatch } from 'react-redux'
import { fetchListLocations } from './../../redux/actions/locations'
import { Button } from '@material-ui/core'
import { NoData } from '../../shared/components/NoData'
import { useChargersStatusPeriodicUpdate } from '../../utils/currentChargersStatus'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        backgroundColor: 'black',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1',
        zIndex: 1,
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
        overflow: 'auto',
        marginBottom: '10px',
    },
    loadMoreButton: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(6),
    },
    dataInfoWrapper: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
    },
    spacingDiv: {
        height: '100px',
    },
    decorationBottom: {
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
        position: 'absolute',
        bottom: '-1px',
        width: '100%',
        left: '0',
        right: '0',
        height: '400px',
        margin: 'auto',
        zIndex: 0,
    },
}))

export default function MainList() {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const locations = useSelector((state: any) => state.locationsList)
    const user = useSelector((state: any) => state.user.login.value.user)
    const tenant = useSelector((state: any) => state.tenant.value)

    const filter = useSelector((state: any) => state.filter)

    const [pageNumber, setPageNumber] = useState<number>(1)

    const stopExecution = useRef(false)
    const resetPageNumber = useRef(false)

    useEffect(() => {
        resetPageNumber.current = true
    }, [filter])

    useEffect(() => {
        var key
        if (!stopExecution.current && locations.executed) {
            if (resetPageNumber.current) {
                if (locations.key === 1) {
                    key = 0
                } else {
                    key = 1
                }
                resetPageNumber.current = false
            } else {
                key = locations.key
            }
            dispatch(
                fetchListLocations(key, locations, pageNumber, {
                    search: filter.search.value,
                    availability: filter.availability.value,
                    distance: filter.distance.value,
                    connector: filter.connector.value,
                    showHiddenLocation: user.showHiddenLocation,
                    sortByDistance: filter.sortByDistance.value,
                    tenantId: tenant?.id,
                })
            )
        }
    }, [dispatch, filter, locations, locations.value, user.showHiddenLocation, pageNumber, tenant])

    useEffect(() => {
        if (locations.executed) {
            stopExecution.current = !stopExecution.current
        }
    }, [locations.executed, locations.value, filter, pageNumber])

    const loadMoreButton = () => {
        setPageNumber(pageNumber + 1)
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window
        return {
            width,
            height,
        }
    }

    useChargersStatusPeriodicUpdate()

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const [windowDimensions, setWindowDimensions] = useState<any>(getWindowDimensions())

    return (
        <>
            <div className={classes.main} style={{ height: windowDimensions.height }}>
                <SearchBar float={false} elevationScroll={true} fullWidthChipsBar={true} blackStyle={true} />
                <div className={classes.content}>
                    {locations.value.items?.map((item: any, index: number) => (
                        <ListItem key={item.id + index} item={item} />
                    ))}
                    <div>
                        {locations.hasMorePages && locations.executed && (
                            <Button
                                className={classes.loadMoreButton}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    loadMoreButton()
                                }}
                            >
                                {t('common.loadMore')}
                            </Button>
                        )}
                    </div>
                    <div>
                        {locations.totalCount === 0 && (
                            <div className={classes.dataInfoWrapper}>
                                <NoData text={t('locations.noLocations')} />
                            </div>
                        )}
                    </div>
                    <div className={classes.spacingDiv} />
                </div>
                <div style={{ height: '0px', width: '100%', position: 'relative' }}>
                    <BottomControlPanel actualPage="MainList" positionAbsolute={true} />
                    <div className={classes.decorationBottom} />
                </div>
            </div>
        </>
    )
}
