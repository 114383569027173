import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { sendJson } from '../../shared/utils/api'
import { usePromise } from '../../shared/utils/usePromise'
import { promiseError } from '../../shared/redux/actions/common'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ValidationError from '../../shared/utils/validationError'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const confirmAction = (token: string) => sendJson('users/confirm', 'POST', token)

const ConfirmAccount = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()

    const dispatch = useDispatch()

    const confirmAccountRouteMatch = useRouteMatch(`/confirm-account/:token`)

    const routeToken: string = (confirmAccountRouteMatch?.params as any)?.token

    const [confirmPasswordStart, inProgress, result, error] = usePromise(confirmAction)

    useEffect(() => {
        confirmPasswordStart(routeToken)
    }, [confirmPasswordStart, routeToken])
    useEffect(() => {
        if (error instanceof ValidationError) {
            dispatch(promiseError(t('login.accountConfirmationValidationError', { error })))
        } else if (error) {
            dispatch(promiseError(t('login.accountConfirmationError', { error })))
        }
    }, [dispatch, t, error])
    useEffect(() => {
        if (result) {
            history.push('/?confirmationSuccess')
        }
    }, [result, history])

    return <div className={classes.wrapper}>{inProgress && <CircularProgress />}</div>
}

export default ConfirmAccount
