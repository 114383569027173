import { ERROR_DISMISS } from '../const'

const getDefaultState = (defaultValue: any) => ({
    error: null,
    executed: true,
    value: defaultValue,
})

export const asyncPagedActionReducer = (
    actionName: string,
    additionalScenarios: (state: any, action: any) => any = (state) => {
        return state
    }
) => (
    state = {
        ...getDefaultState([]),
        key: null,
        data: [],
        pageIndex: null,
        hasMorePages: null,
        totalCount: null,
    },
    action: any
) => {
    let baseState = asyncActionReducer(actionName)(state, action)
    switch (action.type) {
        case `${actionName}_SUCCESS`: {
            const previousData = action.payload.key === state.key ? (state.data as any) : []
            return {
                ...baseState,
                key: action.payload.key,
                data: previousData.concat(action.payload.result.items),
                pageIndex: action.payload.pageIndex,
                totalCount: action.payload.result.count,
                hasMorePages: previousData.length + action.payload.result.items.length < action.payload.result.count,
            }
        }
        default: {
            return additionalScenarios(baseState, action)
        }
    }
}

export const asyncActionReducer = (
    actionName: string,
    defaultValue: any = null,
    additionalScenarios: (state: any, action: any) => any = (state) => {
        return state
    }
) => (state = getDefaultState(defaultValue), action: any) => {
    switch (action.type) {
        case `${actionName}_START`: {
            return {
                ...state,
                error: null,
                executed: false,
            }
        }
        case `${actionName}_SUCCESS`: {
            return {
                ...state,
                error: null,
                value: action.payload.result,
                executed: true,
            }
        }
        case `${actionName}_ERROR`: {
            return {
                ...state,
                error: action.payload.error,
                executed: true,
            }
        }
        case `${actionName}_CLEAR`: {
            return getDefaultState(defaultValue)
        }
        default: {
            return additionalScenarios(state, action)
        }
    }
}

export const valueHolderReducer = (
    actionName: string,
    defaultValue: any = null,
    additionalScenarios: (state: any, action: any) => any = (state) => {
        return state
    }
) => (state = { value: defaultValue }, action: any) => {
    switch (action.type) {
        case actionName: {
            return {
                value: action.payload.value,
            }
        }
        default: {
            return additionalScenarios(state, action)
        }
    }
}

export const errorReducer = () => (state = { current: null }, action: any) => {
    if (action.type.endsWith('_ERROR')) {
        return {
            ...state,
            current: action.payload.error,
            codeToMessageMap: action.payload.codeToMessageMap,
        }
    } else if (action.type === ERROR_DISMISS) {
        return {
            ...state,
            current: null,
            codeToMessageMap: null,
        }
    } else {
        return state
    }
}
