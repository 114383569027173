import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'
import { save, load } from 'redux-localstorage-simple'

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const statesToPersist = ['filter']

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunkMiddleware, save({ states: statesToPersist })))(
    createStore
)
export default createStoreWithMiddleware(rootReducer, load({ states: statesToPersist }))
