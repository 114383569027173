import React from 'react'
import {
    makeStyles,
    Button,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
} from '@material-ui/core'
import { DialogConfirmButton } from './dialogConfirmButtonStyled'
import { NoProgress } from './noProgress'
import CloseOutlined from '@material-ui/icons/CloseOutlined'

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
        justifyContent: 'center',
    },
    mainDialog: {
        zoom: '0.75',
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
        },
    },
    main: {
        color: 'white',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 0) 100%)',
        backgroundColor: 'black',
    },
    closeBackIcon: {
        color: 'white',
        width: '16px',
    },
    closeButton: {
        marginRight: '24px',
        width: '48px',
        minWidth: '48px',
        height: '60px',
        borderRadius: '14px',
        backgroundImage: 'linear-gradient(141deg, #3a4245, #1b1f21 100%)',
        '&:hover': {
            color: 'black',
        },
    },
}))

type AsyncActionConfirmationDialogProps = {
    open: boolean
    title: string
    content: string
    confirmLabel: string
    cancelLabel: string
    onConfirm: Function
    onCancel: Function
    inProgress: boolean
}
export function AsyncActionConfirmationDialog({
    open,
    title,
    content,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    inProgress,
}: AsyncActionConfirmationDialogProps) {
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.mainDialog}
        >
            <div className={classes.main}>
                {inProgress === true ? <LinearProgress /> : <NoProgress />}
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="center">
                    <Grid item style={{ justifySelf: 'start' }}>
                        <DialogTitle id="alert-dialog-title">
                            <p style={{ color: 'white' }}>{title}</p>
                        </DialogTitle>
                    </Grid>
                    <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                        <Button className={classes.closeButton} onClick={() => onCancel()} color="primary">
                            <CloseOutlined className={classes.closeBackIcon} />
                        </Button>
                    </Grid>
                </Grid>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <DialogConfirmButton label={confirmLabel} onClick={() => onConfirm()} />
                </DialogActions>
            </div>
        </Dialog>
    )
}
