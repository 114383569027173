import { PersistenceState, Image } from "./common";
import { TagCharger } from "./tag";

export type ChargerModel = {
  locationId: string;
  tenantId: string;
  name: string;
  manufacturer: string;
  brand: string;
  textInstructionForNavigation: string;
  textInstructionHowToStartCharging: string;
  linkToForum: string;
  serviceNote: string;
  externalId: string;
  integrations: string;
};

export type IntegrationType = {
  id: string;
  name: string;
}

export type Charger = ChargerModel & {
  id: string;
  connectors: ChargerConnector[];
  images: Image[];
  tags: TagCharger[];
};

export enum ChargerConnectorType {
  Ac,
  Dc,
}

export enum ChargerConnectorSocketType {
  Type1,
  Type2,
  CHAdeMO,
  CCS,
}

export enum ChargingInitSources {
  SEAK,
  HUBJECT,
  VILLAPRO
}

export enum ChargerConnectorState {
  Free,
  Busy,
  Error,
  OutOfOrder,
  Unknown,
  Preparing = 10
}

export enum ChargerConnectorAvailability {
  Available,
  Unavailable
}

export type ChargerConnectorModel = {
  chargerId: string;
  type: ChargerConnectorType;
  socketType: ChargerConnectorSocketType;
  state: ChargerConnectorState;
  externalId: number;
  availability: ChargerConnectorAvailability;
  inPreparingState: boolean;
};

export type ChargerConnector = ChargerConnectorModel & {
  id: string;
  persistenceState: PersistenceState;
};

export type ChargerConnectorSpecification = {
  type: ChargerConnectorType;
  socketType: ChargerConnectorSocketType;
};

function getChargerConnectorsSpecifications(): ChargerConnectorSpecification[] {
  return [
    {
      type: ChargerConnectorType.Ac,
      socketType: ChargerConnectorSocketType.Type1,
    },
    {
      type: ChargerConnectorType.Ac,
      socketType: ChargerConnectorSocketType.Type2,
    },
    {
      type: ChargerConnectorType.Dc,
      socketType: ChargerConnectorSocketType.CCS,
    },
    {
      type: ChargerConnectorType.Dc,
      socketType: ChargerConnectorSocketType.CHAdeMO,
    },
  ];
}

export function getSpecificationForSocketType(
  socketType: ChargerConnectorSocketType
): ChargerConnectorSpecification | undefined {
  return getChargerConnectorsSpecifications().find(
    (x) => x.socketType === socketType
  );
}

export function getChargerConnectorTypes(t: any) {
  return [
    {
      id: ChargerConnectorType.Ac,
      label: t("locations.connectorTypeAc"),
    },
    {
      id: ChargerConnectorType.Dc,
      label: t("locations.connectorTypeDc"),
    },
  ];
}
export function getLabelForChargerConnectorType(
  type: ChargerConnectorType | null | undefined,
  t: any
) {
  if (type === null || type === undefined) {
    return t("common.unknown");
  }

  const foundType = getChargerConnectorTypes(t).find((x) => x.id === type);
  return foundType ? foundType.label : t("common.unknown");
}

const getConncetorStateLabel = (t: any, chargerConnectorState: ChargerConnectorState) => {
  var label = ""
  switch (chargerConnectorState) {
    case ChargerConnectorState.Busy:
    case ChargerConnectorState.Error:
    case ChargerConnectorState.OutOfOrder:
    case ChargerConnectorState.Unknown:
      label = t("locations.connectorBusy");
      break;
    case ChargerConnectorState.Free:
      label = t("locations.connectorAvailable");
      break;
    case ChargerConnectorState.Preparing:
      label = t("locations.connectorPreparing");
      break;
    default:
      label = ""
  }
  return label;
}


export function getChargerConnectorSocketTypes(t: any) {
  return [
    {
      id: ChargerConnectorSocketType.Type1,
      label: t("locations.connectorSocketTypeType1"),
    },
    {
      id: ChargerConnectorSocketType.Type2,
      label: t("locations.connectorSocketTypeType2"),
    },
    {
      id: ChargerConnectorSocketType.CHAdeMO,
      label: t("locations.connectorSocketTypeChademo"),
    },
    {
      id: ChargerConnectorSocketType.CCS,
      label: t("locations.connectorSocketTypeCcs"),
    },
  ];
}
export function getLabelForChargerConnectorSocketType(
  socketType: ChargerConnectorSocketType,
  t: any
) {
  const foundSocketType = getChargerConnectorSocketTypes(t).find(
    (x) => x.id === socketType
  );
  return foundSocketType ? foundSocketType.label : t("common.unknown");
}

export const getConnectorLabel = (
  t: any,
  socketType: ChargerConnectorSocketType,
  chargerConnectorState?: ChargerConnectorState,
  externalId?: number,
  inPreparingState?: boolean,

) => {
  const socketLabel = getLabelForChargerConnectorSocketType(socketType, t);
  const specification = getSpecificationForSocketType(socketType);
  let status;
  if (inPreparingState === true) {
    chargerConnectorState = ChargerConnectorState.Preparing;
  }
  if (chargerConnectorState) {
    status = getConncetorStateLabel(t, chargerConnectorState);
  }

  if (specification) {
    const typeLabel = getLabelForChargerConnectorType(specification.type, t);
    let result = `${socketLabel} (${typeLabel})`;
    if (externalId) {
      result = `${externalId} - ${result} ${status ? status : ""}`;
    }
    return result;
  }

  return t("common.unknown");
};
