import React from 'react'
import { Marker } from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { LocationInfo } from '../../shared/types/location'
const green = require(`../../static/map_icon_charger_available.gif`)
const orange = require(`../../static/map_icon_charger_unavailable.png`)
const red = require(`../../static/map_icon_charger_red.png`)

export type LocationMarkerProps = {
    location: LocationInfo
    onClick: Function
    clusterer: Clusterer
}

export const LocationMarker = ({ location, onClick, clusterer }: LocationMarkerProps) => {
    let icon: any
    if (location.workingConnectors === 0 || location.connectedChargers.length === 0)  {
        icon = {
            url: red,
            anchor: new window.google.maps.Point(25, 25),
            scaledSize: new window.google.maps.Size(50, 50),
        }
    }else if (location.availableConnectors === 0) {
        icon = {
            url: orange,
            anchor: new window.google.maps.Point(25, 25),
            scaledSize: new window.google.maps.Size(50, 50),
        }
    } else if (location.availableConnectors > 0) {
        icon = {
            url: green,
            anchor: new window.google.maps.Point(40, 40),
            scaledSize: new window.google.maps.Size(80, 80),
        }
    }

    return (
        <Marker
            icon={icon}
            position={{
                lat: location.latitude,
                lng: location.longitude,
            }}
            onClick={() => onClick()}
            clusterer={clusterer}
        ></Marker>
    )
}
