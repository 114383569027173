import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useRouteMatch } from 'react-router-dom'
import { ChargingStart } from './ChargingStart'
import { ChargingInProgress } from './ChargingInProgress'
import { ChargingDone } from './ChargingDone'
import { useSelector, useDispatch } from 'react-redux'
import { LocationWithChargers, ChargingSession, ChargingSessionState } from '../../shared/types/location'
import { fetchLocationDetail } from '../../redux/actions/locations'

const useStyles = makeStyles((theme) => ({
    main: {
        height: '100%',
        backgroundColor: 'black',
    },
}))

export default function Charging() {
    const classes = useStyles()

    const chargingRouteMatch = useRouteMatch(`/charging/:locationId`)
    const routeParams: any = chargingRouteMatch?.params
    const locationId: string = routeParams?.locationId

    const dispatch = useDispatch()
    const location = useSelector((state: any) => state.locationDetail.value) as LocationWithChargers
    const chargingSession: ChargingSession = useSelector((state: any) => state.chargingSession.value)

    if (!location) {
        switch (chargingSession?.state) {
            case ChargingSessionState.InProgress:
            case ChargingSessionState.Parking:
            case ChargingSessionState.Finished:
                break
        }
    }

    useEffect(() => {
        if (!location || location.id !== locationId) {
            dispatch(fetchLocationDetail(locationId));
        }
    }, [dispatch, location, locationId])

    let content: any
    if (location) {
        switch (chargingSession?.state) {
            case ChargingSessionState.InProgress:
            case ChargingSessionState.Parking:
                content = <ChargingInProgress location={location} />
                break
            case ChargingSessionState.Finished:
                content = <ChargingDone location={location} />
                break
            default:
                content = <ChargingStart location={location} />
        }
    }

    return (
        <Grid className={classes.main} container direction="column" wrap="nowrap">
            {content}
        </Grid>
    )
}
