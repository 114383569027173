import React, { useState } from 'react'
import { Grid, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import EyeIcon from '../static/icon-eye-white.svg'
import EyeOffIcon from '../static/icon-eye-off-white.svg'

const commonStyles = makeStyles((theme) => ({
    input: {
        paddingLeft: '5px',
        marginInlineStart: '-22px',
        color: 'white',
        background: 'black',
        paddingRight: '5px',
        paddingTop: '2px',
        fontSize: '14px',
        border: '0px !important',
        fontFamily: "'Mulish', sans-serif",
        WebkitAppearance: 'none',
    },
    inputIconRight: {
        marginLeft: '3%',
        color: 'white',
        opacity: '0.3',
        marginBottom: '14px',
        marginTop: '20px',
        height: '17px',
    },
}))

const standardStyles = makeStyles((theme) => ({
    wrapper: {
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #565b5c',
        background: 'black',
    },
    inputTextField: {
        '&& .MuiInput-root:hover::before': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiInput-root:hover::after': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiFormLabel-root.Mui-focused': {
            color: 'white',
            border: 'none',
        },
        '& label.Mui-focused': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            paddingTop: '0px',
            color: '#00e3e3 !important',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            marginLeft: '-22%',
            marginRight: '0px',
            marginTop: '57px',
            paddingTop: '3px',
            fontSize: '7px',
            position: 'absolute',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            '&.MuiInputLabel-outlined': {
                background: 'black',
                paddingRight: '10px',
            },
            '&.MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                padding: '6px',
            },
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        '& input:-internal-autofill-focused': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        WebkitBoxShadow: '0 0 0 50px black inset',
        WebkitAppearance: 'none',
    },
}))

const errorStyles = makeStyles((theme) => ({
    wrapper: {
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #de384a',
        background: 'black',
    },
    inputTextField: {
        '&& .MuiInput-root:hover::before': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiInput-root:hover::after': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiFormLabel-root.Mui-focused': {
            color: 'white',
            border: 'none',
        },
        '& label.Mui-focused': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            paddingTop: '0px',
            color: '#de384a !important',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: '#de384a',
            marginLeft: '-23%',
            marginRight: '0px',
            marginTop: '57px',
            paddingTop: '6px',
            position: 'absolute',
            fontSize: '10px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.17,
            letterSpacing: 'normal',
            fontFamily: "'Mulish', sans-serif",
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            '&.MuiInputLabel-outlined': {
                background: 'black',
                paddingRight: '10px',
            },
            '&.MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                padding: '6px',
            },
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        '& input:-internal-autofill-focused': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        WebkitBoxShadow: '0 0 0 50px black inset',
        WebkitAppearance: 'none',
    },
    '@global': {
        '.MuiFormLabel-root.Mui-error': {
            color: '#de384a !important',
        },
    },
}))

type CustomTextFieldProps = {
    textFieldProps?: TextFieldProps
    standardChildren?: any
    errorChildren?: any
    password: boolean
    itemName?: string
    helperText?: string
    errorState: boolean
}
export function CustomTextField({
    textFieldProps,
    standardChildren,
    errorChildren,
    password,
    itemName,
    helperText,
    errorState,
}: CustomTextFieldProps) {
    const commonClasses = commonStyles()
    const errorClasses = errorStyles()
    const standardClasses = standardStyles()
    const classes = helperText ? errorClasses : standardClasses

    const children = helperText && errorChildren ? errorChildren : standardChildren

    const [showPassword, setShowPassword] = useState<boolean>()

    const changePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    return (
        <Grid item xs={12} className={classes.wrapper}>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item style={{ width: '17%', zIndex: 100 }}>
                    {children}
                </Grid>
                <Grid item style={{ width: password ? '65%' : '70%' }}>
                    <TextField
                        name={itemName ? itemName : ''}
                        className={classes.inputTextField}
                        {...textFieldProps}
                        InputProps={{
                            className: commonClasses.input,
                        }}
                        InputLabelProps={{
                            className: commonClasses.input,
                        }}
                        type={password && !showPassword ? 'password' : 'text'}
                        variant="outlined"
                        style={{ width: password ? '120%' : '110%' }}
                        fullWidth
                        autoComplete="off"
                        helperText={helperText}
                        error={errorState}
                    />
                </Grid>
                {password ? (
                    <Grid item style={{ width: '17%' }}>
                        <div onClick={changePasswordVisibility}>
                            {showPassword ? (
                                <img alt="Eye" src={EyeIcon} className={commonClasses.inputIconRight} />
                            ) : (
                                <img alt="Eye" src={EyeOffIcon} className={commonClasses.inputIconRight} />
                            )}
                        </div>
                    </Grid>
                ) : (
                    ''
                )}
            </Grid>
        </Grid>
    )
}
