import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'

type DialogConfirmButtonProps = {
    label: string
    stateSelector?(state: any): any
    disabled?: boolean
    onClick?: Function
}
export function DialogConfirmButton({ label, stateSelector, disabled, onClick = () => {} }: DialogConfirmButtonProps) {
    const defaultStateSelector = (state: any) => {
        return state
    }
    const state = useSelector(stateSelector ?? defaultStateSelector)

    return (
        <Button
            disabled={(stateSelector ? !state?.executed : false) || disabled}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => onClick()}
        >
            {label}
        </Button>
    )
}
