import { config, getToken } from '../../config'
import AuthorizationError from './authorizationError'
import ValidationError from './validationError'

export const getJson = (url: string, additionalHeaders: any = {}) => {
    return apiCall(url, 'GET', undefined, additionalHeaders)
}

export const getFile = (url: string) => {
    return apiCall(url, 'GET', undefined, {
        'content-type': 'application/octet-stream',
        'content-disposition': ' attachment'
    })
}

export const sendJson = (url: string, method: string, body: any = null, additionalHeaders: any = {}) => {
    return sendData(url, method, body ? JSON.stringify(body) : undefined, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...additionalHeaders,
    })
}

export const sendData = (url: string, method: string, body: any = null, additionalHeaders: any = {}) => {
    return apiCall(url, method, body, additionalHeaders)
}

const apiCall = (url: string, method: string, body: any = null, additionalHeaders: any = {}) => {
    const result: Promise<any> = new Promise((resolve, reject) => {
        fetch(`${config.api}${url}`, {
            method,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                ...additionalHeaders,
            },
            body: body,
        })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    const contentType: string | null = response.headers.get('Content-Type')
                    if (contentType === 'application/octet-stream') {
                        return response.blob()
                    }
                    if (contentType != null) {
                        return response.json()
                    } else {
                        resolve({})
                    }
                } else if (response.status === 400) {
                    return response.json().then((body) => {
                        return new ValidationError(body)
                    })
                } else if (response.status === 401) {
                    reject(new AuthorizationError())
                } else {
                    reject(new Error(`${response.status}: ${response.statusText}`))
                }
            })
            .then((result: any) => {
                if (result !== undefined) {
                    if (result instanceof ValidationError) {
                        reject(result)
                    } else {
                        resolve(result)
                    }
                }
            })
            .catch((reason) => {
                reject(reason)
            })
    })
    return result
}

export const patchProperty = (property: string, newValue: any) => {
    return {
        op: 'add',
        path: `/${property}`,
        value: newValue,
    }
}
