import React from 'react'
import { Fab, FormControl, Select, Input, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RadioButtonUnchecked from '../../src/static/icons/radio_button_unchecked_blue.svg'
import RadioButtonChecked from '../../src/static/icons/radio_button_checked_blue.svg'

const useBlackStyles = makeStyles((theme) => ({
    chip: {
        backgroundColor: '#ffffff',
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        color: '#000000',
        border: 'solid 1px #565b5c',
        opacity: 1,
        fontSize: '9px',
    },
    chipWithoutMenu: {
        height: '31px !important',
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: 'black',
        },
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                backgroundColor: '#37c0ca',
                color: '#37c0ca',
                borderColor: '#37c0ca',
            },
        },
    },
    activeChip: {
        backgroundColor: `black !important`,
        color: `#37c0ca !important`,
        border: '1px solid !important',
        borderColor: `#37c0ca !important`,
    },
    select: {
        color: 'white',
        outline: 'none',
        marginLeft: theme.spacing(2),
        border: '0px solid #ffffff',
        borderRadius: '20px',
        boxShadow: 'none',
        '&:before': {
            border: '0 !important',
        },
        '&:after': {
            border: '0 !important',
        },
    },
    selectInner: {
        color: 'white',
        outline: 'none',
        backgroundColor: 'black',
        fontSize: '9px',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontFamily: 'Roboto,Regular,Open Sans,sans-serif',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        border: 'solid 1px #565b5c',
        borderRadius: '20px',
        boxShadow:
            '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        '&:focus': {
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '20px',
            border: 'solid 0.5px #565b5c',
        },
        '&:hover': {
            backgroundColor: 'black',
            color: 'white',
            border: 'solid 0.5px #565b5c',
        },
    },
    selectMenu: {
        '& option': {
            padding: theme.spacing(1),
        },
    },
    noElevation: {
        boxShadow: '0px 3px 5px -1px transparent, 0px 6px 10px 0px transparent, 0px 1px 18px 0px transparent',

        '&:focus': {
            border: 'solid 0.5px #565b5c',
        },
    },
    noElevationActive: {
        boxShadow: '0px 3px 5px -1px transparent, 0px 6px 10px 0px transparent, 0px 1px 18px 0px transparent',
    },
    icon: {
        color: 'white',
        top: 'calc(50% - 13px);',
    },
    empty: {},
    menuItem: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: 'white !important',
        },
        '& .MuiPaper-root': {
            backgroundColor: 'black',
        },

        background: '#363b3d',
        color: 'white',
        backgroundPosition: 'left',
        backgroundImage: `url(${RadioButtonUnchecked})`,
        backgroundSize: '14px 14px',
        backgroundRepeat: 'no-repeat',
        fontSize: '11px',
        marginLeft: '12px',
        paddingLeft: '22px',
        paddingRight: '12px',
        marginRight: '10px',
    },
    menuProps: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: '#363b3d !important',
            color: 'white',
            backgroundPosition: 'left',
            backgroundImage: `url(${RadioButtonChecked})`,
            backgroundSize: '14px 14px',
            fontSize: '11px',
            backgroundRepeat: 'no-repeat',
            marginLeft: '12px',
            paddingLeft: '22px',
            paddingRight: '12px',
            marginRight: '10px',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#363b3d',
            borderRadius: '10px',
        },
        '& .MuiList-padding': {
            backgroundColor: '#363b3d',
            borderRadius: '10px',
        },
        '& .MuiPopover-paper': {},
    },
}))

const useWhiteStyles = makeStyles((theme) => ({
    chip: {
        backgroundColor: '#ffffff',
        marginLeft: theme.spacing(2),
        color: '#000000',
        border: 'solid 1px white',
        opacity: 1,
        fontSize: '9px',
        fontWeight: 500,
    },
    chipWithoutMenu: {
        height: '31px !important',
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        backgroundColor: 'white',
        color: 'black',
        boxShadow: '0px !important',
        '& .MuiFab-root:active': {
            boxShadow: '0px',
        },
        '&:hover': {
            backgroundColor: 'white',
        },
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                backgroundColor: '#37c0ca',
                color: '#37c0ca',
                borderColor: '#37c0ca',
            },
        },
    },
    activeChip: {
        backgroundColor: `white !important`,
        color: `#37c0ca !important`,
        border: '1px solid !important',
        borderColor: `#37c0ca !important`,
    },
    select: {
        color: 'black',
        outline: 'none',
        marginLeft: theme.spacing(2),
        border: '0px solid #ffffff',
        borderRadius: '20px',
        boxShadow: 'none',
        '&:before': {
            border: '0 !important',
        },
        '&:after': {
            border: '0 !important',
        },
    },
    selectInner: {
        color: 'black',
        outline: 'none',
        backgroundColor: 'white',
        fontSize: '9px',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontFamily: 'Roboto,Regular,Open Sans,sans-serif',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        border: 'solid 1px white',
        borderRadius: '20px',
        boxShadow:
            '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        '&:focus': {
            backgroundColor: 'white',
            color: 'black',
            borderRadius: '20px',
            border: 'solid 0.5px white',
        },
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
            border: 'solid 0.5px white',
        },
    },
    selectMenu: {
        '& option': {
            padding: theme.spacing(1),
        },
    },
    noElevation: {
        boxShadow: '0px 3px 5px -1px transparent, 0px 6px 10px 0px transparent, 0px 1px 18px 0px transparent',

        '&:focus': {
            border: 'solid 0.5px white',
        },
    },
    noElevationActive: {
        boxShadow: '0px 3px 5px -1px transparent, 0px 6px 10px 0px transparent, 0px 1px 18px 0px transparent',
    },
    icon: {
        color: 'black',
        top: 'calc(50% - 13px);',
    },
    empty: {},
    menuItem: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: 'white !important',
        },
        '& .MuiPaper-root': {
            backgroundColor: 'black',
        },

        background: '#1b1f21',
        color: 'white',
        backgroundPosition: 'left',
        backgroundImage: `url(${RadioButtonUnchecked})`,
        backgroundSize: '14px 14px',
        backgroundRepeat: 'no-repeat',
        fontSize: '11px',
        marginLeft: '12px',
        paddingLeft: '22px',
        paddingRight: '12px',
        marginRight: '10px',
    },
    menuProps: {
        '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: '#1b1f21 !important',
            color: 'white',
            backgroundPosition: 'left',
            backgroundImage: `url(${RadioButtonChecked})`,
            backgroundSize: '14px 14px',
            backgroundRepeat: 'no-repeat',
            fontSize: '11px',
            marginLeft: '12px',
            paddingLeft: '22px',
            paddingRight: '12px',
            marginRight: '10px',
        },
        '& .MuiPaper-root': {
            backgroundColor: '#1b1f21',
            borderRadius: '10px',
        },
        '& .MuiList-padding': {
            backgroundColor: '#1b1f21',
            borderRadius: '10px',
        },
        '& .MuiPopover-paper': {},
    },
}))

interface MultiPickerProps {
    active: boolean
    elevate: boolean
    label: string
    value: number
    items: Array<{ id: number; label: string }>
    useWhiteStyle?: boolean
    onChange: (event: any) => void
}
export const MultiPicker: React.FC<MultiPickerProps> = ({
    active,
    elevate,
    label,
    value,
    items,
    useWhiteStyle,
    onChange,
}) => {
    const whiteStyle = useWhiteStyles()
    const blackStyle = useBlackStyles()
    const classes = useWhiteStyle ? whiteStyle : blackStyle
    const renderFunc = (value: number, valueLabel: string, selectLabel: string) => (
        <div>{value === 0 ? selectLabel : valueLabel}</div>
    )

    return (
        <FormControl variant="outlined">
            <Select
                className={classes.select}
                classes={{
                    root: active
                        ? `${classes.activeChip} ${classes.selectInner} ${elevate ? '' : classes.noElevationActive}`
                        : `${classes.selectInner} ${elevate ? '' : classes.noElevation}`,
                    icon: `${classes.icon}`,
                }}
                input={<Input classes={{ focused: classes.empty }} />}
                value={value}
                onChange={onChange}
                renderValue={(value: any) => renderFunc(value, items[value].label, label)}
                MenuProps={{
                    className: classes.menuProps,
                }}
            >
                {items.map((menuItem, i) => (
                    <MenuItem
                        key={menuItem.id}
                        value={menuItem.id}
                        className={classes.menuItem}
                        style={i !== 0 ? { borderTop: '1px solid #484e51' } : {}}
                    >
                        {menuItem.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

interface SinglePickerProps {
    active: boolean
    elevate: boolean
    label: string
    useWhiteStyle?: boolean
    onClick: (event: any) => void
}
export const SinglePicker: React.FC<SinglePickerProps> = ({ active, elevate, label, useWhiteStyle, onClick }) => {
    const whiteStyle = useWhiteStyles()
    const blackStyle = useBlackStyles()
    const classes = useWhiteStyle ? whiteStyle : blackStyle

    return (
        <Fab
            size="medium"
            variant="extended"
            onClick={onClick}
            className={
                active
                    ? `${classes.chip} ${classes.chipWithoutMenu} ${classes.activeChip} ${
                          elevate ? '' : classes.noElevationActive
                      }`
                    : `${classes.chip} ${classes.chipWithoutMenu} ${elevate ? '' : classes.noElevation}`
            }
        >
            {label}
        </Fab>
    )
}
