import {
    asyncPagedActionCreator,
    asyncActionCreator,
    valueHolderActionCreator,
} from './../../shared/redux/actions/common'
import {
    FETCH_LIST_LOCATIONS, FETCH_MAP_LOCATIONS, FETCH_LOCATION_DETAIL, MAP_TOKEN, UPDATE_LOCATION_INFO,
    /*UPDATE_CHARGING_SESSION_LOCATION_DETAIL,*/ UPDATE_CONNECTOR_STATUS_LOCATION_DETAIL
} from '../const'
import { getJson } from '../../shared/utils/api'
import { ChargerConnectorSocketType } from '../../shared/types/charger'
import { Coordinates } from '../../shared/types/common'

export const fetchListLocations = (
    key: any,
    state: any,
    pageNumber?: number,
    filter?: {
        search?: string
        availability?: boolean
        distance?: { km?: number; location: Coordinates }
        connector?: ChargerConnectorSocketType
        showHiddenLocation?: boolean
        sortByDistance?: boolean,
        tenantId?: string
    }
) => {
    const sort = filter?.sortByDistance ? 'distanceInMeters asc' : 'name asc'
    let url = `locations?${buildQuery(filter)}`

    return asyncPagedActionCreator(
        FETCH_LIST_LOCATIONS,
        (pageIndex, pageSize) => getJson(url + `page=${pageIndex}&pageSize=${pageSize}&sort=${sort}`),
        key,
        state,
        pageNumber
    )
}

/*export const updateChargingSessionLocationDetail = (locationInfo: any) => ({
    type: UPDATE_CHARGING_SESSION_LOCATION_DETAIL,
    payload: {
        locationInfo: locationInfo,
    },
})*/

export const updateConnectorStatusLocationDetail = (locationInfo: any) => ({
    type: UPDATE_CONNECTOR_STATUS_LOCATION_DETAIL,
    payload: {
        locationInfo: locationInfo,
    },
})

export const updateLocationInfo = (locationInfo: any) => ({
    type: UPDATE_LOCATION_INFO,
    payload: {
        locationInfo: locationInfo,
    },
})

export const fetchMapLocations = (filter?: {
    search?: string
    availability?: boolean
    distance?: { km?: number; location: Coordinates }
    connector?: ChargerConnectorSocketType
    showHiddenLocation?: boolean,
    tenantId?: string,
}) => {
    let url = `locations/all?${buildQuery(filter)}`

    return asyncActionCreator(FETCH_MAP_LOCATIONS, () => getJson(url))
}

export const fetchLocationDetail = (locationId: string) => {
    return asyncActionCreator(FETCH_LOCATION_DETAIL, () => getJson(`locations/${locationId}`))
}

export const setMapToken = (token: string) => {
    return valueHolderActionCreator(MAP_TOKEN, token)
}


const buildQuery = (filter?: {
    search?: string
    availability?: boolean
    distance?: { km?: number; location: Coordinates }
    connector?: ChargerConnectorSocketType
    showHiddenLocation?: boolean,
    tenantId?: string,
}) => {
    let query = ''
    if (filter) {
        if (filter.search) {
            query += `searchQuery=${filter.search}&`
        }
        if (filter.tenantId) {
            query += `tenantId=${filter.tenantId}&`
        }
        if (filter.distance?.km) {
            query += `distanceInMeters=${filter.distance.km * 1000}&`
        }
        if (filter.distance?.location) {
            query += `latitude=${filter.distance.location.lat
                }&longitude=${filter.distance.location.lng}&`
        }
        if (filter.connector != null) {
            query += `socketType=${filter.connector}&`
        }

        query += `onlyAvailable=${filter.availability}&`
        if (filter.showHiddenLocation === undefined) {
            filter.showHiddenLocation = false
        }
        query += `showHidden=${filter.showHiddenLocation}&`
    }
    return query
}
