import { makeStyles } from '@material-ui/core'

const loginStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    wrapperMiddle: {
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    wrapperBottomBackround: {
        background: "rgba(55, 191, 201, 0.46)"
    },
    wrapperBottom: {
        borderTopLeftRadius: '14px',
        borderTopRightRadius: '14px',
        background: "black"
    },
    wrapper: {
        zoom: "0.75",
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
    },
    wrapperInnerTop: {
        maxWidth: '400px',
        paddingBottom: '0px',
        padding: '23px',
        margin: 'auto',
        color: 'white',
    },
    wrapperInnerMiddle: {
        maxWidth: '400px',
        paddingTop: '0px',
        margin: 'auto',
        color: 'white',
    },
    wrapperInnerBottom: {
        borderRadius: '14px',
        maxWidth: '400px',
        paddingTop: '0px',
        padding: '60px',
        margin: 'auto',
        color: 'white',
    },
    singInButtonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    singInButtonContainer: {
        marginBottom: '19px',
        marginLeft: '37px',
        marginRight: '37px',
    },
    singInButton: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        borderRadius: '14px',
    },
    resendEmailContainer: {
        marginBottom: '19px',
        marginLeft: '37px',
        marginRight: '37px',
    },
    resendEmail: {
        height: '60px',
        borderRadius: '14px',
        background: 'white',
    },
    resendEmailText: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'black',
        fontFamily: "'Mulish', sans-serif",
    },
    languagePart: {
        paddingBottom: theme.spacing(3),
    },
    languageDiv: {
        
    },
    signInWith: {
        marginTop: "24px",
        marginBottom: "20px"
    },
    headerRight: {
        position: 'absolute',
        right: '0px',
        top: '0px',
    },
    logo: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    titleContainer: {
        marginBottom: '57px',
        textAlign: 'center',
    },
    login: {
        marginBottom: '26px',
    },
    password: {
        marginBottom: '30px',
    },
    forgetPassword: {
        marginBottom: '91px',
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
    socialLogins: {
        paddingBottom: "12px"
    },
    anonymousLogin: {
        fontFamily: "'Mulish', sans-serif",
        '& .MuiTypography-caption': {
            padding: theme.spacing(1, 0, 1, 0),
            fontFamily: "'Mulish', sans-serif",
        },
    },
    anonymousLoginButton: {
        padding: "6px",
        justifyContent: 'middle',
        color: 'black',
        textTransform: 'uppercase',
        backgroundColor: '#ffffff',
        '& .MuiButton-startIcon': {
            paddingRight: '10px',
        },
        height: '60px',
        borderRadius: '14px',
        fontFamily: "'Mulish', sans-serif",
        fontSize: '18px',
        fontWeight: 'bold',
    },
    googleButton: {
        textTransform: "uppercase",
        color: 'black !important',
        width: '100%',
        height: '60px',
        borderRadius: '14px !important',
        fontFamily: "'Mulish', sans-serif !important",
        fontWeight: 'bold',
    },
    googleButton2: {
        textTransform: "uppercase",
        color: 'black !important',
        width: '100%',
        height: '60px',
        borderRadius: '14px !important',
        fontFamily: "'Mulish', sans-serif !important",
        fontSize: '18px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        border: 'none',
    },
    facebookButton: {
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100%',
        padding: '10px',
        paddingLeft: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
        border: '1px solid transparent',
        borderRadius: '2px',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "'Mulish', sans-serif !important",
        '& svg': {
            color: '#4267b2',
            marginRight: '15px',
        },
        '&:hover': {
            opacity: 0.9,
        },
    },
    register: {
        marginBottom: '9px',
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
    input: {
        paddingLeft: '5px',
        marginInlineStart: '-22px',
        color: 'white',
        background: 'black',
        paddingRight: '5px',
        paddingTop: '2px',
        fontSize: '14px',
        border: '0px !important',
        fontFamily: "'Mulish', sans-serif",
        WebkitAppearance : 'none'
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid rgba(255, 133, 51, 0.42)',
        },
        '&:after': {
            borderBottom: `2px solid ${theme.palette.secondary.main}`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid ${theme.palette.secondary.main}`,
        },
    },
    terms: {
        paddingTop: theme.spacing(3),
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
    inputIconRight: {
        color: 'white',
        opacity: '0.3',
        marginBottom: '14px',
        marginTop:"20px",
        height: '17px',
    },
    inputIconLeft: {
        color: '#37c0ca',
        marginBottom: '14px',
        marginTop: '20px',
        marginLeft: '8px',
        height: '17px',
    },
    arrowBackIcon: {
        color: 'white',
        width: '16px',
        marginLeft: '5px',
    },
    backButton: {
        width: '48px',
        minWidth: '48px',
        height: '60px',
        borderRadius: '14px',
        backgroundImage: 'linear-gradient(141deg, #3a4245, #1b1f21 100%)',
    },
    corner: {
        "--resize": "6",
        "--color": "lime",
        position: "absolute",
        top: "calc(0px - var(--size) / 2)",
        ".item": { display: "none" }
    },
}))

export default loginStyles
