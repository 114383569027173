import React, { useState, useEffect } from 'react'
import { Grid, Input, useScrollTrigger, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import ChipsBar from '../chipsBar/ChipsBar'
import { setSearch } from '../../redux/actions/filter'
import { useDispatch, useSelector } from 'react-redux'
import MyLocation from '@material-ui/icons/MyLocation'
import Search from '@material-ui/icons/Search'

const useBaseStyles = makeStyles((theme) => ({
    searchPannelContainer: {
        marginRight: '23px',
        marginLeft: '23px',
        zoom: '0.75',
    },
    wrapper: {},
    wrapperInner: {},
    searchWrapper: {
        marginRight: '13px',
        width: 'calc(100% - 65px)',
    },
    searchInputContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    searchIconRightContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        margin: '10px',
    },
    searchIconRight: {
        height: '20px',
        marginLeft: '10px',
        marginRight: '10px',
    },
    searchIconLeftContainer: {
        display: 'flex',
        width: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '10px',
    },
    searchIconLeft: {
        marginLeft: '10px',
        marginRight: '10px',
        height: '20px',
        color: '#37c0ca',
    },
    searchSettingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchSetting: {
        backgroundImage: 'linear-gradient(143deg, #3a4245, #1b1f21)',
        height: '60px',
        width: '46px',
        minWidth: '46px',
        borderRadius: '14px',
    },
    searchSettingIcon: {
        margin: 'auto',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchSettingIconActive: {
        margin: 'auto',
        height: '25px',
        width: '25px',
        color: '#37c0ca',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))
const useBlackStyles = makeStyles((theme) => ({
    searchPannelWrapper: {
        left: '0',
        right: '0',
        margin: 'auto',
        height: '95px',
        position: 'relative',
        width: '100%',
        top: '23px',
        backgroundColor: 'transparent',
        zIndex: 2,
    },
    searchContainer: {
        height: '100%',
        borderRadius: '14px',
        border: 'solid 1px #565b5c',
        backgroundColor: 'black',
        justifyContent: 'space-around',
        flexWrap: 'nowrap',
    },
    searchInput: {
        width: '100%',
        color: 'white',
    },
}))

const useWhiteStyles = makeStyles((theme) => ({
    searchPannelWrapper: {
        left: '0',
        right: '0',
        margin: 'auto',
        height: '60px',
        position: 'absolute',
        top: '23px',
        backgroundColor: 'transparent',
        zIndex: 2,
    },
    searchContainer: {
        height: '100%',
        borderRadius: '14px',
        boxShadow: '0 10px 16px 0 rgba(0, 0, 0, 0.19)',
        backgroundColor: 'white',
        justifyContent: 'space-around',
        flexWrap: 'nowrap',
    },
    searchInput: {
        width: '100%',
        color: 'black',
    },
}))

const debouncedAction = _.debounce((action: any) => {
    action()
}, 250)

interface SearchBarProps {
    float: Boolean
    elevationScroll?: Boolean
    scrollRef?: any
    fullWidthChipsBar: boolean
    moveDown?: Boolean
    blackStyle: Boolean
}
const SearchBar: React.FC<SearchBarProps> = ({
    float,
    elevationScroll,
    scrollRef,
    fullWidthChipsBar,
    moveDown,
    blackStyle,
}) => {
    const classes = useBaseStyles()
    const blackClasses = useBlackStyles()
    const whiteClasses = useWhiteStyles()
    const colorClasses = blackStyle ? blackClasses : whiteClasses
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const search = useSelector((state: any) => state.filter.search.value)
    const filterMaxDistance = useSelector((state: any) => state.filter.distance.value.km)
    const filterAvilableOnly = useSelector((state: any) => state.filter.availability.value)
    const filterConnector = useSelector((state: any) => state.filter.connector.value)

    const [searchRaw, setSearchRaw] = useState<string>(search ?? '')

    const [showFilter, setShowFilter] = useState<boolean>(false)

    const changeShowFilter = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (filterAvilableOnly || filterMaxDistance || filterConnector) {
            setShowFilter(true)
        }
    }, [filterAvilableOnly, filterMaxDistance, filterConnector])

    const searchSetting = () => {
        return (
            <>
                <path
                    id="k8h9mabz0a"
                    d="M6 3c.513 0 .936.386.993.883L7 4v9.171c1.166.412 2 1.523 2 2.829 0 1.306-.834 2.417-2 2.829V20c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L5 20v-1.17c-1.165-.412-2-1.524-2-2.83s.835-2.418 2-2.83V4c0-.552.448-1 1-1zm6 0c.513 0 .936.386.993.883L13 4v1.171c1.166.412 2 1.523 2 2.829 0 1.306-.834 2.417-2 2.829V20c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L11 20v-9.17C9.835 10.417 9 9.305 9 8s.835-2.418 2-2.83V4c0-.552.448-1 1-1zm6 0c.513 0 .936.386.993.883L19 4v9.171c1.166.412 2 1.523 2 2.829 0 1.306-.834 2.417-2 2.829V20c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L17 20v-1.17c-1.165-.412-2-1.524-2-2.83s.835-2.418 2-2.83V4c0-.552.448-1 1-1zM6 15c-.552 0-1 .448-1 1 0 .513.386.936.883.993L6 17c.552 0 1-.448 1-1 0-.513-.386-.936-.883-.993L6 15zm12 0c-.552 0-1 .448-1 1 0 .513.386.936.883.993L18 17c.552 0 1-.448 1-1 0-.513-.386-.936-.883-.993L18 15zm-6-8c-.552 0-1 .448-1 1 0 .513.386.936.883.993L12 9c.552 0 1-.448 1-1 0-.513-.386-.936-.883-.993L12 7z"
                />
            </>
        )
    }

    const trigger = useScrollTrigger({
        threshold: 0,
        disableHysteresis: true,
    })

    return (
        <div
            className={colorClasses.searchPannelWrapper}
            style={showFilter && blackStyle ? { height: '120px' } : { height: '75px' }}
        >
            <Grid item xs={12} className={classes.searchPannelContainer}>
                <Grid item xs={12} container justify="center" className={classes.wrapper}>
                    <Grid item xs={12} container justify="center" className={classes.wrapperInner}>
                        <Grid item className={classes.searchWrapper}>
                            <Grid item xs={12} container justify="center" className={colorClasses.searchContainer}>
                                <Grid item className={classes.searchIconLeftContainer}>
                                    <Search className={classes.searchIconLeft} />
                                </Grid>
                                <Grid item className={classes.searchInputContainer}>
                                    <Input
                                        placeholder={t('common.searchPlaceholder')}
                                        disableUnderline={true}
                                        className={colorClasses.searchInput}
                                        value={searchRaw}
                                        onChange={(x) => {
                                            const newValue = x.target.value
                                            setSearchRaw(newValue)
                                            debouncedAction(() => dispatch(setSearch(newValue)))
                                        }}
                                    />
                                </Grid>
                                <Grid item className={classes.searchIconRightContainer}>
                                    <MyLocation className={classes.searchIconRight} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.searchSettingWrapper}>
                            <Button className={classes.searchSetting} onClick={changeShowFilter}>
                                <svg
                                    fill={showFilter ? '#37c0ca' : 'white'}
                                    className={classes.searchSettingIconActive}
                                >
                                    {searchSetting()}
                                </svg>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {showFilter ? (
                <ChipsBar
                    elevate={!(elevationScroll && trigger)}
                    fullWidth={fullWidthChipsBar}
                    useWhiteStyle={!blackStyle}
                />
            ) : (
                ''
            )}
        </div>
    )
}

export default SearchBar
