import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#6100ED",
      light: "#e8e0fb",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EBEBEB",
      dark: "#757575",
    },
    action: {
      selected: "#F2F2F2",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Open Sans", "sans-serif"].join(","),
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "#43425D",
      },
      h2: {
        fontSize: "29px",
        fontWeight: "bold",
        color: "#4D4F5C",
      },
      h3: {
        fontSize: "22px",
        color: "#43425D",
      },
      h4: {
        fontSize: "20px",
        color: "#000000DE",
      },
      h5: {
        fontSize: "24px",
        color: "#000000",
      },
      h6: {
        fontSize: "18px",
        color: "#000000",
      },
      subtitle2: {
        fontSize: "14px",
        color: "#000000",
      },
      caption: {
        fontSize: "14px",
        color: "#757575",
      },
    },
    MuiLink: {
      button: {
        fontSize: "14px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "14px",
        color: "#4D4F5C;",
      },
    },
  },
});
