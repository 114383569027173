import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import store from './redux/store'
import './App.scss'
import { CircularProgress, ThemeProvider } from '@material-ui/core'
import AppRouter from './AppRouter'
import ErrorHandler from './core/errorHandler/ErrorHandler'
import { theme } from './shared/theme/theme'

const useStyles = makeStyles((theme) => ({
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}))

export default function App() {
    const classes = useStyles()
    return (
        <Suspense fallback={<CircularProgress className={classes.loader} />}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <div className="App">
                        <CssBaseline />
                        <AppRouter />
                        <ErrorHandler />
                    </div>
                </Provider>
            </ThemeProvider>
        </Suspense>
    )
}
