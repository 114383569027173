import React from 'react'
import { Grid } from '@material-ui/core'
import { Autocomplete } from 'mui-rff'
import { TextField } from 'mui-rff'
// import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import RadioButtonUnchecked from '../../static/icons/radio_button_unchecked_blue.svg'
import RadioButtonChecked from '../../static/icons/radio_button_checked_blue.svg'

const useStyles = makeStyles((theme) => ({
    input: {
        paddingLeft: '5px',
        marginInlineStart: '-22px',
        color: 'white',
        background: 'black',
        paddingRight: '5px',
        paddingTop: '2px',
        fontSize: '14px',
        border: '0px !important',
        fontFamily: "'Mulish', sans-serif",
        WebkitAppearance: 'none',
        borderRadius: '20px',
    },
    inputIconRight: {
        marginLeft: '3%',
        color: 'white',
        opacity: '0.3',
        marginBottom: '14px',
        marginTop: '20px',
        height: '17px',
    },
    wrapper: {
        height: '49px',
        borderRadius: '10.5px',
        border: 'solid 1px #565b5c',
        background: 'black',
    },
    inputTextField: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            // Default left padding is 6px
            color: 'white',
            marginInlineStart: '-19px',
            fontSize: '10px',
        },
        '&& .MuiInput-root:hover::before': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiInput-root:hover::after': {
            border: 'none',
            color: 'white !important',
        },
        '&& .MuiFormLabel-root.Mui-focused': {
            color: 'white',
            border: 'none',
        },
        '& label.Mui-focused': {
            border: 'none',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiAutocomplete-clearIndicator': {
            color: 'white',
        },
        '& .MuiAutocomplete-popupIndicator': {
            color: 'white',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            marginTop: '-1px',
            paddingTop: '2px',
            color: '#00e3e3 !important',
            background: 'black',
            marginInlineStart: '-22px',
            paddingLeft: '3px',
            paddingRight: '3px',
            fontSize: '10.5px',
            border: '0px !important',
            fontFamily: "'Mulish', sans-serif",
        },
        '& .MuiInputLabel-outlined': {
            color: 'white',
            marginInlineStart: '-19px',
            fontSize: '10px',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            marginRight: '0px',
            marginTop: '46px',
            paddingTop: '3px',
            fontSize: '7px',
            position: 'absolute',
            marginLeft: '-5px',
        },
        '& .MuiOutlinedInput-root': {
            height: '46px',
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            '.MuiAutocomplete-inputFocused': {
                color: 'white',
            },
            '&.MuiInputLabel-outlined': {
                background: 'black',
                paddingRight: '10px',
            },
            '&.MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                padding: '6px',
            },
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        '& input:-internal-autofill-focused': {
            WebkitBoxShadow: '0 0 0 50px black inset',
            WebkitTextFillColor: 'white',
            background: 'black',
        },
        color: 'white',
        WebkitBoxShadow: '0 0 0 50px black inset',
        WebkitAppearance: 'none',
        borderRadius: '20px',
    },
    paper: {
        borderRadius: '10px',
        backgroundColor: '#363b3d',
    },
    option: {
        fontSize: '10px',
        maxHeight: '30px',
        minHeight: '30px',
        borderBottom: '1px solid #484e51',
        background: '#363b3d',
        color: 'white',
        backgroundPosition: 'left',
        backgroundImage: `url(${RadioButtonUnchecked})`,
        backgroundSize: '14px 14px',
        backgroundRepeat: 'no-repeat',
        marginLeft: '10px',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginRight: '10px',
        '& .MuiAutocomplete-option[data-focus="true"]': {
            backgroundColor: 'blue',
        },
        '&:hover': {
            fontSize: '10px',
            background: '#363b3d',
            backgroundImage: `url(${RadioButtonUnchecked})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '14px 14px',
            backgroundPosition: 'left',
        },
    },
    '@global': {
        '.MuiAutocomplete-option[data-focus="true"]': {
            fontSize: '10px',
            maxHeight: '30px',
            minHeight: '30px',
            background: '#363b3d',
            color: 'white',
            backgroundPosition: 'left',
            backgroundImage: `url(${RadioButtonChecked})`,
            backgroundSize: '14px 14px',
            backgroundRepeat: 'no-repeat',
            marginLeft: '10px',
            paddingLeft: '25px',
            paddingRight: '25px',
            marginRight: '10px',
        },
        '.MuiAutocomplete-popper': {
            width: '85% !important',
            maxWidth: '350px',
            left: '0 !important',
            right: '0 !important',
            margin: '0 23px 0 23px !important',
        },
    },
}))

export type Country = {
    label: string
    code: string
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
    { code: 'AD', label: 'Andorra' },
    { code: 'AE', label: 'United Arab Emirates' },
    { code: 'AF', label: 'Afghanistan' },
    { code: 'AG', label: 'Antigua and Barbuda' },
    { code: 'AI', label: 'Anguilla' },
    { code: 'AL', label: 'Albania' },
    { code: 'AM', label: 'Armenia' },
    { code: 'AO', label: 'Angola' },
    { code: 'AQ', label: 'Antarctica' },
    { code: 'AR', label: 'Argentina' },
    { code: 'AS', label: 'American Samoa' },
    { code: 'AT', label: 'Austria' },
    { code: 'AU', label: 'Australia' },
    { code: 'AW', label: 'Aruba' },
    { code: 'AX', label: 'Alland Islands' },
    { code: 'AZ', label: 'Azerbaijan' },
    { code: 'BA', label: 'Bosnia and Herzegovina' },
    { code: 'BB', label: 'Barbados' },
    { code: 'BD', label: 'Bangladesh' },
    { code: 'BE', label: 'Belgium' },
    { code: 'BF', label: 'Burkina Faso' },
    { code: 'BG', label: 'Bulgaria' },
    { code: 'BH', label: 'Bahrain' },
    { code: 'BI', label: 'Burundi' },
    { code: 'BJ', label: 'Benin' },
    { code: 'BL', label: 'Saint Barthelemy' },
    { code: 'BM', label: 'Bermuda' },
    { code: 'BN', label: 'Brunei Darussalam' },
    { code: 'BO', label: 'Bolivia' },
    { code: 'BR', label: 'Brazil' },
    { code: 'BS', label: 'Bahamas' },
    { code: 'BT', label: 'Bhutan' },
    { code: 'BV', label: 'Bouvet Island' },
    { code: 'BW', label: 'Botswana' },
    { code: 'BY', label: 'Belarus' },
    { code: 'BZ', label: 'Belize' },
    { code: 'CA', label: 'Canada' },
    { code: 'CC', label: 'Cocos (Keeling) Islands' },
    { code: 'CD', label: 'Congo, Democratic Republic of the' },
    { code: 'CF', label: 'Central African Republic' },
    { code: 'CG', label: 'Congo, Republic of the' },
    { code: 'CH', label: 'Switzerland' },
    { code: 'CI', label: "Cote d'Ivoire" },
    { code: 'CK', label: 'Cook Islands' },
    { code: 'CL', label: 'Chile' },
    { code: 'CM', label: 'Cameroon' },
    { code: 'CN', label: 'China' },
    { code: 'CO', label: 'Colombia' },
    { code: 'CR', label: 'Costa Rica' },
    { code: 'CU', label: 'Cuba' },
    { code: 'CV', label: 'Cape Verde' },
    { code: 'CW', label: 'Curacao' },
    { code: 'CX', label: 'Christmas Island' },
    { code: 'CY', label: 'Cyprus' },
    { code: 'CZ', label: 'Czech Republic' },
    { code: 'DE', label: 'Germany' },
    { code: 'DJ', label: 'Djibouti' },
    { code: 'DK', label: 'Denmark' },
    { code: 'DM', label: 'Dominica' },
    { code: 'DO', label: 'Dominican Republic' },
    { code: 'DZ', label: 'Algeria' },
    { code: 'EC', label: 'Ecuador' },
    { code: 'EE', label: 'Estonia' },
    { code: 'EG', label: 'Egypt' },
    { code: 'EH', label: 'Western Sahara' },
    { code: 'ER', label: 'Eritrea' },
    { code: 'ES', label: 'Spain' },
    { code: 'ET', label: 'Ethiopia' },
    { code: 'FI', label: 'Finland' },
    { code: 'FJ', label: 'Fiji' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)' },
    { code: 'FM', label: 'Micronesia, Federated States of' },
    { code: 'FO', label: 'Faroe Islands' },
    { code: 'FR', label: 'France' },
    { code: 'GA', label: 'Gabon' },
    { code: 'GB', label: 'United Kingdom' },
    { code: 'GD', label: 'Grenada' },
    { code: 'GE', label: 'Georgia' },
    { code: 'GF', label: 'French Guiana' },
    { code: 'GG', label: 'Guernsey' },
    { code: 'GH', label: 'Ghana' },
    { code: 'GI', label: 'Gibraltar' },
    { code: 'GL', label: 'Greenland' },
    { code: 'GM', label: 'Gambia' },
    { code: 'GN', label: 'Guinea' },
    { code: 'GP', label: 'Guadeloupe' },
    { code: 'GQ', label: 'Equatorial Guinea' },
    { code: 'GR', label: 'Greece' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { code: 'GT', label: 'Guatemala' },
    { code: 'GU', label: 'Guam' },
    { code: 'GW', label: 'Guinea-Bissau' },
    { code: 'GY', label: 'Guyana' },
    { code: 'HK', label: 'Hong Kong' },
    { code: 'HM', label: 'Heard Island and McDonald Islands' },
    { code: 'HN', label: 'Honduras' },
    { code: 'HR', label: 'Croatia' },
    { code: 'HT', label: 'Haiti' },
    { code: 'HU', label: 'Hungary' },
    { code: 'ID', label: 'Indonesia' },
    { code: 'IE', label: 'Ireland' },
    { code: 'IL', label: 'Israel' },
    { code: 'IM', label: 'Isle of Man' },
    { code: 'IN', label: 'India' },
    { code: 'IO', label: 'British Indian Ocean Territory' },
    { code: 'IQ', label: 'Iraq' },
    { code: 'IR', label: 'Iran, Islamic Republic of' },
    { code: 'IS', label: 'Iceland' },
    { code: 'IT', label: 'Italy' },
    { code: 'JE', label: 'Jersey' },
    { code: 'JM', label: 'Jamaica' },
    { code: 'JO', label: 'Jordan' },
    { code: 'JP', label: 'Japan' },
    { code: 'KE', label: 'Kenya' },
    { code: 'KG', label: 'Kyrgyzstan' },
    { code: 'KH', label: 'Cambodia' },
    { code: 'KI', label: 'Kiribati' },
    { code: 'KM', label: 'Comoros' },
    { code: 'KN', label: 'Saint Kitts and Nevis' },
    { code: 'KP', label: "Korea, Democratic People's Republic of" },
    { code: 'KR', label: 'Korea, Republic of' },
    { code: 'KW', label: 'Kuwait' },
    { code: 'KY', label: 'Cayman Islands' },
    { code: 'KZ', label: 'Kazakhstan' },
    { code: 'LA', label: "Lao People's Democratic Republic" },
    { code: 'LB', label: 'Lebanon' },
    { code: 'LC', label: 'Saint Lucia' },
    { code: 'LI', label: 'Liechtenstein' },
    { code: 'LK', label: 'Sri Lanka' },
    { code: 'LR', label: 'Liberia' },
    { code: 'LS', label: 'Lesotho' },
    { code: 'LT', label: 'Lithuania' },
    { code: 'LU', label: 'Luxembourg' },
    { code: 'LV', label: 'Latvia' },
    { code: 'LY', label: 'Libya' },
    { code: 'MA', label: 'Morocco' },
    { code: 'MC', label: 'Monaco' },
    { code: 'MD', label: 'Moldova, Republic of' },
    { code: 'ME', label: 'Montenegro' },
    { code: 'MF', label: 'Saint Martin (French part)' },
    { code: 'MG', label: 'Madagascar' },
    { code: 'MH', label: 'Marshall Islands' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
    { code: 'ML', label: 'Mali' },
    { code: 'MM', label: 'Myanmar' },
    { code: 'MN', label: 'Mongolia' },
    { code: 'MO', label: 'Macao' },
    { code: 'MP', label: 'Northern Mariana Islands' },
    { code: 'MQ', label: 'Martinique' },
    { code: 'MR', label: 'Mauritania' },
    { code: 'MS', label: 'Montserrat' },
    { code: 'MT', label: 'Malta' },
    { code: 'MU', label: 'Mauritius' },
    { code: 'MV', label: 'Maldives' },
    { code: 'MW', label: 'Malawi' },
    { code: 'MX', label: 'Mexico' },
    { code: 'MY', label: 'Malaysia' },
    { code: 'MZ', label: 'Mozambique' },
    { code: 'NA', label: 'Namibia' },
    { code: 'NC', label: 'New Caledonia' },
    { code: 'NE', label: 'Niger' },
    { code: 'NF', label: 'Norfolk Island' },
    { code: 'NG', label: 'Nigeria' },
    { code: 'NI', label: 'Nicaragua' },
    { code: 'NL', label: 'Netherlands' },
    { code: 'NO', label: 'Norway' },
    { code: 'NP', label: 'Nepal' },
    { code: 'NR', label: 'Nauru' },
    { code: 'NU', label: 'Niue' },
    { code: 'NZ', label: 'New Zealand' },
    { code: 'OM', label: 'Oman' },
    { code: 'PA', label: 'Panama' },
    { code: 'PE', label: 'Peru' },
    { code: 'PF', label: 'French Polynesia' },
    { code: 'PG', label: 'Papua New Guinea' },
    { code: 'PH', label: 'Philippines' },
    { code: 'PK', label: 'Pakistan' },
    { code: 'PL', label: 'Poland' },
    { code: 'PM', label: 'Saint Pierre and Miquelon' },
    { code: 'PN', label: 'Pitcairn' },
    { code: 'PR', label: 'Puerto Rico' },
    { code: 'PS', label: 'Palestine, State of' },
    { code: 'PT', label: 'Portugal' },
    { code: 'PW', label: 'Palau' },
    { code: 'PY', label: 'Paraguay' },
    { code: 'QA', label: 'Qatar' },
    { code: 'RE', label: 'Reunion' },
    { code: 'RO', label: 'Romania' },
    { code: 'RS', label: 'Serbia' },
    { code: 'RU', label: 'Russian Federation' },
    { code: 'RW', label: 'Rwanda' },
    { code: 'SA', label: 'Saudi Arabia' },
    { code: 'SB', label: 'Solomon Islands' },
    { code: 'SC', label: 'Seychelles' },
    { code: 'SD', label: 'Sudan' },
    { code: 'SE', label: 'Sweden' },
    { code: 'SG', label: 'Singapore' },
    { code: 'SH', label: 'Saint Helena' },
    { code: 'SI', label: 'Slovenia' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen' },
    { code: 'SK', label: 'Slovakia' },
    { code: 'SL', label: 'Sierra Leone' },
    { code: 'SM', label: 'San Marino' },
    { code: 'SN', label: 'Senegal' },
    { code: 'SO', label: 'Somalia' },
    { code: 'SR', label: 'Suriname' },
    { code: 'SS', label: 'South Sudan' },
    { code: 'ST', label: 'Sao Tome and Principe' },
    { code: 'SV', label: 'El Salvador' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)' },
    { code: 'SY', label: 'Syrian Arab Republic' },
    { code: 'SZ', label: 'Swaziland' },
    { code: 'TC', label: 'Turks and Caicos Islands' },
    { code: 'TD', label: 'Chad' },
    { code: 'TF', label: 'French Southern Territories' },
    { code: 'TG', label: 'Togo' },
    { code: 'TH', label: 'Thailand' },
    { code: 'TJ', label: 'Tajikistan' },
    { code: 'TK', label: 'Tokelau' },
    { code: 'TL', label: 'Timor-Leste' },
    { code: 'TM', label: 'Turkmenistan' },
    { code: 'TN', label: 'Tunisia' },
    { code: 'TO', label: 'Tonga' },
    { code: 'TR', label: 'Turkey' },
    { code: 'TT', label: 'Trinidad and Tobago' },
    { code: 'TV', label: 'Tuvalu' },
    { code: 'TW', label: 'Taiwan, Province of China' },
    { code: 'TZ', label: 'United Republic of Tanzania' },
    { code: 'UA', label: 'Ukraine' },
    { code: 'UG', label: 'Uganda' },
    { code: 'US', label: 'United States' },
    { code: 'UY', label: 'Uruguay' },
    { code: 'UZ', label: 'Uzbekistan' },
    { code: 'VA', label: 'Holy See (Vatican City State)' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines' },
    { code: 'VE', label: 'Venezuela' },
    { code: 'VG', label: 'British Virgin Islands' },
    { code: 'VI', label: 'US Virgin Islands' },
    { code: 'VN', label: 'Vietnam' },
    { code: 'VU', label: 'Vanuatu' },
    { code: 'WF', label: 'Wallis and Futuna' },
    { code: 'WS', label: 'Samoa' },
    { code: 'XK', label: 'Kosovo' },
    { code: 'YE', label: 'Yemen' },
    { code: 'YT', label: 'Mayotte' },
    { code: 'ZA', label: 'South Africa' },
    { code: 'ZM', label: 'Zambia' },
    { code: 'ZW', label: 'Zimbabwe' },
]

type CountrySelectProps = {
    name: string
    required: boolean
    label: string
    variant: any
    child: any
}

export function CountrySelect({ name, required, label, variant, child }: CountrySelectProps) {
    const classes = useStyles()
    // const { t } = useTranslation()
    return (
        <Grid item xs={12} className={classes.wrapper}>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item style={{ width: '46px', zIndex: 5, height: '65px' }}>
                    {child}
                </Grid>
                <Grid item style={{ width: 'calc(100% - 65px)', height: '67px' }}>
                    <Autocomplete
                        className={classes.inputTextField}
                        id="country-select-demo"
                        style={{ width: '100%' }}
                        name={name}
                        label="state"
                        options={countries}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.code}
                        classes={{
                            paper: classes.paper,
                            option: classes.option,
                        }}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.label} ({option.code})
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                className={classes.inputTextField}
                                {...params}
                                name={name}
                                required={required}
                                label={label}
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
