import React from 'react'
import { makeStyles, CircularProgress, CircularProgressProps } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    progressWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

type ProgressOverlayProps = {
    children: any
    inProgress: boolean
    progressProps?: CircularProgressProps
}
export function ProgressOverlay({ children, inProgress, progressProps }: ProgressOverlayProps) {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            {children}
            {inProgress && (
                <div className={classes.progressWrapper}>
                    <CircularProgress {...progressProps} size={24} />
                </div>
            )}
        </div>
    )
}
