import React, { SFC } from 'react'
import moment from 'moment'
import { ChargingSession } from '../../shared/types/location'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Typography, makeStyles, IconButton } from '@material-ui/core'
import { Image } from '../../shared/components/Image'
import { consumedKwh } from '../../shared/utils/display'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { exportFile } from '../../shared/utils/export'
import { config, getToken } from '../../config'
import { getOffset } from '../../shared/utils/localTime'

const useStyles = makeStyles((theme) => ({
    sessionList: {
        padding: theme.spacing(1, 2),
    },
    sessionDayTitle: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    sessionDayTitleDivider: {
        marginTop: theme.spacing(3),
    },
    sessionListItem: {
        borderRadius: '10px',
        background: 'black',
        margin: theme.spacing(1, 0, 1, 0),
        padding: theme.spacing(2),
        display: 'flex',
        width: '100%',
        backgroundImage: 'linear-gradient(to left, #3a4245, rgba(27, 31, 33, 0) 77%)',
    },
    sessionListContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    sessionListContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    sessionImageWrapper: {
        flex: '1 0 auto',
        textAlign: 'right',
        marginLeft: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    sessionImage: {
        width: '80px',
        height: '80px',
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    itemHeader: {
        fontFamily: "'Mulish', sans-serif",
        fontSize: '18px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#37c0ca',
    },
    itemText: {
        paddingTop: '5px',
        fontFamily: "'Mulish', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: 'white',
    },
}))

interface SessionCardProps {
    session: ChargingSession
}
export const SessionCard: SFC<SessionCardProps> = React.memo(({ session }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const onExport = () => {
        exportFile(config.proxy, config.api, `ChargingSession/invoice/${session.id}`, getToken(), getOffset())
    }

    const formatDuration = () => {
        const duration = moment.duration(moment(session.endedAt).diff(moment(session.startedAt)))
        return moment()
            .hours(duration.hours())
            .minutes(duration.minutes())
            .seconds(duration.seconds())
            .format('H[h] m[m] s[s]')
    }

    return (
        <Card className={classes.sessionListItem} elevation={2}>
            <div className={classes.sessionListContentWrapper}>
                <CardContent className={classes.sessionListContent}>
                    <Typography className={classes.itemHeader} variant="h6">
                        {session.location?.name}
                    </Typography>
                    <Typography component="div" variant="caption" className={classes.itemText}>
                        {t('charging.sessionStart', {
                            time: moment(session.startedAt).format('LT'),
                        })}
                    </Typography>
                    <Typography component="div" variant="caption" className={classes.itemText}>
                        {session.endedAt
                            ? t('charging.sessionDuration', {
                                  duration: formatDuration(),
                                  consumedKwh: consumedKwh(session),
                              })
                            : t('charging.sessionInProgress')}
                    </Typography>
                </CardContent>
            </div>
            <div className={classes.sessionImageWrapper}>
                {!session.hasInvoice ? (
                    ''
                ) : (
                    <IconButton className={classes.exportButton} onClick={onExport}>
                        <PictureAsPdfIcon style={{ color: '#37c0ca' }} />
                    </IconButton>
                )}
                <Image
                    className={classes.sessionImage}
                    imageClassName={classes.sessionImage}
                    url={session.location?.images?.length > 0 ? session.location?.images[0].url : undefined}
                />
            </div>
        </Card>
    )
})
