import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { config } from "../../config";

export const AppVersion = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="body2">{`${t("common.version")} ${
      config.version
    }`}</Typography>
  );
};
