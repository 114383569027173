import React from 'react'
import {
    makeStyles,
    Button,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core'
import { DialogConfirmButton } from './dialogConfirmButton'
import { NoProgress } from './noProgress'

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
}))

type AsyncActionConfirmationDialogProps = {
    open: boolean
    title: string
    content: string
    confirmLabel: string
    cancelLabel: string
    onConfirm: Function
    onCancel: Function
    inProgress: boolean
}
export function AsyncActionConfirmationDialog({
    open,
    title,
    content,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    inProgress,
}: AsyncActionConfirmationDialogProps) {
    const classes = useStyles()

    return (
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {inProgress === true ? <LinearProgress /> : <NoProgress />}
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={() => onCancel()} color="primary">
                    {cancelLabel}
                </Button>
                <DialogConfirmButton label={confirmLabel} onClick={() => onConfirm()} />
            </DialogActions>
        </Dialog>
    )
}
