import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, Snackbar, Dialog, DialogTitle } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { editUser, clearEditState, cardRegister, fetchUser } from '../../redux/actions/users'
import { RegisteredUsersOnly } from '../../core/anonymusUser/registeredUsersOnly'
import { config } from '../../config'
import { ValidationErrors } from 'final-form'
import { setRequiredErrors } from '../../utils/ui'
import { CountrySelect } from '../../shared/components/countrySelectStyled'
import { BottomControlPanel } from '../../core/footer/bottomControlPanel'
import { PulsingHeader } from '../../shared/components/pulsingHeader'
import { CustomTextField } from '../../shared/components/customInputRff'
import IdCard from '../../static/icons/icon-id-card-blue.svg'
import PhoneIcon from '@material-ui/icons/Phone'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import PlaceIcon from '@material-ui/icons/Place'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import PublicIcon from '@material-ui/icons/Public'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { removeToken } from '../../config'
import { useHistory } from 'react-router-dom'
import { logout } from '../../shared/redux/actions/common'
import { LanguageSettings } from '../../utils/languageSettings'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { useLocation } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    main: {
        background: 'black',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        fontSize: '1rem',
        alignItems: 'center',
    },
    formWrapper: {
        zIndex: 4,
        overflowY: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
    },
    formItem: {
        zoom: '0.75',
        paddingLeft: '26px',
        paddingRight: '26px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '26px',
    },
    formItemCountry: {
        paddingLeft: '19.5px',
        paddingRight: '19.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '19.5px',
    },
    termsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        paddingBottom: '15px',
    },
    terms: {
        height: '30px',
        width: '75%',
        marginTop: '15px',
        marginBottom: '5px',
        fontFamily: 'Muli',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
        '& a': {
            color: '#37c0ca',
            textDecoration: 'none',
            '&:hover': {
                color: '#37c0ca',
                textDecoration: 'underline',
            },
        },
    },
    buttonWrapper: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    autoScroll: {
        overflowY: 'auto',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    titleContainer: {
        maxHeight: '24px',
        marginBottom: '15px',
        textAlign: 'center',
    },
    buttonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    buttonContainer: {
        marginTop: '26px',
        width: '100%',
    },
    buttonBlue: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '45px',
        width: '100%',
        maxWidth: '280px',
        borderRadius: '11px',
    },
    logoutButton: {
        width: '100%',
        maxWidth: '280px',
        height: '40px',
        borderRadius: '14px',

        background: 'transparent',
    },
    logoutText: {
        color: 'white',
        fontSize: '11px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        fontFamily: "'Mulish', sans-serif",
        borderBottom: '#37c0ca solid 1px',
        borderBottomStyle: 'groove',
        padding: '2px',
    },
    inputIconLeft: {
        color: '#37c0ca',
        marginBottom: '14px',
        marginTop: '20px',
        marginLeft: '8px',
        height: '17px',
    },
    selectContainer: {
        marginBottom: '10px',
        height: '60px',
        borderRadius: '14px',
        background: 'black',
        border: 'solid 1px #565b5c',
    },
    selectIconLeftContainer: {
        zIndex: 5,
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectIconLeftContainerCountry: {
        zIndex: 5,
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputIconLeftCountry: {
        color: '#37c0ca',
        marginBottom: '15px',
        marginTop: '16px',
        marginLeft: '8px',
        height: '13px',
    },
    selectIconLeft: {
        width: '18px',
        color: '#37c0ca',
        marginLeft: '8px',
        marginRight: '8px',
    },
    bottomDecoration: {
        bottom: '0px',
        left: '0px',
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '261px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    headerConstainer: {
        marginBottom: '10px',
        marginTop: '23px',
        marginLeft: '23px',
        marginRight: '23px',
    },
    cardUpdateButton: {
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #565b5c',
        background: 'black',
        width: '100%',
    },
    cardUpdateButtonContainer: {
        zoom: '0.75',
        marginTop: '26px',
        width: '100%',
        paddingRight: '26px',
        paddingLeft: '26px',
    },
    popupNotification: {
        marginBottom: '5em',
    },
    dialog: {
        color: 'white',
        borderRadius: '10px',

        backgroundColor: 'transparent',
    },
    textDesc: {
        margin: '0px 0px 0px 0px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: 'white',
    },
    '@global': {
        '.MuiDialog-paperScrollPaper': {
            backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 1) 100%)',
            borderRadius: '16px',
        },
    },
}))

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined

type Message = {
    open: boolean
    severity: Severity
    message: string
}

export default function Settings() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: any) => state.user.login.value.user)
    const tenant = useSelector((state: any) => state.tenant.value)
    const editState = useSelector((state: any) => state.user.edit)
    const isAnonymousUser = useSelector((state: any) => state.user.login.value.isAnonymous)

    const paymentRequestId = useSelector((state: any) => state.user.login.value.user.paymentRequestId)
    const cardExpiration = useSelector((state: any) => state.user.login.value.user.cardExpiration)

    const userId = useSelector((state: any) => state.user.login.value.user.id)

    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const handleRedirectToCardRegister = () => {
        history.push('/register-card/settings')
    }

    const { search } = useLocation()

    const [message, setMessage] = useState<Message>({ open: false, severity: undefined, message: '' })

    const handleLogout = () => {
        removeToken()
        dispatch(logout())
    }

    const handleDialogState = () => {
        setOpenDialog(!openDialog)
    }

    const cardValidate = () => {
        var validationResult
        if (user.cardExpiration) {
            var currentDate = new Date()
            var expirationDate = new Date(
                String(currentDate.getFullYear()).substring(0, 2) +
                    user.cardExpiration.substring(2, 4) +
                    '-' +
                    user.cardExpiration.substring(0, 2)
            )
            if (
                currentDate.getMonth() === expirationDate.getMonth() &&
                currentDate.getFullYear() === expirationDate.getFullYear()
            ) {
                validationResult = <div style={{ color: '#ffa3a3' }}>{t('settings.cardWillExpire')}</div>
            } else if (
                currentDate.getMonth() > expirationDate.getMonth() &&
                currentDate.getFullYear() >= expirationDate.getFullYear()
            ) {
                validationResult = <div style={{ color: '#ff8888' }}>{t('settings.cardAfterExpiration')}</div>
            } else {
                validationResult = <div style={{ color: '#37c0ca' }}>{t('settings.cardValid')}</div>
            }
        } else {
            if (user.paymentRequestId) {
                validationResult = <div style={{ color: '#ff8888' }}>{t('settings.cardWaiting')}</div>
            } else {
                validationResult = <div style={{ color: '#ff8888' }}>{t('settings.cardNotAssigned')}</div>
            }
        }
        return validationResult
    }

    useEffect(() => {
        for (let i = 0; i < 3; i++) {
            setTimeout(() => {
                if (paymentRequestId && !cardExpiration) {
                    dispatch(fetchUser())
                }
            }, 3000 * (i + 1))
        }
    }, [dispatch, paymentRequestId, cardExpiration])

    useEffect(() => {
        const params = new URLSearchParams(search)
        const result = params.get('ResultCode')
        const successfulTransaction = '0'
        if (result === successfulTransaction) {
            dispatch(cardRegister(userId, params))
        }
        if (result && result !== successfulTransaction) {
            console.log('AcquirerResponseId', params.get('AcquirerResponseId'))
        }
    }, [dispatch, search, userId])

    useEffect(() => {
        if (editState.executed) {
            if (editState.error) {
                setMessage({ open: true, severity: 'error', message: t('common.unknownValidationFailure') })
            } else if (editState.value) {
                setMessage({ open: true, severity: 'success', message: t('settings.changesSaved') })
            }
        }
    }, [editState, t])

    useEffect(() => {
        return () => {
            dispatch(clearEditState())
        }
    }, [dispatch])

    return (
        <>
            <div style={{ background: 'black', height: '100%' }}>
                {!isAnonymousUser ? (
                    <Grid className={classes.main} container direction="column">
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                className={classes.headerConstainer}
                            >
                                <Grid item style={{ justifySelf: 'start' }}></Grid>
                                <Grid item style={{ justifySelf: 'end', overflow: 'hidden' }}>
                                    <PulsingHeader mainWindowScroll={window.pageYOffset} />
                                    <LanguageSettings />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography variant="h3" className={classes.title}>
                                {t('menu.settings')}
                            </Typography>
                        </Grid>
                        <Grid container direction="column" wrap="nowrap" className={classes.formWrapper}>
                            <Form
                                onSubmit={(model: any) => {
                                    dispatch(editUser(model))
                                }}
                                initialValues={user}
                                validate={(model: any): ValidationErrors => {
                                    const result: any = {}
                                    let requiredFields = ['firstName', 'lastName']
                                    if (user.paymentRequestId) {
                                        requiredFields = [...requiredFields, 'street', 'zip', 'city', 'country']
                                    }
                                    setRequiredErrors(result, model, requiredFields, t('register.required'))

                                    return result
                                }}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                        <Grid container className={classes.autoScroll}>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.firstname')}
                                                    itemName={'firstName'}
                                                    password={false}
                                                    children={
                                                        <img
                                                            alt="Card"
                                                            src={IdCard}
                                                            className={classes.inputIconLeft}
                                                        />
                                                    }
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.lastname')}
                                                    itemName={'lastName'}
                                                    password={false}
                                                    children={
                                                        <img
                                                            alt="Card"
                                                            src={IdCard}
                                                            className={classes.inputIconLeft}
                                                        />
                                                    }
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.phoneNumber')}
                                                    itemName={'phone'}
                                                    password={false}
                                                    required={false}
                                                    children={<PhoneIcon className={classes.inputIconLeft} />}
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.carLicensePlate')}
                                                    itemName={'carLicensePlate'}
                                                    password={false}
                                                    required={false}
                                                    children={<DirectionsCarIcon className={classes.inputIconLeft} />}
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.street')}
                                                    itemName={'street'}
                                                    password={false}
                                                    required={user.paymentRequestId !== null}
                                                    children={<PlaceIcon className={classes.inputIconLeft} />}
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.zip')}
                                                    itemName={'zip'}
                                                    password={false}
                                                    required={user.paymentRequestId !== null}
                                                    children={<LocationCityIcon className={classes.inputIconLeft} />}
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.city')}
                                                    itemName={'city'}
                                                    password={false}
                                                    required={user.paymentRequestId !== null}
                                                    children={<LocationCityIcon className={classes.inputIconLeft} />}
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItemCountry}>
                                                <CountrySelect
                                                    child={<PublicIcon className={classes.inputIconLeftCountry} />}
                                                    name="country"
                                                    required={user.paymentRequestId !== null}
                                                    label={t('settings.state')}
                                                    variant="standard"
                                                ></CountrySelect>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.companyName')}
                                                    itemName={'companyName'}
                                                    password={false}
                                                    required={false}
                                                    children={<DashboardIcon className={classes.inputIconLeft} />}
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.companyId')}
                                                    itemName={'companyId'}
                                                    password={false}
                                                    required={false}
                                                    children={<DashboardIcon className={classes.inputIconLeft} />}
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.taxId')}
                                                    itemName={'taxId'}
                                                    password={false}
                                                    required={false}
                                                    children={
                                                        <CollectionsBookmarkIcon className={classes.inputIconLeft} />
                                                    }
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.vatId')}
                                                    itemName={'vatId'}
                                                    password={false}
                                                    required={false}
                                                    children={
                                                        <CollectionsBookmarkIcon className={classes.inputIconLeft} />
                                                    }
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formItem}>
                                                <CustomTextField
                                                    label={t('settings.rfid')}
                                                    itemName={'rfid'}
                                                    password={false}
                                                    required={false}
                                                    children={
                                                        <CollectionsBookmarkIcon className={classes.inputIconLeft} />
                                                    }
                                                ></CustomTextField>
                                            </Grid>
                                            <Grid item className={classes.cardUpdateButtonContainer}>
                                                <Button
                                                    className={classes.cardUpdateButton}
                                                    onClick={handleDialogState}
                                                >
                                                    <CreditCardIcon
                                                        className={classes.inputIconLeft}
                                                        style={{ marginTop: '14px' }}
                                                    />
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <div style={{ width: '100%', height: '10px' }}></div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{ width: '100%' }}>
                                                                <Typography
                                                                    className={classes.buttonText}
                                                                    style={{ textAlign: 'left', marginLeft: '15px' }}
                                                                >
                                                                    {t('card.updateCard')}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div style={{ width: '100%', height: '10px' }}>
                                                                <Typography
                                                                    className={classes.buttonText}
                                                                    style={{
                                                                        fontSize: '9px',
                                                                        textAlign: 'left',
                                                                        marginLeft: '15px',
                                                                    }}
                                                                >
                                                                    {cardValidate()}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <ArrowForwardIosIcon
                                                        style={{
                                                            color: '#37c0ca',
                                                            width: '25px',
                                                            height: '25px',
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>
                                            <Grid item className={classes.buttonContainer}>
                                                <Button className={classes.buttonBlue} type="submit">
                                                    <p className={classes.buttonText}> {t('common.save')}</p>
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '15px', width: '100%' }}>
                                                <Button className={classes.logoutButton} onClick={handleLogout}>
                                                    <p className={classes.logoutText}> {t('common.logout')}</p>
                                                </Button>
                                            </Grid>
                                            <Snackbar
                                                open={message.open}
                                                className={classes.popupNotification}
                                                autoHideDuration={5000}
                                                onClose={() =>
                                                    setMessage({ open: false, severity: undefined, message: '' })
                                                }
                                            >
                                                <Alert
                                                    onClose={() =>
                                                        setMessage({ open: false, severity: undefined, message: '' })
                                                    }
                                                    severity={message.severity}
                                                >
                                                    {message.message}
                                                </Alert>
                                            </Snackbar>
                                            <Grid item className={classes.termsContainer}>
                                                <div className={classes.terms}>
                                                    {' '}
                                                    {t('settings.readOur')}
                                                    <a
                                                        href={`${config.proxy ?? ''}${
                                                            config.api
                                                        }Settings/TermsAndConditions`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {t('common.terms2')}
                                                    </a>
                                                    {t('common.or')}
                                                    <a
                                                        href={`${config.proxy ?? ''}${config.api}Settings/Gdpr`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {t('common.gdpr2')}
                                                    </a>
                                                    .
                                                    {tenant.aboutPage ? (
                                                        <p>
                                                            <a
                                                                href={tenant.aboutPage}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                About us
                                                            </a>
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </Grid>
                                            <div style={{ height: '150px' }} />
                                        </Grid>
                                    </form>
                                )}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <RegisteredUsersOnly title={t('menu.settings')} languageSettings={true} />
                )}
                <BottomControlPanel positionAbsolute={true} actualPage="Settings" />
                <div className={classes.bottomDecoration}></div>
            </div>
            <Dialog open={openDialog} className={classes.dialog}>
                <DialogTitle style={{ color: 'white' }}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>{t('common.warning')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ marginTop: '10px', width: '100%' }} className={classes.textDesc}>
                            {t('settings.updateCardDialog')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '10px', width: '100%' }}>
                        <Button className={classes.logoutButton} onClick={handleDialogState} style={{ width: '50%' }}>
                            <p
                                className={classes.logoutText}
                                style={{
                                    color: '#ffa3a3',
                                    borderBottom: '#ffa3a3 solid 1px',
                                    borderBottomStyle: 'groove',
                                }}
                            >
                                {t('common.cancel')}
                            </p>
                        </Button>
                        <Button
                            className={classes.logoutButton}
                            onClick={handleRedirectToCardRegister}
                            style={{ width: '50%' }}
                        >
                            <p className={classes.logoutText} style={{ color: '#37c0ca' }}>
                                {t('common.confirm')}
                            </p>
                        </Button>
                    </Grid>
                </DialogTitle>
            </Dialog>
        </>
    )
}
