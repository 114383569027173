import React from 'react'
import { Coordinates } from '../../shared/types/common'
import { Circle, Marker } from '@react-google-maps/api'

export interface CurrentPositionMarkerProps {
    coordinates: Coordinates
}

export const CurrentPositionMarker = ({ coordinates }: CurrentPositionMarkerProps) => {
    return (
        <div>
            <Circle
                center={{
                    lat: coordinates.lat,
                    lng: coordinates.lng,
                }}
                radius={coordinates.accuracy ? coordinates.accuracy : 0}
                options={{
                    strokeColor: '#6351ED29',
                    strokeWeight: 1,
                    fillColor: '#6351ED46',
                }}
            />
            <Marker
                icon={{
                    path: 'M 0, 0 m -10, 0 a 10, 10 0 1, 0 20, 0 a 10, 10 0 1, 0 -20, 0',
                    strokeColor: '#ffffff',
                    strokeWeight: 2,
                    fillColor: '#6351ED',
                    fillOpacity: 1,
                    size: new (window as any).google.maps.Size(21, 21),
                    scale: 1,
                }}
                position={{ lat: coordinates.lat, lng: coordinates.lng }}
            />
        </div>
    )
}
