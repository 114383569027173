import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ChargingSession, LocationWithChargers, ChargingSessionState } from '../../shared/types/location'
import { useSelector, useDispatch } from 'react-redux'
import { Charger, ChargerConnector, getConnectorLabel } from '../../shared/types/charger'
import moment from 'moment'
import { consumedKwh, importedKwh, offeredKwh } from '../../shared/utils/display'
import { setChargingSession, getChargingSession } from '../../redux/actions/charging'
import { useSessionPeriodicUpdate } from '../../utils/currentSession'
import CarLoading from '../../static/car_loading.png'
import { BottomControlPanel } from '../../core/footer/bottomControlPanel'

const useStyles = makeStyles((theme) => ({
    scrollable: {
        zoom: '0.75',
        zIndex: 3,
        overflowY: 'auto',
        padding: '23px 23px 23px 23px',
    },
    navigateButton: {
        maxWidth: '280px',
        marginTop: '59px',
        width: '100%',
        height: '60px',
        borderRadius: '14px',
        backgroundImage: 'linear-gradient(259deg, #fd8d99 1%, #de384a 47%)',
    },
    navigateButtonWhite: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        borderRadius: '28px',
        fontSize: '0.7rem',
        backgroundColor: '#ffffff',
        color: '#707070',
        borderColor: '#707070',
        border: '2px solid',
        opacity: 1,
        '&:hover': {
            backgroundColor: '#e8e0fb',
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
    },
    navigateIcon: {
        marginRight: theme.spacing(1),
    },
    charging: {
        paddingBottom: theme.spacing(3),
        textAlign: 'left',
        '& .MuiLinearProgress-root': {
            height: theme.spacing(2),
        },
    },
    subTitle: {
        marginTop: '14px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#37c0ca',
    },
    title: {
        marginTop: '21px',
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
    smallTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'left',
        fontSize: '1rem',
        color: theme.palette.secondary.dark,
    },
    property: {
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
        fontSize: '1.2rem',
        color: '#000000',
    },
    subTitleDetail: {
        marginTop: '43px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
    subTitleDetailItem: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    line: {
        borderLeft: 'solid 1px #2c2e2f',
        height: '45px',
        width: '6px',
    },
    carImageItem: {
        position: 'absolute',
        left: 'calc(41% - 160px)',
        overflow: 'hidden',
        height: '75%',
        bottom: '15%',
    },
    dataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '59px',
    },
    dataContainerInner: {
        justifyContent: 'flex-end',
        position: 'relative',
        maxWidth: '700px',
    },
    dataItem: {
        color: 'white',
        height: 'auto',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(to right, #363b3d, rgba(26, 30, 32, 0) 100%)',
    },
    dataItemInner: {
        margin: '2em',
    },
    dataItemSeparator: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource: 'linear-gradient(to left, rgba(104, 225, 231, 0), #37c0ca)',
        borderImageSlice: '1',
        marginBottom: '32px',
        marginTop: '20px',
    },
    dataText: {
        fontFamily: "'Play', sans-serif",
        fontSize: '45px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: '#37c0ca',
    },
    dataDescText: {
        margin: '10px',
        fontFamily: "'Play', sans-serif",
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: 'white',
    },
    bottomDecoration: {
        bottom: '0px',
        left: '0px',
        zIndex: 0,
        position: 'absolute',
        width: '100%',
        height: '261px',
        backgroundImage: 'linear-gradient(to bottom, rgba(55, 192, 202, 0) 1%, rgba(55, 191, 201, 0.46))',
    },
    startTimeText: {
        opacity: 0.5,
        fontFamily: "'Muli', sans-serif",
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
        marginBottom: '14px',
    },
    startTimeDataContainer: {
        opacity: 0.5,
        borderRadius: '8px',
        backgroundColor: '#363b3d',
    },
    startTimeData: {
        margin: '9px 10px 9px 10px',
        fontFamily: "'Muli', sans-serif",
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: 'white',
    },
    simpleSpacer: {
        opacity: 0,
        fontSize: '20px',
    },
}))

interface ChargingInProgressProps {
    location: LocationWithChargers
}

export const ChargingInProgress: React.FC<ChargingInProgressProps> = ({ location }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const chargingSession: ChargingSession = useSelector((state: any) => state.chargingSession.value)
    const stopChargingState = useSelector((state: any) => state.stopCharging)

    const charger: Charger = chargingSession.charger
    const connector: ChargerConnector = chargingSession.chargingConnector

    const startTime = moment(chargingSession.startedAt)
    // const parkingTime = moment.duration(moment().diff(chargingSession.endedAt))
    const elapsedTime = moment.duration(moment().diff(startTime))

    const [sessionTime, setSessionTime] = useState<any>(elapsedTime)
    const [parkingTime, setParkingTime] = useState<any>(
        moment.duration(moment().diff(moment(chargingSession.startedParkingAt)))
    )

    // const formatTime = (time: any) => {
    //     return time.minutes() === 0 && time.hours() === 0
    //         ? t('charging.elapsedLessThanMinute')
    //         : moment().hours(time.hours()).minutes(time.minutes()).format('H[h] m[m]')
    // }

    useEffect(() => {
        const timer = setInterval(() => {
            setParkingTime(moment.duration(moment().diff(moment(chargingSession.startedParkingAt))))
            setSessionTime(moment.duration(moment().diff(moment(chargingSession.startedAt))))
        }, 60000)
        return () => clearInterval(timer)
    }, [chargingSession.state, chargingSession.startedAt, chargingSession.startedParkingAt])

    useSessionPeriodicUpdate(chargingSession)

    useEffect(() => {
        const setEndedSession = async () => {
            const result = await dispatch(getChargingSession(chargingSession.id))
            dispatch(setChargingSession(result))
        }
        const isChargerConnected = stopChargingState.error?.errors[0]?.code === 1
        if (isChargerConnected) {
            setEndedSession()
        }
    }, [stopChargingState, chargingSession.id, dispatch])

    return (
        <>
            <div className={classes.scrollable}>
                <Grid container direction="column">
                    {location && (
                        <>
                            <Grid item className={classes.subTitle}>
                                {chargingSession.state === ChargingSessionState.Parking
                                    ? t('charging.parkingAt')
                                    : t('charging.chargingAt')}
                            </Grid>
                            <Grid item className={classes.title}>
                                {location.name}
                            </Grid>

                            <Grid item className={classes.subTitleDetail}>
                                <Grid xs={12} container justify="center">
                                    <Grid item xs={4} className={classes.subTitleDetailItem}>
                                        {charger?.name}
                                    </Grid>
                                    <Grid item xs={4} className={classes.subTitleDetailItem}>
                                        <div className={classes.line} />
                                    </Grid>
                                    <Grid item xs={4} className={classes.subTitleDetailItem}>
                                        {connector
                                            ? getConnectorLabel(t, connector.socketType, connector.externalId)
                                            : t('common.unknown')}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <div className={classes.dataContainer}>
                                <Grid
                                    className={classes.dataContainerInner}
                                    container
                                    alignItems="flex-end"
                                    justify="space-around"
                                >
                                    <img alt="Car" height={'100%'} src={CarLoading} className={classes.carImageItem} />

                                    <Grid item style={{ width: 'calc(50% - 235px)' }}></Grid>
                                    <Grid
                                        item
                                        className={classes.dataItem}
                                        style={{ width: '65%', maxWidth: '330px', minWidth: '225px' }}
                                        justify="flex-end"
                                    >
                                        <Grid item className={classes.dataItemInner}>
                                            <Grid xs={12} container justify="center">
                                                <Grid item className={classes.dataText}>
                                                    {consumedKwh(chargingSession)}
                                                </Grid>
                                                <Grid item className={classes.dataDescText}>
                                                    kWh
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} container justify="center">
                                                <div className={classes.dataItemSeparator}></div>
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.dataDescText}
                                                style={{ marginTop: '0px', marginBottom: '5px' }}
                                            >
                                                {t('charging.sessionDurationDesc')}
                                            </Grid>
                                            <Grid xs={12} container justify="center">
                                                <Grid item className={classes.dataText}>
                                                    {sessionTime._data.hours === 0 ? (
                                                        <>
                                                            <span>{sessionTime._data.minutes}</span>
                                                            <span className={classes.simpleSpacer}>_</span>
                                                            <span>min</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span>{sessionTime._data.hours}</span>
                                                            <span>h</span>
                                                            <span className={classes.simpleSpacer}>_</span>
                                                            <span>{sessionTime._data.minutes}</span>
                                                            <span>m</span>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {parkingTime._data.hours !== 0 || parkingTime._data.minutes !== 0 ? (
                                                <Grid
                                                    item
                                                    className={classes.dataText}
                                                    style={{ marginTop: '10px', fontSize: '2em' }}
                                                >
                                                    <Grid
                                                        item
                                                        className={classes.dataDescText}
                                                        style={{
                                                            marginTop: '0px',
                                                            marginBottom: '5px',
                                                            fontSize: '0.5em',
                                                        }}
                                                    >
                                                        {t('charging.parkingDurationDesc')}
                                                    </Grid>
                                                    {parkingTime._data.hours === 0 ? (
                                                        <>
                                                            <span>{parkingTime._data.minutes}</span>
                                                            <span className={classes.simpleSpacer}>_</span>
                                                            <span>min</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span>{parkingTime._data.hours}</span>
                                                            <span>h</span>
                                                            <span className={classes.simpleSpacer}>_</span>
                                                            <span>{parkingTime._data.minutes}</span>
                                                            <span>m</span>
                                                        </>
                                                    )}
                                                </Grid>
                                            ) : (
                                                ''
                                            )}
                                            <Grid xs={12} container justify="center">
                                                <div className={classes.dataItemSeparator}></div>
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.dataDescText}
                                                style={{ marginTop: '0px', marginBottom: '5px', fontSize: '1em' }}
                                            >
                                                {t('charging.currentCharging')}
                                                {/* Aktuálne nabíjanie */}
                                            </Grid>
                                            <Grid xs={12} container justify="center">
                                                <Grid item className={classes.dataText} style={{ fontSize: '2em'}}>
                                                    {importedKwh(chargingSession)}
                                                </Grid>
                                                <Grid item className={classes.dataDescText} style={{margin: '0px 10px'}}>
                                                    /
                                                </Grid>
                                                <Grid item className={classes.dataText} style={{ fontSize: '2em'}}>
                                                    {offeredKwh(chargingSession)}
                                                </Grid>
                                                <Grid item className={classes.dataDescText} style={{ fontSize: '1em',  margin: '0.5em'}}>
                                                    kW
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} container justify="center">
                                                <div className={classes.dataItemSeparator}></div>
                                            </Grid>
                                            <Grid xs={12} container></Grid>

                                            <Grid container style={{ justifyContent: 'center' }}>
                                                <Grid item className={classes.startTimeDataContainer}>
                                                    <div className={classes.startTimeData}>
                                                        {t('charging.started', { started: startTime.format('LT') })}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ height: '143px' }} />
                        </>
                    )}
                </Grid>
            </div>
            <BottomControlPanel
                actualPage={chargingSession.state === ChargingSessionState.Parking ? 'none' : 'charging'}
                positionAbsolute={true}
            />
            <div className={classes.bottomDecoration} />
        </>
    )
}
