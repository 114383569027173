import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid, Button, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setToken, getAppKey, config, getMainTenantId } from '../../config'
import { usePromise } from '../../shared/utils/usePromise'
import { RouteComponentProps } from 'react-router-dom'
import { sendJson } from '../../shared/utils/api'
import CarSplash from '../../static/car_splash_alternative.png'
import ChargeLogo from '../../static/charge_logo.png'
import { login, promiseError } from '../../shared/redux/actions/common'
import IconOutlineBolt from '../../static/icons/bolt-splash.svg'
import { LanguageSettings } from '../../utils/languageSettings'

const useStyles = makeStyles((theme) => ({
    wrapperMain: {
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
    },
    wrapper: {
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
    },
    wrapperMiddleTop: {
        /*height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='390' height='340' viewBox='0 0 390 340'%3E%3Cdefs%3E%3CradialGradient id='mwpxakn86b' cx='50%25' cy='100%25' r='85.002%25' fx='50%25' fy='100%25' gradientTransform='matrix(0 -1 .57327 0 -.073 1.5)'%3E%3Cstop offset='0%25' stop-color='%2337C0CA'/%3E%3Cstop offset='100%25' stop-color='%23000507'/%3E%3C/radialGradient%3E%3Cpath id='jq1qqmrrxa' d='M0 0H390V536H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg transform='translate(0 -78) translate(0 78)'%3E%3Cmask id='4psu7qilhc' fill='%23fff'%3E%3Cuse xlink:href='%23jq1qqmrrxa'/%3E%3C/mask%3E%3Cellipse cx='195' cy='156' fill='url(%23mwpxakn86b)' mask='url(%234psu7qilhc)' rx='479' ry='254'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A\")",
        fontFamily: "'Mulish', sans-serif",*/
    },
    wrapperMiddleBottom: {
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
    },
    wrapperBottom: {
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        backgroundColor: '#000507',
        fontFamily: "'Mulish', sans-serif",
    },
    wrapperInner: {
        objectFit: 'contain',
        maxWidth: '300px',
        paddingBottom: '0px',
        padding: '23px 0px',
        margin: 'auto',
        color: 'white',
    },
    wrapperInnerMiddle: {
        objectFit: 'contain',
        maxWidth: '500px',
        paddingBottom: '0px',
        padding: '0px',
        margin: '0px auto 0px auto',
        color: 'white',
    },
    wrapperInnerBottom: {
        objectFit: 'contain',
        maxWidth: '400px',
        paddingBottom: '0px',
        padding: '0px ',
        margin: 'auto',
        color: 'white',
    },
    backButton: {
        width: '48px',
        minWidth: '48px',
        height: '60px',
        borderRadius: '14px',
        backgroundImage: 'linear-gradient(141deg, #3a4245, #1b1f21 100%)',
    },
    input: {
        paddingLeft: '5px',
        color: 'white',
        backgroundImage: 'linear-gradient(141deg, #3a4245, #1b1f21 100%)',
        paddingRight: '5px',
        paddingTop: '2px',
        fontSize: '14px',
        border: '0px !important',
        fontFamily: "'Mulish', sans-serif",
        WebkitAppearance: 'none',
    },
    inputTextField: {
        '& .MuiSelect-iconOutlined': {
            left: '12px',
            top: '35px',
            color: 'white !important',
        },
        '&& .MuiSelect-outlined.MuiSelect-outlined': {
            color: 'white !important',
            padding: '20.5px 14px 20.5px 14px',
            backgroundImage: 'linear-gradient(141deg, #3a4245, #1b1f21 100%)',
            borderRadius: '14px',
        },
        WebkitAppearance: 'none',
    },
    titleContainer: {
        marginTop: '46px',
        textAlign: 'center',
    },
    carContainer: {
        marginTop: '0px',
        marginBottom: '0px',
        position: 'relative',
    },
    title: {
        fontFamily: "'Play', sans-serif",
        fontSize: '32px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textAlign: 'center',
    },
    singInButtonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
    buttonContainer: {
        marginBottom: '15px',
        marginLeft: '55px',
        marginRight: '55px',
    },
    signAsAnonymContainer: {
        marginTop: '14px',
        marginBottom: '61px',
    },
    signAsAnonymInnerContainer: {
        display: 'inline-block',
        paddingBottom: '7px',
        borderBottom: '1px solid #37c0ca',
        cursor: 'pointer',
    },
    signAsAnonymText: {
        fontFamily: "'Mulish', sans-serif",
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        margin: '0px',
        textTransform: 'uppercase',
    },
    singInButton: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        borderRadius: '14px',
    },
    registerButton: {
        height: '60px',
        borderRadius: '14px',
        border: 'solid 1px #37c0ca',
        background: 'transparent',
        '&:hover': {
            background: '#0b3134',
        },
    },
    select: {
        // ...existing styles
        display: 'grid',
        width: '100%',
        minWidth: '15ch',
        maxWidth: '30ch',
        border: '1px solid var(--select-border)',
        borderRadius: '0.25em',
        padding: '0.25em 0.5em',
        fontSize: '1.25rem',
        cursor: 'pointer',
        lineHeight: 1.1,
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(to top, #f9f9f9, #fff 33%)',
        '&:hover': {
            border: '1px solid black',
        },
        '& .select::after': {
            content: '',
            width: '0.8em',
            height: '0.5em',
            backgroundColor: 'var(--select-arrow)',
            clipPath: 'polygon(100% 0%, 0 0%, 50% 100%)',
        },
    },
    selectInput: {
        '& .MuiOutlinedInput-input': {
            border: 'none',
        },
        '& .MuiInputLabel-root': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&:hover .MuiOutlinedInput-input': {
            border: 'none',
        },
        '&:hover .MuiInputLabel-root': {
            border: 'none',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            border: 'none',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        outline: 0,
        border: 'none',
        boxShadow: 'none',
    },
    circle: {
        overflow: 'hidden',
        position: 'absolute',
        top: 'calc(59% - 38px)',
        left: 'calc(50% - 38px)',
        borderRadius: '50%',
        width: '76px',
        height: '76px',
        opacity: 0,
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: '#37c0ca',
        animation: '$scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)',
    },
    circleMidIcon: {
        width: '14px',
        position: 'absolute',
        top: 'calc(59% - 7px)',
        left: 'calc(50% - 7px)',
    },
    '@keyframes scaleIn': {
        from: { transform: 'scale(.5, .5)', opacity: 0.6 },
        to: { transform: 'scale(2.5, 2.5)', opacity: 0 },
    },
    terms: {
        paddingTop: theme.spacing(3),
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline',
            },
        },
    },
    logo: {
        maxHeight: '58px',
    }
}))

const loginAnonymousAction = (appKey: string) =>
    sendJson('authorization/anonymous', 'POST', null, {
        ApplicationKey: appKey,
    })

export interface IProps extends RouteComponentProps {
    returnUrl: string
}

const SplashScreen: React.FC<IProps> = ({ returnUrl }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()
    const tenant = useSelector((state: any) => state.tenant.value)
    const appKey = getAppKey()
    const mainTenantId = getMainTenantId()
    
    const { 0: loginAnonymousStart, 2: loginAnonymousResult, 3: loginAnonymousError } = usePromise(loginAnonymousAction)

    useEffect(() => {
        if (loginAnonymousError) {
            dispatch(promiseError(t('login.error', { error: loginAnonymousError })))
        }
        if (loginAnonymousResult) {
            if (loginAnonymousResult.token) {
                setToken(loginAnonymousResult.token)
                dispatch(login(true))
                if (returnUrl) {
                    history.push(returnUrl)
                }
            }
        }
    }, [dispatch, t, loginAnonymousError, loginAnonymousResult, returnUrl, history])

    return (
        <div className={classes.wrapperMain}>
            <div className={classes.wrapper}>
                <Grid container alignItems="flex-start" className={classes.wrapperInner} spacing={0}>
                    <Grid item xs={12} container direction="row" justify="space-between" alignItems="center">
                        <Grid item style={{ justifySelf: 'start' }}></Grid>
                        <Grid item style={{ justifySelf: 'end' }}>
                            <LanguageSettings />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div style={{ zoom: '0.75' }}>
                <div className={classes.wrapperMiddleTop}>
                    <Grid container alignItems="flex-start" className={classes.wrapperInnerMiddle} spacing={0}>
                        <Grid item xs={12} style={{ marginTop: '37px' }}>
                            <img alt="Logo" className={classes.logo} src={tenant?.logo.url ? `${config.api}${tenant.logo.url}` : ChargeLogo} />
                        </Grid>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography variant="h3" className={classes.title}>
                                {t('login.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.carContainer}>
                            <img alt="Splash" width={'100%'} src={CarSplash} />
                            <div className={classes.circle}></div>
                            <div
                                className={classes.circle}
                                style={{
                                    animationDelay: '0s',
                                }}
                            ></div>
                            <div
                                className={classes.circle}
                                style={{
                                    animationDelay: '1s',
                                }}
                            ></div>
                            <div
                                className={classes.circle}
                                style={{
                                    animationDelay: '2s',
                                }}
                            ></div>
                            <div
                                className={classes.circle}
                                style={{
                                    animationDelay: '3s',
                                }}
                            ></div>
                            <img alt="Bolt" src={IconOutlineBolt} className={classes.circleMidIcon} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.wrapperBottom}>
                    <Grid container alignItems="flex-start" className={classes.wrapperInnerBottom} spacing={0}>
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                fullWidth={true}
                                onClick={(event: any) => {
                                    history.push('/login')
                                }}
                                type="submit"
                                className={classes.singInButton}
                            >
                                <p className={classes.singInButtonText}>{t('login.signIn')}</p>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.wrapperBottom}>
                    <Grid container alignItems="flex-start" className={classes.wrapperInnerBottom} spacing={0}>
                        {tenant && tenant.id === mainTenantId && (
                            <Grid item xs={12} className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    fullWidth={true}
                                    type="submit"
                                    onClick={(event: any) => {
                                        history.push('/register')
                                    }}
                                    className={classes.registerButton}
                                >
                                    <p className={classes.singInButtonText}>{t('register.register')}</p>
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={12} className={classes.signAsAnonymContainer}>
                            <div
                                className={classes.signAsAnonymInnerContainer}
                                onClick={(event: any) => {
                                    event.preventDefault()
                                    loginAnonymousStart(appKey)
                                }}
                            >
                                <p className={classes.signAsAnonymText}>{t('common.signInAnonymously')}</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default SplashScreen
