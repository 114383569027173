import { UPDATE_LOCATION_INFO } from './../const/index'

export const locationMapReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${UPDATE_LOCATION_INFO}`: {
            return {
                ...state,
                value: state.value.map((value: any) => 
                value.id === action.payload.locationInfo.Id ? { ...value,
                    chargerCount: action.payload.locationInfo.ChargerCount,
                    chargersInUseCount: action.payload.locationInfo.ChargersInUseCount,
                    availableConnectors: action.payload.locationInfo.AvailableConnectors,
                    workingConnectors: action.payload.locationInfo.WorkingConnectors,
                    connectedChargers: action.payload.locationInfo.ConnectedChargers,
                    connectorCount: action.payload.locationInfo.ConnectorCount,
                 } : value
                ) 
            };
        }
        default: {
            return state
        }
    }
}