import React, { ReactNode } from 'react';
import { Button, ButtonProps, CircularProgressProps } from '@material-ui/core';
import { ProgressOverlay } from './progressOverlay';

type AsyncButtonProps = {
    buttonProps?: ButtonProps;
    children?: ReactNode; // Use ReactNode for a more specific type
    progressProps?: CircularProgressProps;
    inProgress: boolean;
};

export function AsyncButton({ buttonProps, children, progressProps, inProgress }: AsyncButtonProps) {
    return (
        <ProgressOverlay inProgress={inProgress} progressProps={progressProps}>
            <Button disabled={inProgress} {...buttonProps}>
                {children}
            </Button>
        </ProgressOverlay>
    );
}