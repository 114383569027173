import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { errorDismiss, logout } from '../../shared/redux/actions/common'
import AuthorizationError from '../../shared/utils/authorizationError'
import ValidationError from '../../shared/utils/validationError'
import { getErrorMessage } from '../../shared/utils/validation'

const ErrorHandler = () => {
    const dispatch = useDispatch()
    const error = useSelector((state: any) => state.error.current)
    const codeToMessageMapWithKeys = useSelector((state: any) => state.error.codeToMessageMap)

    const { t } = useTranslation()
    const codeToMessageMap = codeToMessageMapWithKeys
        ? codeToMessageMapWithKeys
              .filter((mapItem: Array<string>) => Boolean(mapItem) && mapItem.length === 2)
              .map((mapItem: Array<string>) => [mapItem[0], t(mapItem[1])])
        : []

    useEffect(() => {
        if (error && error instanceof AuthorizationError) {
            dispatch(logout())
            dispatch(errorDismiss())
        }
    }, [dispatch, error])

    return Boolean(error) && !(error instanceof AuthorizationError) ? (
        <Snackbar open={Boolean(error)} autoHideDuration={5000} onClose={() => dispatch(errorDismiss())}>
            <Alert onClose={() => dispatch(errorDismiss())} severity="error">
                {error instanceof ValidationError
                    ? getErrorMessage(error, codeToMessageMap, t('common.unknownValidationFailure'))
                    : error}
            </Alert>
        </Snackbar>
    ) : (
        <></>
    )
}

export default ErrorHandler
