import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import ImageGalleryReact from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { config } from '../../config'

const useStyles = makeStyles((theme) => ({
    galleryDialog: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(1, 1, 0, 1),
        },
        '& .MuiDialog-paper': {
            maxHeight: '90vh',
            overflow: 'hidden',
        },
        '& .image-gallery-image': {
            maxHeight: `calc(90vh - ${theme.spacing(10)}px) !important`,
        },
    },
}))

type ImageGalleryProps = {
    urls: string[]
    initialIndex: number
    onClose: Function
}
export function ImageGallery({ urls, initialIndex, onClose }: ImageGalleryProps) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Dialog
            open={true}
            onBackdropClick={() => onClose()}
            fullWidth={true}
            maxWidth="xl"
            className={classes.galleryDialog}
        >
            <DialogContent>
                <ImageGalleryReact
                    items={urls.map((x) => ({ original: `${config.proxy ? config.proxy + config.api : config.api}${x}` }))}
                    showThumbnails={false}
                    showPlayButton={false}
                    startIndex={initialIndex}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>{t('common.close')}</Button>
            </DialogActions>
        </Dialog>
    )
}
