import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { /*updateChargingSessionLocationDetail,*/ updateConnectorStatusLocationDetail } from './../redux/actions/locations'
import { config } from '../config'


var socket: WebSocket;
var actualLocationId: String;

export function closeWebSocket() {
    socket.close();
}

export function useConnectorStatusPeriodicUpdate(locationId: String) {
    const dispatch = useDispatch()

    useEffect(() => {
        if (actualLocationId !== locationId) {
            if (socket != null) {
                closeWebSocket()
            }
            if (locationId !== "") {
                socket = new WebSocket(`${config.websocket}/ocpp/client_charging_connector_status${locationId}`, [
                    'ocpp1.6',
                ]);
                socket.onmessage = (event) => {
                    const connectorInfoChanged = JSON.parse(event.data)
                    console.log(connectorInfoChanged)
                    if (connectorInfoChanged.ChargingConnectorId) {
                        //dispatch(updateChargingSessionLocationDetail(connectorInfoChanged))
                    } else {
                        dispatch(updateConnectorStatusLocationDetail(connectorInfoChanged))
                    }
                    //dispatch(updateLocationInfo(chargerInfoChanged))
                }
                return () => {
                }
            }
        }
    }, [dispatch, locationId])
}
