import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundImage: 'linear-gradient(259deg, #268890 1%, #42c7d0 95%, #42c7d0 5%)',
        height: '60px',
        width: '100%',
        maxWidth: '280px',
        borderRadius: '14px',
    },
    buttonText: {
        fontSize: '14px',
        fontWeight: 'initial',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '1px',
        color: 'white',
        fontFamily: "'Mulish', sans-serif",
    },
}))

type DialogConfirmButtonProps = {
    label: string
    stateSelector?(state: any): any
    disabled?: boolean
    onClick?: Function
}
export function DialogConfirmButton({ label, stateSelector, disabled, onClick = () => {} }: DialogConfirmButtonProps) {
    const classes = useStyles()
    const defaultStateSelector = (state: any) => {
        return state
    }
    const state = useSelector(stateSelector ?? defaultStateSelector)

    return (
        <Button
            disabled={(stateSelector ? !state?.executed : false) || disabled}
            className={classes.button}
            type="submit"
            onClick={() => onClick()}
        >
            <p className={classes.buttonText}>{label}</p>
        </Button>
    )
}
