import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Button, ButtonBase, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import { DropzoneArea } from 'material-ui-dropzone'
import { DialogProgress } from './dialogProgress'
import { DialogConfirmButton } from './dialogConfirmButton'

const useStyles = makeStyles((theme) => ({
    addImage: {
        backgroundColor: theme.palette.secondary.light,
    },
    uploadDialog: {
        '& .MuiDialog-paper': {
            width: '600px',
        },
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    uploader: {
        minHeight: 'auto',
        '& .MuiGrid-container': {
            margin: 0,
            width: 'auto',
        },
    },
}))

type AddImageProps = {
    className?: String
    progressStateSelector(state: any): any
    onUpload(files: File[]): Promise<any>
    onUploadError?(): void
    radius?: string
}
export function AddImage({ className, progressStateSelector, onUpload, onUploadError, radius = '5%' }: AddImageProps) {
    const classes = useStyles()
    const { t } = useTranslation()

    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false)
    const [filesToUpload, setFilesToUpload] = useState<File[]>([])

    return (
        <>
            <ButtonBase
                className={`${classes.addImage} ${className}`}
                onClick={() => setIsUploadDialogOpen(true)}
                style={{ borderRadius: radius }}
            >
                <AddAPhotoIcon />
            </ButtonBase>
            {isUploadDialogOpen && (
                <Dialog open={true} className={classes.uploadDialog}>
                    <DialogProgress stateSelector={progressStateSelector} />
                    <DialogTitle>{t('common.addPhotos')}</DialogTitle>
                    <DialogContent>
                        <DropzoneArea
                            dropzoneClass={classes.uploader}
                            dropzoneText={t('common.uploadImage')}
                            showAlerts={false}
                            onDropRejected={() => {
                                if (onUploadError) {
                                    onUploadError()
                                }
                            }}
                            filesLimit={10}
                            maxFileSize={3 * 1024 * 1024}
                            showPreviewsInDropzone
                            onChange={setFilesToUpload}
                            acceptedFiles={['image/*']}
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={() => setIsUploadDialogOpen(false)}>{t('common.cancel')}</Button>
                        <DialogConfirmButton
                            label={t('common.upload')}
                            stateSelector={progressStateSelector}
                            disabled={filesToUpload.length === 0}
                            onClick={async () => {
                                await onUpload(filesToUpload)
                                setIsUploadDialogOpen(false)
                                setFilesToUpload([])
                            }}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
